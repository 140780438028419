import { memo } from 'react'

import { Group, Text } from '@mantine/core'

import classes from './ActiveLabel.module.css'

export const ActiveLabel = memo<{ label: string }>(function ActiveLabel({
  label,
}) {
  return (
    <Group bg="green.9" className={classes.root} gap="8">
      <div className={classes.light} />
      <Text size="sm">{label}</Text>
    </Group>
  )
})
