import { memo } from 'react'

import dayjs from 'dayjs'

import { calc_APY_between } from '@repo/common/calculations/apy_math'
import {
  useGetVaultLatestData,
  useGetVaultOriginalPrice,
} from '@repo/common/queries/vaults'

export const VaultOverallGrowth = memo<{ id: RubyID }>(
  function VaultOverallGrowth({ id }) {
    const latest = useGetVaultLatestData({ id })
    const original = useGetVaultOriginalPrice({ id })
    if (
      latest.isLoading ||
      original.isLoading ||
      original.data == null ||
      latest.data == null
    )
      return null

    const apy = calc_APY_between(
      { balance: original.data.price, date: original.data.date },
      {
        balance: latest.data.price_per_token,
        date: dayjs.unix(latest.data.block_timestamp_s).valueOf(),
      },
    )

    return <>{apy}</>
  },
)
