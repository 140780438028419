import { memo, useMemo } from 'react'

import dayjs, { type Dayjs } from 'dayjs'
import * as _ from 'lodash-es'

import { calc_APY_between } from '@repo/common/calculations/apy_math'
import { Metrics } from '@repo/common/components/Metrics/Metrics'
import { percentage_formatter } from '@repo/common/helpers/formatters'
import {
  useGetVaultHistory,
  useGetVaultOriginalPrice,
} from '@repo/common/queries/vaults'

type DailyPrice = { date_s: number; price_per_token: number }
function find_nav_of_date(dailies: DailyPrice[], for_date: Dayjs) {
  return dailies.find(({ date_s }, index) => {
    const same = dayjs.unix(date_s).isSame(for_date, 'day')
    if (same) return true

    const next = dailies[index + 1]?.date_s
    if (next && dayjs.unix(next).isAfter(for_date, 'date')) return true
    return false
  })
}

function to_apy(first: DailyPrice | undefined, second: DailyPrice | undefined) {
  if (first == null || second == null) return 'n/a'
  return calc_APY_between(
    price_per_token_to_balance(first),
    price_per_token_to_balance(second),
  )
}

export function price_per_token_to_balance(entry: DailyPrice) {
  return {
    balance: entry.price_per_token,
    date: dayjs.unix(entry.date_s).valueOf(),
  }
}

export function to_rate(
  first: DailyPrice | undefined,
  second: DailyPrice | undefined,
) {
  if (first == null || second == null) return 'n/a'
  return percentage_formatter(
    (100 * (second.price_per_token - first.price_per_token)) /
      first.price_per_token,
  )
}

export const VaultReturnMetrics = memo<{ id: RubyID }>(
  // eslint-disable-next-line max-lines-per-function
  function VaultReturnMetrics({ id }) {
    const { data: dailies } = useGetVaultHistory({ id })
    const { data: original } = useGetVaultOriginalPrice({ id })

    const metrics = useMemo(() => {
      const today_nav = _.last(dailies)
      if (today_nav == null) {
        return []
      }
      const today_day = dayjs.unix(today_nav.date_s)

      const one_month_nav = find_nav_of_date(
        dailies,
        today_day.subtract(1, 'month'),
      )
      const quarter_nav = find_nav_of_date(
        dailies,
        today_day.subtract(3, 'month'),
      )
      const jan_1_nav = find_nav_of_date(dailies, today_day.startOf('year'))

      const one_year_nav = find_nav_of_date(
        dailies,
        today_day.subtract(1, 'year'),
      )
      const inception_nav = {
        date_s: dayjs(original?.date).unix(),
        price_per_token: original?.price ?? 0,
      }
      return [
        {
          label: '1 Month',
          since_date: dayjs.unix(one_month_nav!.date_s),
          rate: to_rate(one_month_nav, today_nav),
        },
        {
          label: '3 Month',
          since_date: dayjs.unix(quarter_nav!.date_s),
          rate: to_rate(quarter_nav, today_nav),
        },
        {
          label: 'YTD',
          since_date: dayjs.unix(jan_1_nav!.date_s),
          rate: to_rate(jan_1_nav, today_nav),
          apy: to_apy(jan_1_nav, today_nav),
        },
        {
          label: '1 Year',
          since_date: dayjs.unix(one_year_nav!.date_s),
          rate: to_rate(one_year_nav, today_nav),
          apy: to_apy(one_year_nav, today_nav),
        },
        {
          label: 'Inception',
          since_date: dayjs.unix(inception_nav.date_s),
          rate: to_rate(inception_nav, today_nav),
          apy: to_apy(inception_nav, today_nav),
        },
      ]
    }, [dailies, original])

    if (dailies.length == 0) return null

    return <Metrics data={metrics} />
  },
)
