import { Stack } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'

import { LinkButton } from '@repo/common/components/LinkButton'
import { useFundInfo } from '@repo/common/queries/funds'

import { FakeSection } from '@/components/FakeSection'
import { HeadlineAndDetails } from '@/components/HeadlineAndDetails'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/_app/investments/$symbol/docs/disclosures')({
  component: DisclosuresPage,
})

function DisclosuresPage() {
  const { symbol } = Route.useParams()

  const { data: fund, isLoading } = useFundInfo({ symbol })

  if (isLoading || fund == null) return null

  return (
    <Stack>
      <HeadlineAndDetails
        title={`Disclosure Document page for ${fund.name} (${fund.symbol})`}
        description=""
      />
      <FakeSection image="document" width="600px" />
      <LinkButton to="/investments/$symbol" params={{ symbol }}>
        Back to Fund
      </LinkButton>
    </Stack>
  )
}
