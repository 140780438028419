import { defineChain } from 'viem'
import { createConfig, http } from 'wagmi'
import { sepolia } from 'wagmi/chains'
import { coinbaseWallet, metaMask } from 'wagmi/connectors'

const hardhat = defineChain({
  id: 31_337,
  name: 'Hardhat',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['http://127.0.0.1:8545'] },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 2,
    },
  },
})

export const getAlchemyUrlFor = (network: 'eth-mainnet' | 'eth-sepolia') =>
  `https://${network}.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_KEY}`

function get_active_chain() {
  switch (import.meta.env.VITE_NETWORK) {
    case 'hardhat':
      return hardhat
    default:
      return sepolia
  }
}

export const active_chain = get_active_chain()

export function make_config(args: { appLogoUrl: string; appName: string }) {
  return createConfig({
    chains: [active_chain],
    transports: {
      [sepolia.id]: http(getAlchemyUrlFor('eth-sepolia')),
      [hardhat.id]: http('http://127.0.0.1:8545/'),
    },
    connectors: [
      metaMask(),
      coinbaseWallet({
        ...args,
        darkMode: true,
        chainId: active_chain.id,
      }),
    ],
  })
}
