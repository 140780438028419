/* eslint-disable complexity */
import { memo } from 'react'

import {
  Box,
  Button,
  Grid,
  Group,
  NumberInput,
  Stack,
  Text,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { z } from 'zod'

import { IconPlus } from '@repo/common/components/Icons'
import { TokenImage } from '@repo/common/components/TokenImage'
import { TokenRender } from '@repo/common/components/TokenRender'
import { WalletBalance } from '@repo/common/components/WalletBalance'
import {
  countFormatter,
  percentage_formatter,
  usdFormatter,
} from '@repo/common/helpers/formatters'
import { useBuyCartFund } from '@repo/common/queries/cart'
import { useGetTokenPrice } from '@repo/common/queries/fission_dex'
import { useFundInfo } from '@repo/common/queries/funds'
import { useGetGovMintRewards } from '@repo/common/queries/gov'

// eslint-disable-next-line max-lines-per-function
import classes from './PanelBuy.module.css'

const schema = z.object({
  quantity: z.number().min(1),
})

export const PanelBuy = memo<{
  fund_id: RubyID
  onCancel: () => void
  onCompleted: () => void
  amount?: number
}>(
  // eslint-disable-next-line max-lines-per-function
  function PanelBuy({
    fund_id,
    amount: starting_amount = 10,
    onCancel,
    onCompleted,
  }) {
    const { data: fund, isLoading } = useFundInfo({ fund_id })
    const { data: fund_price } = useGetTokenPrice({ symbol: fund?.symbol })

    const cart = useBuyCartFund()
    const form = useForm({
      initialValues: {
        quantity: starting_amount,
      },
      validate: zodResolver(schema),
    })
    const total = form.values.quantity * (fund_price ?? 0)
    const { data: fisn_rewards } = useGetGovMintRewards({
      mint_value: total,
      fund_id,
    })

    const onSubmit = (
      values: Parameters<Parameters<typeof form.onSubmit>[0]>[0],
    ) => {
      cart.setValues({
        quantity: values.quantity,
        deposit_type: 'usdc',
        id: fund_id,
      })
      onCompleted()
    }

    if (isLoading || fund == null) return null

    return (
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Grid columns={2} gutter="0" className={classes.root}>
          <Grid.Col span={1} className={classes.row}>
            <Group>
              <TokenImage image_slug={fund.name} size="50" />
              <Stack gap="0">
                <Text size="lg" fw="bold" lh="1">
                  {fund.name}
                </Text>
                <Text size="sm">{fund.symbol}</Text>
              </Stack>
            </Group>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row_value}>
            <Text fw="bold">
              {fund_price == null ? null : usdFormatter(fund_price)}/share
            </Text>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row}>
            <Text size="sm">Order Type</Text>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row_value}>
            <Text>Market</Text>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row}>
            <Text size="md" component="label" htmlFor="quantity">
              Tokens to Mint
            </Text>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row_value}>
            <NumberInput
              min={1}
              size="lg"
              {...form.getInputProps('quantity')}
              name="quantity"
              id="quantity"
            />
          </Grid.Col>
          <Grid.Col span={1} className={classes.row}>
            <Group gap="0.5ex">
              <Text size="md" lh="1">
                Mint Rewards
              </Text>
              <Group c="green.2" gap="0">
                <IconPlus size="1em" />
                <Text size="md" lh="1">
                  {percentage_formatter(fisn_rewards.percentage)}
                </Text>
              </Group>
            </Group>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row_value}>
            <Group gap="0.5ex" fz="md">
              <TokenRender
                size="var(--mantine-spacing-lg)"
                type="token"
                image_slug="fisn"
              />
              <Text fw="bold">
                {countFormatter(fisn_rewards.tokens, {
                  maximumFractionDigits: 2,
                  compact: false,
                })}
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={1} fw="bold" className={classes.row}>
            <Text size="sm">Total Cost</Text>
          </Grid.Col>
          <Grid.Col span={1} data-testid="total" className={classes.row_value}>
            <Group gap="0.5ex" fz="md">
              <TokenRender
                size="var(--mantine-spacing-lg)"
                type="token"
                image_slug="usdc"
              />
              <Text fw="bold">
                {countFormatter(total, {
                  maximumFractionDigits: 2,
                  compact: false,
                })}
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={2}>
            <Box className={classes.line} />
          </Grid.Col>
          <Grid.Col span={1} className={classes.row}>
            <Text size="sm">USDC in wallet</Text>
          </Grid.Col>
          <Grid.Col
            span={1}
            className={classes.row_value}
            data-testid="wallet balance"
          >
            <WalletBalance unconnected="show" />
          </Grid.Col>
          <Grid.Col span={2} mt="lg">
            <Group justify="flex-end">
              <Button variant="subtle" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" miw="8em" size="lg" disabled={total <= 0}>
                Mint
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    )
  },
)
