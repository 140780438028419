import { memo } from 'react'

import { Group, ScrollArea, Stack, Table, Text } from '@mantine/core'

import { TokenPairSpace } from '@repo/common/components/TokenPair'
import { TokenRender } from '@repo/common/components/TokenRender'
import {
  countFormatter,
  usdLargeNumberFormatter,
} from '@repo/common/helpers/formatters'
import { useGetVaultComposition } from '@repo/common/queries/vaults'

import { useMobileSwitch } from '@/helpers/useMobileSwitch'

import { VaultShareSparkline } from '../VaultShareSparkline'

export const Holdings = memo<{ id: RubyID }>(function Holdings({ id }) {
  const { data: vault } = useGetVaultComposition({ id })
  const mobile_switch = useMobileSwitch()

  if (vault == null) return <Text opacity={0.7}>Empty Vault</Text>

  return (
    <ScrollArea scrollbars="x" w="100%" type="auto" pb="md">
      <Table
        horizontalSpacing={mobile_switch({ base: 'xs', sm: 'lg' })}
        verticalSpacing="sm"
      >
        <Table.Thead>
          <Table.Tr opacity={0.6} fs="sm">
            <Table.Th>Token</Table.Th>
            <Table.Th>Tokens</Table.Th>
            <Table.Th>Value Held History</Table.Th>
            <Table.Th>Total Value</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {vault.composition.map((item, index) => (
            <Table.Tr key={index}>
              <Table.Td>
                {/* Token */}
                <Group gap="xxs" wrap="nowrap">
                  <TokenPairSpace size="50px">
                    <TokenRender size="50px" {...item} />
                  </TokenPairSpace>
                  <Stack gap="0" miw="max-content">
                    <Text opacity={1}>{item.name}</Text>
                    <Text size="sm" opacity={0.8} miw="max-content">
                      {item.long_name}
                    </Text>
                  </Stack>
                </Group>
              </Table.Td>
              <Table.Td>
                {/* Shares */}
                <Text>{countFormatter(item.shares, { compact: false })}</Text>
              </Table.Td>
              <Table.Td>
                {/* History */}
                <VaultShareSparkline id={id} composition_name={item.name} />
              </Table.Td>
              <Table.Td>
                {/* total value */}
                <Text>
                  {usdLargeNumberFormatter(item.usdc_value, {
                    maximumFractionDigits: 1,
                  })}
                </Text>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  )
})
