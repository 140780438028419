import { memo } from 'react'

import { TokenImage, type TokenImageSlug } from '../TokenImage'
import { TokenPair } from '../TokenPair'
import { TokenVault } from '../TokenVault'

export type TokenRenderProps = {
  type: 'token' | 'fund' | 'lp' | 'vault'
  image_slug: TokenImageSlug | [TokenImageSlug, TokenImageSlug]
}

export type TokenRenderType = TokenRenderProps['type']
export { type TokenImageSlug }

export const TokenRender = memo<
  TokenRenderProps & {
    size?: `var(--mantine-spacing-${string})` | `${number}px`
  }
>(function TokenRender({ type, image_slug, size = '80px' }) {
  if (image_slug instanceof Array) {
    return (
      <TokenPair first={image_slug[0]} second={image_slug[1]} size={size} />
    )
  }

  switch (type) {
    case 'token':
    case 'fund':
      return <TokenImage image_slug={image_slug} size={size} />
    case 'vault':
      return <TokenVault image_slug={image_slug} size={size} />
  }
})
