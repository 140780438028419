import { memo } from 'react'

import { Button, Group, Stack, Text } from '@mantine/core'

import { LinkButton } from '@repo/common/components/LinkButton'

export const PanelBuyComplete = memo<{ onCancel: () => void }>(
  function PanelBuyComplete({ onCancel }) {
    return (
      <Stack w="300" align="center" gap="50">
        <Text size="xl">Success!</Text>
        <Group justify="flex-end">
          <Button onClick={onCancel} variant="light">
            Close
          </Button>
          <LinkButton to="/portfolio">My Portfolio</LinkButton>
        </Group>
      </Stack>
    )
  },
)
