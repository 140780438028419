import dayjs, { type Dayjs } from 'dayjs'
import * as _ from 'lodash-es'

import { calc_APY_between } from '@repo/common/calculations/apy_math'
import { percentage_formatter } from '@repo/common/helpers/formatters'

export function price_per_share_to_balance(nav: {
  price_per_share: number
  date: number
}) {
  return {
    balance: nav.price_per_share,
    date: nav.date,
  }
}

type DailyNAV = { date: number; price_per_share: number }
export function find_nav_of_date(dailies: DailyNAV[], for_date: Dayjs) {
  const result = dailies.find(({ date }, index) => {
    const same = dayjs(date).isSame(for_date, 'day')
    if (same) return true

    const next = dailies[index + 1]?.date
    if (next && dayjs(next).isAfter(for_date, 'date')) return true
    return false
  })
  if (result != null) return result
  return _.minBy(dailies, 'date')
}

export function to_apy(
  first: DailyNAV | undefined,
  second: DailyNAV | undefined,
) {
  if (first == null || second == null) return 'n/a'
  return calc_APY_between(
    price_per_share_to_balance(first),
    price_per_share_to_balance(second),
  )
}

export function to_rate(
  first: DailyNAV | undefined,
  second: DailyNAV | undefined,
) {
  if (first == null || second == null) return 'n/a'
  return percentage_formatter(
    (100 * (second.price_per_share - first.price_per_share)) /
      first.price_per_share,
  )
}
