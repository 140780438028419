import { useLocalStorage } from '@repo/common/hooks/useLocalStorage'

export type CartOneType = {
  id: RubyID
  deposit_type: DepositType
  quantity: number
}

export type CartTwoType = {
  id: RubyID
  deposit_token: number
  deposit_usdc: number
}

export type StoryKey = 'cart_fund_buy' | 'cart_vault_buy' | 'cart_vault_sell'

export function useBuyCartFund() {
  const [values, setValues, clear] = useLocalStorage<CartOneType>({
    key: 'cart_fund_buy' satisfies StoryKey,
    getInitialValueInEffect: false,
  })

  return {
    values,
    setValues,
    clear,
  }
}

export function useBuyCartVault() {
  const [values, setValues, clear] = useLocalStorage<CartTwoType>({
    key: 'cart_vault_buy' satisfies StoryKey,
    getInitialValueInEffect: false,
  })

  return {
    values,
    setValues,
    clear,
  }
}

export function useSellCartVault() {
  const [values, setValues, clear] = useLocalStorage<CartOneType>({
    key: 'cart_vault_sell' satisfies StoryKey,
    getInitialValueInEffect: false,
  })

  return {
    values,
    setValues,
    clear,
  }
}
