import { memo, useEffect } from 'react'

import { Stack } from '@mantine/core'

import { SumsubComponent } from '@repo/common/components/SumsubComponent'
import { useGetFlags } from '@repo/common/hooks/flags'
import { useGetKycStatus } from '@repo/common/queries/kyc'

export const PanelIdentification = memo<{ onCompleted: () => void }>(
  function PanelIdentification({ onCompleted }) {
    const { data } = useGetKycStatus()

    useEffect(() => {
      if (data?.passed) {
        onCompleted()
      }
    }, [data?.passed, onCompleted])

    return (
      <Stack w="600px">
        <SumsubComponent onCompleted={onCompleted} />
      </Stack>
    )
  },
)

export const useSkipIdentification = () => {
  const { is_demo_site } = useGetFlags()
  const { data } = useGetKycStatus()
  return Boolean(data?.passed || is_demo_site)
}
