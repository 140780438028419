import { memo } from 'react'

import { Box, Group, ScrollArea, Stack, Table, Text } from '@mantine/core'

import { TokenRender } from '@repo/common/components/TokenRender'
import {
  type TokenSymbol,
  useListPortfolio,
} from '@repo/common/queries/products'

import { countFormatter, usdFormatter } from '../../helpers/formatters'
import { LinkButton } from '../LinkButton'
import { LiquidityPosition } from '../LiquidityPosition'

// eslint-disable-next-line max-lines-per-function
import classes from './PortfolioTable.module.css'

// eslint-disable-next-line max-lines-per-function
export const PortfolioTable = memo(function PortfolioTable({}) {
  const { entries, positions, totals } = useListPortfolio()

  return (
    <Stack maw="100%">
      <ScrollArea scrollbars="x" w="100%" type="auto" pb="md">
        <Table classNames={classes}>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Token</Table.Th>
              <Table.Th>Current Value</Table.Th>
              <Table.Th>Tokens</Table.Th>
              <Table.Th>Price per Token</Table.Th>
              <Table.Th>Average Price Paid</Table.Th>
              <Table.Th>Gain/Loss $</Table.Th>
              <Table.Th>Gain/Loss %</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {entries.map(
              ({ symbol, image_slug, type, usdc_value, tokens, price }) => (
                <Table.Tr key={symbol} h="60px">
                  <Table.Td>
                    <Group wrap="nowrap" gap="0.75em">
                      <TokenRender
                        image_slug={image_slug}
                        type={type}
                        size="30px"
                      />
                      <Text ta="center">{symbol}</Text>
                    </Group>
                  </Table.Td>
                  <Table.Td data-testid={symbol}>
                    {usdFormatter(usdc_value)}
                  </Table.Td>
                  <Table.Td>
                    {countFormatter(tokens, {
                      maximumFractionDigits: symbol == 'USDC' ? 2 : 4,
                      compact: false,
                    })}
                  </Table.Td>
                  <Table.Td>
                    <>{usdFormatter(price)}</>
                  </Table.Td>
                  <Table.Td>
                    <Text size="sm" opacity={0.7}>
                      coming soon...
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text size="sm" opacity={0.7}>
                      coming soon...
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text size="sm" opacity={0.7}>
                      coming soon...
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    {symbol != 'USDC ⇄ TECH' && (
                      <PortfolioActions symbol={symbol} />
                    )}
                  </Table.Td>
                </Table.Tr>
              ),
            )}
          </Table.Tbody>
          <Table.Tfoot>
            <Table.Tr>
              <Table.Th>Totals</Table.Th>
              <Table.Td data-testid="total">
                {usdFormatter(totals.usdc_value)}
              </Table.Td>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
            </Table.Tr>
          </Table.Tfoot>
        </Table>
      </ScrollArea>
      <ScrollArea scrollbars="x" type="auto" pb="md">
        <Stack p="lg" miw="max-content">
          {positions?.map((p) => <LiquidityPosition position={p} key={p.id} />)}
        </Stack>
      </ScrollArea>
    </Stack>
  )
})

function PortfolioActions({ symbol }: { symbol: TokenSymbol }) {
  if (symbol === 'TECH') {
    return (
      <Group justify="flex-end" gap="0">
        <LinkButton
          to="/investments/$symbol"
          params={{ symbol }}
          size="sm"
          variant="subtle"
          miw="120px"
        >
          Invest
        </LinkButton>
        <Box w="120px" />
        <LinkButton
          to="/investments/$symbol"
          params={{ symbol }}
          size="sm"
          variant="subtle"
          miw="120px"
        >
          Details
        </LinkButton>
      </Group>
    )
  }
  if (symbol === 'TVLT') {
    return (
      <Group justify="flex-end" gap="0">
        <LinkButton
          to="/vault/$symbol"
          params={{ symbol }}
          size="sm"
          variant="subtle"
          miw="120px"
        >
          Deposit
        </LinkButton>
        <LinkButton
          to="/vault/$symbol"
          params={{ symbol }}
          size="sm"
          variant="subtle"
          miw="120px"
        >
          Redeem
        </LinkButton>
        <Box w="120px" />
      </Group>
    )
  }
  return null
}
