import { memo, useMemo } from 'react'

import dayjs from 'dayjs'
import * as _ from 'lodash-es'

import { Metrics } from '@repo/common/components/Metrics/Metrics'
import {
  find_nav_of_date,
  to_apy,
  to_rate,
} from '@repo/common/helpers/daily_nav_helpers'
import { useFundDailyNav } from '@repo/common/queries/funds'

export const CumulativePerformance = memo<{ fund_id: RubyID }>(
  // eslint-disable-next-line max-lines-per-function
  function CumulativePerformance({ fund_id }) {
    const { data, isLoading } = useFundDailyNav({ id: fund_id })

    const metrics = useMemo(() => {
      const dailies = data.map((nav) => ({
        date: nav.date,
        price_per_share: nav.nav_price_per_share,
      }))

      const today_nav = _.last(dailies)
      if (today_nav == null) {
        return []
      }

      const today_day = dayjs(today_nav?.date)

      const one_month_nav = find_nav_of_date(
        dailies,
        today_day.subtract(1, 'month'),
      )
      const quarter_nav = find_nav_of_date(
        dailies,
        today_day.subtract(3, 'month'),
      )
      const jan_1_nav = find_nav_of_date(dailies, today_day.startOf('year'))

      const one_year_nav = find_nav_of_date(
        dailies,
        today_day.subtract(1, 'year'),
      )

      const inception_nav = dailies[0]

      return [
        {
          label: '1 Month',
          since_date: dayjs(one_month_nav!.date),
          rate: to_rate(one_month_nav, today_nav),
        },
        {
          label: '3 Month',
          since_date: dayjs(quarter_nav!.date),
          rate: to_rate(quarter_nav, today_nav),
        },
        {
          label: 'YTD',
          since_date: dayjs(jan_1_nav!.date),
          rate: to_rate(jan_1_nav, today_nav),
          apy: to_apy(jan_1_nav, today_nav),
        },
        {
          label: '1 Year',
          since_date: dayjs(one_year_nav!.date),
          rate: to_rate(one_year_nav, today_nav),
          apy: to_apy(one_year_nav, today_nav),
        },
        {
          label: 'Inception',
          since_date: dayjs(inception_nav.date),
          rate: to_rate(inception_nav, today_nav),
          apy: to_apy(inception_nav, today_nav),
        },
      ]
    }, [data])

    if (isLoading) return null

    return <Metrics data={metrics} />
  },
)
