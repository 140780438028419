import { Stack } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'

import { useListFunds } from '@repo/common/queries/funds'

import { CompaniesOverview } from '@/components/CompaniesOverview'
import { ContentBox } from '@/components/ContentBox'
import { HeadlineAndDetails } from '@/components/HeadlineAndDetails'
import { InvestmentRow } from '@/components/InvestmentRow'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/_app/research/')({
  component: ResearchPage,
})

function ResearchPage() {
  const { data: funds } = useListFunds()

  return (
    <Stack gap="xl" maw="80rem">
      <HeadlineAndDetails
        title="Research"
        description="Choose a portfolio company to learn why we invested"
      />
      {funds.map((fund) => (
        <ContentBox key={fund.symbol}>
          <Stack>
            <InvestmentRow fund_id={fund.id} variant="research" />
            <CompaniesOverview fund_id={fund.id} variant="overview" />
          </Stack>
        </ContentBox>
      ))}
    </Stack>
  )
}
