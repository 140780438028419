import { useMemo } from 'react'

import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { erc20Abi } from 'abitype/abis'
import * as _ from 'lodash-es'
import { type Hex, parseUnits } from 'viem'
import {
  useAccount,
  usePublicClient,
  useSwitchChain,
  useWriteContract,
} from 'wagmi'

import { active_chain } from '@repo/common/blockchain/config'

import type { TokenImageSlug } from '../../components/TokenImage'
import type { TokenRenderProps } from '../../components/TokenRender'
import { merge_queries } from '../../helpers/query_helpers'
import { useGetDecimals } from '../blockchain/useGetDecimals'
import { keys as wallet_keys } from '../blockchain/useGetWalletBalance'
import {
  set_looking_for_change,
  useGetWalletBalance,
} from '../blockchain/useGetWalletBalance'
import { useListCryptoContracts } from '../crypto_contracts'
import { useGetPositions, useGetTokenPrice } from '../fission_dex'
import { graphql_call } from '../helpers/server_query'
import { useGetVaultLatestData } from '../vaults'

import { query_list_products } from './products_graphql'

export const keys = createQueryKeyStore({
  products: {
    all: null,
  },
})

export type ProductSymbol = 'TVLT' | 'TECH'
export type TokenSymbol = 'USDC' | 'FISN' | ProductSymbol

export type TokenType = {
  symbol: TokenSymbol
  token_address: CryptoAddress
  image_slug: TokenImageSlug
  name: string
  long_name: string
}

export type ProductType = {
  id: RubyID
  type: 'vault' | 'fund'
  escrow_wallet_address: CryptoAddress
  price_per_share: number
} & TokenType

export type ProductFindBy = {
  id?: RubyID
  symbol?: string
}

function convertProductType(type: string): ProductType['type'] {
  if (type == null) return 'fund'

  return type.split('_')[1] as ProductType['type']
}

export function useListProducts() {
  const results = useQuery<ProductType[]>({
    queryKey: keys.products.all.queryKey,
    queryFn: async () => {
      const data = await graphql_call({ query: query_list_products })

      if (data == null) return []

      return data
        .map((p) => {
          if (
            p.escrow_wallet_address == null ||
            p.token_address == null ||
            p.type == null
          ) {
            return null
          }

          return {
            ...p,
            type: convertProductType(p.type),
          } as ProductType
        })
        .filter((p): p is ProductType => p != null)
    },
    staleTime: 60 * 60 * 1000, // 1 hour
    gcTime: Infinity,
  })

  const data = results.data ?? []

  const product_find_by = ({ id, symbol }: ProductFindBy) =>
    data.find((f) => f.id == id || f.symbol === symbol?.toUpperCase())

  return {
    ...results,
    data,
    product_find_by,
  }
}
export function useProductInfo(args: ProductFindBy) {
  const { product_find_by, ...others } = useListProducts()

  return { ...others, data: product_find_by(args) }
}

export type TokenFindBy = {
  symbol?: string
}

export function useListTokens() {
  const products = useListProducts()
  const contracts = useListCryptoContracts()

  const all_tokens: TokenType[] = useMemo(() => {
    const filter_contracts = contracts.data.contracts
      .filter((c) => c.category == 'token')
      .map((c) => ({
        symbol: c.symbol! as TokenSymbol,
        token_address: c.address,
        image_slug: c.symbol!.toLowerCase() as TokenImageSlug,
        name: c.name,
        long_name: c.name,
      }))

    return [...products.data, ...filter_contracts]
  }, [products.data, contracts.data])

  const token_find_by = ({ symbol }: TokenFindBy) =>
    all_tokens.find((f) => f.symbol.toUpperCase() === symbol?.toUpperCase())

  return {
    ...merge_queries([products, contracts]),
    token_find_by,
    all_tokens,
  }
}
export function useTokenInfo(args: TokenFindBy) {
  const { token_find_by, ...others } = useListTokens()

  return { ...others, data: token_find_by(args) }
}

// eslint-disable-next-line complexity
export function useBuyProduct({ id }: { id: RubyID }) {
  const queryClient = useQueryClient()
  const { writeContractAsync } = useWriteContract()
  const { switchChainAsync } = useSwitchChain()
  const { product_find_by } = useListProducts()
  const { wallet_address, decimals: usdc_decimals } =
    useGetWalletBalance('USDC')
  const { decimals: tech_decimals } = useGetWalletBalance('TECH')
  const { token_find_by } = useListTokens()
  const client = usePublicClient({ chainId: active_chain.id })

  const product = product_find_by({ id })
  const usdc_address = token_find_by({ symbol: 'USDC' })?.token_address
  const tech_address = token_find_by({ symbol: 'TECH' })?.token_address

  const isReady =
    wallet_address != null &&
    product != null &&
    usdc_decimals != null &&
    tech_decimals != null &&
    usdc_address != null &&
    tech_address != null &&
    client != null

  const result = useMutation({
    mutationFn: async ({
      token,
      amount,
    }: {
      token: Extract<TokenSymbol, 'USDC' | 'TECH'>
      amount: number
    }) => {
      if (!isReady) return

      // ensure network is correct
      await switchChainAsync({ chainId: active_chain.id })

      const tx = await writeContractAsync({
        abi: erc20Abi,
        address: token == 'USDC' ? usdc_address : tech_address,
        functionName: 'transfer',
        chainId: active_chain.id,
        args: [
          product.escrow_wallet_address,
          parseUnits(
            amount.toString(),
            token == 'USDC' ? usdc_decimals : tech_decimals,
          ),
        ],
      })

      await client.waitForTransactionReceipt({ hash: tx })
    },
    onSuccess: async () => {
      set_looking_for_change(product!.token_address)
      await queryClient.invalidateQueries({
        queryKey: wallet_keys.balance.wallet(wallet_address!).queryKey,
      })
    },
  })
  return {
    ...result,
    isReady,
  }
}

// eslint-disable-next-line complexity
export function useBuyVaultProduct({ id }: { id: RubyID }) {
  const queryClient = useQueryClient()
  const { writeContractAsync } = useWriteContract()
  const { switchChainAsync } = useSwitchChain()
  const { product_find_by } = useListProducts()
  const { wallet_address, decimals: usdc_decimals } =
    useGetWalletBalance('USDC')
  const { decimals: tech_decimals } = useGetWalletBalance('TECH')
  const { token_find_by } = useListTokens()
  const client = usePublicClient({ chainId: active_chain.id })

  const product = product_find_by({ id })
  const usdc_address = token_find_by({ symbol: 'USDC' })?.token_address
  const tech_address = token_find_by({ symbol: 'TECH' })?.token_address

  const isReady =
    wallet_address != null &&
    product != null &&
    usdc_decimals != null &&
    tech_decimals != null &&
    usdc_address != null &&
    tech_address != null &&
    client != null

  const result = useMutation({
    mutationFn: async ({
      // token,
      deposit_usdc,
      deposit_token,
    }: {
      token: Extract<TokenSymbol, 'TECH'>
      deposit_usdc: number
      deposit_token: number
    }) => {
      if (!isReady) return

      // ensure network is correct
      await switchChainAsync({ chainId: active_chain.id })

      let usdc_tx: Hex | undefined
      let token_tx: Hex | undefined
      if (deposit_usdc > 0) {
        usdc_tx = await writeContractAsync({
          abi: erc20Abi,
          address: usdc_address,
          functionName: 'transfer',
          chainId: active_chain.id,
          args: [
            product.escrow_wallet_address,
            parseUnits(deposit_usdc.toString(), usdc_decimals),
          ],
        })
      }
      if (deposit_token > 0) {
        token_tx = await writeContractAsync({
          abi: erc20Abi,
          address: tech_address,
          functionName: 'transfer',
          chainId: active_chain.id,
          args: [
            product.escrow_wallet_address,
            parseUnits(deposit_token.toString(), tech_decimals),
          ],
        })
      }

      if (usdc_tx != null)
        await client.waitForTransactionReceipt({ hash: usdc_tx })
      if (token_tx != null)
        await client.waitForTransactionReceipt({ hash: token_tx })
    },
    onSuccess: async () => {
      set_looking_for_change(product!.token_address)
      await queryClient.invalidateQueries({
        queryKey: wallet_keys.balance.wallet(wallet_address!).queryKey,
      })
    },
  })
  return {
    ...result,
    isReady,
  }
}

// eslint-disable-next-line complexity
export function useSellVault({ id }: { id: RubyID }) {
  const queryClient = useQueryClient()
  const { writeContractAsync } = useWriteContract()
  const { switchChainAsync } = useSwitchChain()
  const { data: product_data } = useProductInfo({ id })
  const { data: vault_decimals } = useGetDecimals(product_data?.token_address)
  const client = usePublicClient({ chainId: active_chain.id })
  const { address: wallet_address } = useAccount()

  const isReady =
    client != null &&
    product_data != null &&
    vault_decimals != null &&
    wallet_address != null

  const result = useMutation({
    mutationFn: async ({ amount }: { amount: number }) => {
      if (!isReady) return

      // ensure network is correct
      await switchChainAsync({ chainId: active_chain.id })

      const tx = await writeContractAsync({
        abi: erc20Abi,
        address: product_data.token_address,
        functionName: 'transfer',
        chainId: active_chain.id,
        args: [
          product_data.escrow_wallet_address,
          parseUnits(amount.toString(), vault_decimals),
        ],
      })

      await client.waitForTransactionReceipt({ hash: tx })
    },
    onSuccess: async () => {
      set_looking_for_change(product_data!.token_address)
      await queryClient.invalidateQueries({
        queryKey: wallet_keys.balance.wallet(wallet_address!).queryKey,
      })
    },
  })
  return {
    ...result,
    isReady,
  }
}
/*

  const { data: blockNumber } = useBlockNumber({ watch: true })
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: balance_results.queryKey })
  }, [balance_results.queryKey, blockNumber, queryClient])
*/

type PortfolioEntry = TokenRenderProps & {
  symbol: TokenSymbol | 'USDC ⇄ TECH'
  tokens: number
  usdc_value: number
  price?: number
}

// eslint-disable-next-line max-lines-per-function
export function useListPortfolio() {
  const tvlt_query = useProductInfo({ symbol: 'TVLT' })
  const tech_query = useProductInfo({ symbol: 'TECH' })
  const { address } = useAccount()
  const positions_query = useGetPositions({ wallet_address: address })

  const tech_price_result = useGetTokenPrice({ symbol: 'TECH' })
  const fisn_price_result = useGetTokenPrice({ symbol: 'FISN' })
  const vault_price_result = useGetVaultLatestData({ id: tvlt_query.data?.id })

  const wallet_tech = useGetWalletBalance('TECH')
  const wallet_tvlt = useGetWalletBalance('TVLT')
  const wallet_fisn = useGetWalletBalance('FISN')

  // eslint-disable-next-line complexity
  const entries = useMemo<PortfolioEntry[]>(() => {
    if (
      tvlt_query.data == null ||
      tech_query.data == null ||
      vault_price_result.data == null ||
      tech_price_result.data == null ||
      fisn_price_result.data == null
    ) {
      return []
    }
    return [
      {
        ...tvlt_query.data,
        tokens: wallet_tvlt.balance,
        usdc_value:
          wallet_tvlt.balance * vault_price_result.data.price_per_token,
        price: vault_price_result.data.price_per_token,
      },
      {
        ...tech_query.data,
        tokens: wallet_tech.balance,
        usdc_value: wallet_tech.balance * tech_price_result.data,
        price: tech_price_result.data,
      },
      {
        type: 'token',
        image_slug: 'fisn',
        symbol: 'FISN',
        tokens: wallet_fisn.balance,
        usdc_value: wallet_fisn.balance * fisn_price_result.data,
        price: fisn_price_result.data,
      } satisfies PortfolioEntry,
      {
        type: 'lp',
        image_slug: ['usdc', 'technology'],
        symbol: 'USDC ⇄ TECH',
        tokens: positions_query.data?.positions.length ?? 0,
        usdc_value:
          (positions_query.data?.total_fund_tokens ?? 0) *
            tech_price_result.data +
          (positions_query.data?.total_usdc_tokens ?? 0),
      } satisfies PortfolioEntry,
    ].filter((e) => e.tokens > 0 || e.type == 'fund')
  }, [
    fisn_price_result.data,
    positions_query.data?.positions.length,
    positions_query.data?.total_fund_tokens,
    positions_query.data?.total_usdc_tokens,
    tech_price_result.data,
    tech_query.data,
    tvlt_query.data,
    vault_price_result.data,
    wallet_fisn.balance,
    wallet_tech.balance,
    wallet_tvlt.balance,
  ])

  return {
    entries,
    totals: {
      usdc_value: _.sumBy(entries, 'usdc_value'),
      tokens: _.sumBy(entries, 'tokens'),
    },
    positions: positions_query.data?.positions ?? [],
    ...merge_queries([
      tvlt_query,
      tech_query,
      positions_query,
      tech_price_result,
      vault_price_result,
      wallet_tech,
      wallet_tvlt,
    ]),
  }
}
