import { memo, type ReactNode } from 'react'

import { Box, type BoxProps } from '@mantine/core'

import {
  TokenImage,
  type TokenImageSlug,
} from '@repo/common/components/TokenImage'

import classes from './TokenPair.module.css'

export const TokenPair = memo<{
  first: TokenImageSlug
  second: TokenImageSlug
  size?: ReactProps<typeof TokenImage>['size']
}>(function TokenPair({ first, second, size = '80px' }) {
  return (
    <TokenPairSpace size={size}>
      <TokenImage image_slug={first} />
      <TokenImage image_slug={second} />
    </TokenPairSpace>
  )
})

export const TokenPairSpace = memo<
  BoxProps & {
    size: ReactProps<typeof TokenImage>['size']
    children: ReactNode
  }
>(function TokenPairSpace({ size, ...props }) {
  return (
    <Box
      className={classes.pair}
      style={{ '--token-size': size } as React.CSSProperties}
      {...props}
    />
  )
})
