import { memo, useCallback, useState } from 'react'

import { Button, Group, Stack, Text } from '@mantine/core'

import { useGetFlags } from '@repo/common/hooks/flags'

import { useAuth } from '../../queries/auth'
import { FissionModal } from '../FissionModal'
import { LinkButton } from '../LinkButton'
import { LoginSignup } from '../LoginSignup'
import { RequireAccess, TestNetLink } from '../RequireAccess'

// eslint-disable-next-line max-lines-per-function, complexity
export const LaunchApp = memo<{ no_login?: boolean }>(function LaunchApp({
  no_login = false,
}) {
  const { is_main_site } = useGetFlags()
  const { data } = useAuth()
  const [state, set_state] = useState<'closed' | 'open' | 'done'>('closed')

  const onClose = useCallback(() => set_state('closed'), [])

  if (!is_main_site) {
    return <LinkButton to="/investments">Launch App</LinkButton>
  }

  if (data?.is_member && state == 'closed') {
    return (
      <Button component="a" href={TestNetLink}>
        Launch Testnet
      </Button>
    )
  }

  if (state == 'closed') {
    return (
      <Group>
        {data?.is_authenticated || (
          <Button onClick={() => set_state('open')}>Get Early Access</Button>
        )}
        {no_login || (
          <LinkButton variant="subtle" to="/login">
            Login
          </LinkButton>
        )}
      </Group>
    )
  }

  return (
    <FissionModal
      withCloseButton={true}
      opened
      onClose={onClose}
      closeOnEscape={true}
      closeOnClickOutside={true}
    >
      {state == 'open' ? (
        <Stack maw="600px">
          <LoginSignup
            starting_mode="signup"
            onSuccess={() => set_state('done')}
          >
            <Stack gap="md">
              <Text size="xxl" lh="1.4" ta="center">
                The future of VC is built on DeFi. Join the revolution.
              </Text>
              <Text mt="lg" size="lg" ta="center" opacity={0.8}>
                Testnet is available now for a select group of users.{' '}
              </Text>
              <Text ta="center" size="lg" opacity={0.8}>
                Sign up to request early access and get notified first for
                upcoming rewards.
              </Text>
            </Stack>
          </LoginSignup>
        </Stack>
      ) : (
        <RequireAccess onClose={onClose}>
          <></>
        </RequireAccess>
      )}
    </FissionModal>
  )
})
