import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { useMutation, useQuery } from '@tanstack/react-query'
import * as _ from 'lodash-es'

import { active_chain } from '@repo/common/blockchain/config'
import { always_default_data } from '@repo/common/helpers/query_helpers'
import { graphql_call } from '@repo/common/queries/helpers/server_query'

import { useAuth } from '../auth'

import {
  mutation_add_wallet,
  query_get_wallets,
} from './wallet_addresses_graphql'

export type NetworkTypes = 'base' | 'base_sepolia' | 'ethereum' | 'sepolia'

export const keys = createQueryKeyStore({
  wallets: {
    all: null,
  },
})

export function useGetWallets() {
  const auth = useAuth()

  return always_default_data(
    [],
    useQuery({
      queryKey: keys.wallets.all.queryKey,
      queryFn: async () => {
        const results = await graphql_call({ query: query_get_wallets })
        return results?.wallets
      },
      enabled: auth.data?.is_authenticated,
    }),
  )
}

export function active_network() {
  const network = _.snakeCase(active_chain.name)
  if (network == 'hardhat') return 'sepolia'
  return network as NetworkTypes
}

export function useAddWallet() {
  return useMutation({
    mutationFn: async ({
      address,
      wallet_provider,
    }: {
      address: string
      wallet_provider: string
    }) => {
      return graphql_call({
        query: mutation_add_wallet,
        variables: {
          input: {
            address,
            network: active_network(),
            wallet_provider,
          },
        },
      })
    },
  })
}
