import { memo, type ReactNode } from 'react'

import { Accordion, Stack } from '@mantine/core'

import { IconPlus } from '@repo/common/components/Icons'

import classes from './HomeFAQs.module.css'

export type FAQs = { question: string; answer: ReactNode }[]

export const HomeFAQs = memo<{ list: FAQs }>(function HomeFAQs({ list }) {
  return (
    <Accordion
      w="100%"
      classNames={{
        chevron: classes.chevron,
        control: classes.control,
        content: classes.content,
        label: classes.label,
      }}
      chevron={<IconPlus className={classes.icon} />}
      multiple
    >
      {list.map((item) => (
        <Accordion.Item key={item.question} value={item.question}>
          <Accordion.Control>{item.question}</Accordion.Control>
          <Accordion.Panel>
            <Stack>{item.answer}</Stack>
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  )
})
