/* eslint-disable @cspell/spellchecker */
import * as _ from 'lodash-es'

import { type CompanyData, section_ordering } from './text_sections'

export { section_ordering, type CompanyData }

const companies = import.meta.glob<{ default: CompanyData }>('./text/*.json')

export async function getCompanyText({ cms_name }: { cms_name: string }) {
  const filename = _.kebabCase(cms_name)
  if (companies[`./text/${filename}.json`] == null) {
    console.error(`Company ${cms_name} not found`)
  }
  return (await companies[`./text/${filename}.json`]()).default
}

// Images

export const images = import.meta.glob<{ default: string }>('./images/*.png', {
  eager: true,
})

const dark_background_logos = [
  'algolia',
  'anduril',
  'byte-dance',
  'canva',
  'carta',
  'celonis',
  'chainalysis',
  'circle',
  'chime',
  'discord',
  'epic-games',
  'figma',
  'gopuff',
  'impossible-foods',
  'rappi',
  'redwood-materials',
  'stripe',
  'kraken',
]

const no_white_mode_logos: string[] = ['epic-games']

export function getCompanyLogo({ cms_name }: { cms_name: string }) {
  const filename = _.kebabCase(cms_name)
  if (images[`./images/${filename}.png`] == null) {
    if (cms_name !== 'none') {
      console.error(`Company ${cms_name} not found`)
    }
    return {
      image_url: '',
      dark_background_logo: true,
      no_white_mode_logos: false,
    }
  }

  return {
    image_url: images[`./images/${filename}.png`].default,
    dark_background_logo: dark_background_logos.includes(filename),
    no_white_mode_logo: no_white_mode_logos.includes(filename),
  }
}
