import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import * as _ from 'lodash-es'

import type { CompanyRoundsData } from '@/data/companies/research_types'

dayjs.extend(quarterOfYear)

// TODO added vitejs and coverage
function quartersBetween(start: dayjs.Dayjs, end: dayjs.Dayjs) {
  const year_diff = end.year() - start.year()
  const quarter_diff = end.quarter() - start.quarter()
  const quarters = year_diff * 4 - quarter_diff - 1 // -1 because we want to exclude the current quarter
  return quarters
}

export function valuationsByQuarter(rounds?: CompanyRoundsData[string]) {
  if (rounds == null || rounds.length === 0) {
    return []
  }
  const [first_round, ...other_rounds] = _.sortBy(rounds, ({ date }) =>
    dayjs(date).valueOf(),
  ).filter(
    (
      round,
    ): round is CompanyRoundsData[string][number] & {
      post_money_valuation: number
    } => round.post_money_valuation != null,
  )
  let last_date = first_round.date
  const valuations: number[] = [first_round.post_money_valuation]

  other_rounds.forEach(({ date, post_money_valuation }) => {
    _.times(quartersBetween(dayjs(last_date), dayjs(date)), () => {
      valuations.push(_.last(valuations)!)
    })
    valuations.push(post_money_valuation)
    last_date = date
  })

  return valuations
}
