export const y_tick_formatting = {
  fontSize: 'var(--mantine-font-size-sm)',
  fill: 'var(--mantine-color-dark-0)',
  opacity: 0.8,
}

export const x_tick_formatting = {
  fontSize: 'var(--mantine-font-size-sm)',
  fill: 'var(--mantine-color-dark-0)',
  opacity: 0.6,
}
