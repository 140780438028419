import { memo } from 'react'

import { Image } from '@mantine/core'

import component_financial from './component_financial.jpg'
import component_overview from './component_overview.jpg'
import component_page2 from './component_page2.jpg'
import connect_wallet from './connect_wallet.jpeg'
import current_price from './current_price.jpg'
import deposit from './deposit.jpg'
import document from './document.jpg'
import fund_chart from './fund_chart.jpg'
import fund_overview from './fund_overview.jpg'
import home from './home.jpg'
import qr_code from './qr.png'
import research from './research.jpg'
import season_investors from './season_investors.jpg'
import trade from './trade.jpg'
import trade_details from './trade_details.jpg'
import verify_accreditation from './verify_accreditation.jpg'
import verify_institution from './verify_institution.jpg'
import web3trade from './web3trade.png'

const images = {
  component_financial,
  component_overview,
  component_page2,
  connect_wallet,
  current_price,
  deposit,
  document,
  fund_chart,
  home,
  qr_code,
  research,
  season_investors,
  fund_overview,
  trade,
  trade_details,
  verify_accreditation,
  verify_institution,
  web3trade,
}

export const FakeSection = memo<{
  image: keyof typeof images
  width?: string
}>(function FakeSection({ image, width }) {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Image src={images[image]} w={width} />
  )
})
