import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { skipToken, useQuery, useQueryClient } from '@tanstack/react-query'
import { erc20Abi } from 'abitype/abis'
import { formatUnits } from 'viem'
import { useAccount, usePublicClient } from 'wagmi'

import { active_chain } from '@repo/common/blockchain/config'

import { merge_queries } from '../../helpers/query_helpers'
import { type TokenSymbol, useTokenInfo } from '../products'

import { useGetDecimals } from './useGetDecimals'

export const keys = createQueryKeyStore({
  balance: {
    wallet: (wallet_address: CryptoAddress) => ({
      queryKey: [{ wallet_address }],
      contextQueries: {
        token: (token_address: CryptoAddress) => ({
          queryKey: [{ token_address }],
        }),
      },
    }),
  },
})

let look_for_change: false | CryptoAddress = false
export const set_looking_for_change = (token_address: CryptoAddress) => {
  look_for_change = token_address
}

type BalanceType = {
  last: bigint | undefined
  balance: bigint
}
// eslint-disable-next-line complexity
export function useGetWalletBalance(token_symbol?: TokenSymbol) {
  const queryClient = useQueryClient()
  const { address: wallet_address, isConnected } = useAccount()
  const client = usePublicClient({ chainId: active_chain.id })
  const token_address = useTokenInfo({ symbol: token_symbol }).data
    ?.token_address

  const enabled =
    client != null && wallet_address != null && token_address != null
  const balance_results = useQuery<BalanceType>({
    queryKey: keys.balance.wallet(wallet_address!)._ctx.token(token_address!)
      .queryKey,
    queryFn: !enabled
      ? skipToken
      : async ({ queryKey }) => {
          const last = queryClient.getQueryData<BalanceType>(queryKey)?.balance
          const balance = await client.readContract({
            abi: erc20Abi,
            functionName: 'balanceOf',
            args: [wallet_address], // if doesn't exist, query is disabled
            address: token_address,
          })
          return {
            last,
            balance,
          }
        },
    staleTime: 1 * 60 * 1000, // 1 minute
    refetchInterval: (query) => {
      if (look_for_change == false) return false
      if (token_address != look_for_change) return false
      if (query.state.data?.last != query.state.data?.balance) {
        look_for_change = false
        return false // change!
      }
      return 1000 // every second
    },
    gcTime: Infinity,
  })

  const decimals_results = useGetDecimals(token_address)
  const isSuccess = balance_results.isSuccess && decimals_results.isSuccess

  let balance: number = 0
  if (isSuccess) {
    balance = Number(
      formatUnits(balance_results.data.balance, decimals_results.data),
    )
  }

  return {
    ...merge_queries([balance_results, decimals_results]),
    isConnected,
    isSuccess,
    balance,
    queryKey: keys.balance.wallet(wallet_address!)._ctx.token(token_address!)
      .queryKey,
    decimals: decimals_results.data,
    wallet_address,
    token_address,
  }
}
