import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Group, ScrollArea, Stack } from '@mantine/core'
import { useHover, usePrevious } from '@mantine/hooks'
import * as _ from 'lodash-es'

import { LinkButton } from '@repo/common/components/LinkButton'
import { get_pie_color } from '@repo/common/core/pie_colors'
import { safe_percentage } from '@repo/common/helpers/math'
import { useFundCompaniesValue, useFundInfo } from '@repo/common/queries/funds'

import { useOnClickNavigate } from '@/helpers/useOnClickNavigate'
import { valuationsByQuarter } from '@/helpers/valuationsByQuarter'
import { useCompanyMetrics, useCompanyRounds } from '@/queries/company'

import { CompanyLogo } from '../CompanyLogo'
import { CompanyValuationSparkline } from '../CompanyValuationSparkline'
import { Table, table_classes } from '../Table'
import { WeightedSegmentChart } from '../WeightedSegmentChart'

import { CompanyInvestors } from './CompanyInvestors'

import classes from './CompaniesOverview.module.css'

const research_page = '/research/$symbol/company/$cms_name'

export const CompaniesOverview = memo<{
  fund_id: RubyID
  test_hover?: string
  variant: 'overview' | 'details'
  // eslint-disable-next-line max-lines-per-function, complexity
}>(function CompaniesOverview({ fund_id, test_hover, variant }) {
  const { onClickNavigate, navigate } = useOnClickNavigate()
  const { data: fund, isLoading } = useFundInfo({ fund_id })
  const { data: rounds } = useCompanyRounds()
  const { data: companies } = useCompanyMetrics()
  const { data: company_values } = useFundCompaniesValue({ id: fund_id })
  const [highlighted, setHighlighted] = useState<string | null>(
    test_hover ?? null,
  )
  const { hovered, ref } = useHover<HTMLTableElement>()
  const wasHovered = usePrevious(hovered)

  useEffect(() => {
    if (!hovered && wasHovered) {
      setHighlighted(null)
    }
  }, [hovered, wasHovered, setHighlighted])

  const segment_data = useMemo(() => {
    if (company_values == null || rounds == null) return []

    const total_value =
      company_values.cash_balance + company_values.funds_held_value

    const list = _.sortBy(
      company_values.companies.map((company) => ({
        id: company.cms_name,
        name: company.name,
        cms_name: company.cms_name,
        product: companies?.[company.cms_name]?.product ?? 'Something cool',
        weight: safe_percentage(company.value_held, total_value),
        valuation: _.last(valuationsByQuarter(rounds[company.cms_name])),
      })),
      variant == 'overview' ? 'valuation' : 'weight',
    ).reverse()
    if (variant == 'details') {
      list.push({
        id: 'cash',
        name: 'Cash',
        cms_name: 'none',
        product: 'USD',
        weight: safe_percentage(company_values.cash_balance, total_value),
        valuation: undefined,
      })
    }

    return list.map((company, index) => ({
      ...company,
      color: get_pie_color(index),
    }))
  }, [companies, company_values, rounds, variant])

  const onClick = useCallback(
    (cms_name: string) => {
      void navigate({
        to: research_page,
        params: { symbol: fund!.symbol, cms_name },
      })
    },
    [fund, navigate],
  )

  if (isLoading || fund == null) return null

  return (
    <Stack>
      <ScrollArea scrollbars="x" w="100%" type="auto">
        <Table ref={ref} classNames={table_classes}>
          <Table.Thead>
            <Table.Tr>
              <Table.Th w="150" pl="30">
                Company
              </Table.Th>
              <Table.Th>Last Valuation</Table.Th>
              <Table.Th w="200">Product</Table.Th>
              {variant == 'overview' && <Table.Th>Top 3 Investors</Table.Th>}
              {variant == 'details' && <Table.Th>Weight</Table.Th>}
              {variant == 'overview' && (
                <Table.Th ta="right" pr="30">
                  Research
                </Table.Th>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {segment_data.map(
              ({ name: company_name, cms_name, weight, product }, index) => {
                return (
                  <Table.Tr
                    key={cms_name}
                    onClick={onClickNavigate({
                      to: research_page,
                      params: { symbol: fund.symbol, cms_name },
                    })}
                    onMouseOver={() => setHighlighted(cms_name)}
                    data-hover={cms_name === highlighted}
                    className={classes.tr}
                  >
                    <Table.Td>
                      <CompanyLogo
                        cms_name={cms_name}
                        w="100px"
                        title={company_name}
                      />
                    </Table.Td>
                    <Table.Td>
                      <CompanyValuationSparkline
                        cms_name={cms_name}
                        w={variant == 'details' ? '40' : '120'}
                      />
                    </Table.Td>
                    <Table.Td>{product}</Table.Td>
                    {variant == 'overview' && (
                      <Table.Td>
                        <CompanyInvestors cms_name={cms_name} />
                      </Table.Td>
                    )}
                    {variant == 'details' && (
                      <Table.Td>
                        <Group align="center" gap="xs" wrap="nowrap">
                          <Box w="20" h="20" bg={get_pie_color(index)} />{' '}
                          {weight}%
                        </Group>
                      </Table.Td>
                    )}
                    {variant == 'overview' && (
                      <Table.Td>
                        <Group justify="flex-end">
                          <LinkButton
                            size="xs"
                            to={research_page}
                            params={{ symbol: fund.symbol, cms_name }}
                            variant="subtle"
                          >
                            View Details
                          </LinkButton>
                        </Group>
                      </Table.Td>
                    )}
                  </Table.Tr>
                )
              },
            )}
          </Table.Tbody>
        </Table>
      </ScrollArea>
      {variant == 'details' && (
        <WeightedSegmentChart
          weights={segment_data}
          h={30}
          onHover={setHighlighted}
          highlighted={highlighted}
          onClick={onClick}
        />
      )}
    </Stack>
  )
})
