import { type ComponentType, memo, useCallback } from 'react'

import { Button, type ButtonProps } from '@mantine/core'
import { useCounter, useDisclosure } from '@mantine/hooks'

import { PanelBuyComplete } from '@repo/common/components/PanelBuyComplete'
import {
  PanelIdentification,
  useSkipIdentification,
} from '@repo/common/components/PanelIdentification'
import { PanelPaymentPending } from '@repo/common/components/PanelPaymentPending'
import { WorkflowModal } from '@repo/common/components/WorkflowModal'
import { useBuyCartFund } from '@repo/common/queries/cart'

import { PanelBuy } from '../PanelBuy'
import { PanelLogin, useSkipLogin } from '../PanelLogin'
import { PanelPayment } from '../PanelPayment'

type PanelProps = {
  onCompleted: () => void
  onCancel: () => void
  fund_id: RubyID
}

const steps: {
  title: string
  Panel: ComponentType<PanelProps>
  progress: number
  can_close?: boolean
  useSkipCheck?: () => boolean
}[] = [
  { title: 'Mint', Panel: PanelBuy, progress: 30, can_close: true },
  {
    title: 'Mint: Login',
    Panel: PanelLogin,
    progress: 10,
    useSkipCheck: useSkipLogin,
  },
  {
    title: 'Mint: Identity Verification',
    Panel: PanelIdentification,
    progress: 40,
    useSkipCheck: useSkipIdentification,
  },
  { title: 'Mint: Payment', Panel: PanelPayment, progress: 10 },
  {
    title: 'Mint: Payment Verification',
    Panel: PanelPaymentPending,
    progress: 10,
  },
  { title: 'Mint: Completed', Panel: PanelBuyComplete, progress: 0 },
]

export const ProcessBuy = memo<{ fund_id: RubyID } & ButtonProps>(
  function ProcessBuy({ fund_id, ...props }) {
    const [active, setActive] = useCounter(0, { min: 0, max: steps.length - 1 })
    const [opened, { open, close }] = useDisclosure(false)
    const { clear: clear_chart } = useBuyCartFund()

    const onCancel = useCallback(() => {
      close()
      setActive.reset()
      clear_chart()
    }, [clear_chart, close, setActive])

    const skip_checks = steps.map((s) => s.useSkipCheck?.())

    let next = active + 1
    while (skip_checks[next]) {
      next += 1
    }

    const onCompleted = useCallback(() => {
      setActive.set(next)
    }, [next, setActive])

    const progress = steps
      .slice(0, active)
      .reduce((acc, step) => acc + step.progress, 0)

    const { Panel, title, can_close = false } = steps[active]
    return (
      <>
        <Button {...props} onClick={open} />
        <WorkflowModal
          progress={progress}
          opened={opened}
          onClose={close}
          title={title}
          closeOnEscape={can_close}
          closeOnClickOutside={can_close}
        >
          <Panel
            fund_id={fund_id}
            onCancel={onCancel}
            onCompleted={onCompleted}
          />
        </WorkflowModal>
      </>
    )
  },
)
