import {
  type ComponentType,
  memo,
  type NamedExoticComponent,
  useCallback,
  useState,
} from 'react'

import { Button, Group, Stack, Title } from '@mantine/core'
import { useAccount, useDisconnect } from 'wagmi'

import { FissionModal } from '@repo/common/components/FissionModal'
import { LoginLogout } from '@repo/common/components/LoginLogout'
import { LoginPasswordReset } from '@repo/common/components/LoginPasswordReset'
import { LoginSignup } from '@repo/common/components/LoginSignup'
import { useIsAuthenticated } from '@repo/common/queries/auth'

import { ContentBox } from '@/components/ContentBox'

import { Logo } from '../Logo'

type ComponentProps = {
  [key: string]: unknown
}

type ProfileActionTypes = 'login' | 'logout' | 'update' | undefined
function get_profile_action(type: ProfileActionTypes): {
  title: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component:
    | ComponentType<ComponentProps>
    | NamedExoticComponent<ComponentProps>
    | null
} {
  switch (type) {
    case 'login':
      return { title: 'Login', Component: LoginSignup }
    case 'update':
      return { title: 'Change Password', Component: LoginPasswordReset }
    case 'logout':
      return { title: 'Logout', Component: LoginLogout }
    default:
      return { title: 'Logout', Component: null }
  }
}

export const ProfileActions = memo(function ProfileActions() {
  const is_authenticated = useIsAuthenticated()
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const [opened, setOpened] = useState<ProfileActionTypes>()

  const { title, Component } = get_profile_action(opened)

  const onClose = useCallback(() => setOpened(undefined), [])

  return (
    <>
      <FissionModal
        opened={opened != null}
        onClose={onClose}
        title={title}
        closeOnEscape={true}
        closeOnClickOutside={true}
        withCloseButton={true}
      >
        {Component != null && <Component Logo={Logo} onSuccess={onClose} />}
      </FissionModal>
      <ContentBox variant="fading" p="xl" mt="xl2">
        <Stack align="flex-start">
          <Title order={2}>Profile and Account Actions</Title>
          <Group>
            {is_authenticated ? (
              <>
                <Button
                  size="sm"
                  variant="subtle"
                  onClick={() => setOpened('update')}
                >
                  Change Password
                </Button>
                <Button
                  size="sm"
                  variant="subtle"
                  onClick={() => setOpened('logout')}
                >
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button variant="subtle" onClick={() => setOpened('login')}>
                  Login
                </Button>
              </>
            )}
            {Boolean(address) && (
              <Button size="sm" variant="subtle" onClick={() => disconnect()}>
                Disconnect Wallet
              </Button>
            )}
          </Group>
        </Stack>
      </ContentBox>
    </>
  )
})
