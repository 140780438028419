import type { BaseError } from 'wagmi'

export function get_blockchain_error_message(error: Error | null) {
  if (error == null) return 'unknown error'

  let message = (error as BaseError).shortMessage
  if (message == null) message = 'internal error'

  return message
}
