export const section_ordering = [
  'overview',
  'revenue',
  'funding rounds',
  'product',
  'key competitors',
  'differentiation',
  'customers',
  'sales & marketing',
  'industry',
  'industry drivers',
  'public comparable',
  'team',
  'board',
  'market risks',
] as const

export type SectionsKeys = (typeof section_ordering)[number]
export type ContentKeys = Exclude<SectionsKeys, 'funding rounds'>

export const content_sections = section_ordering.filter(
  (s) => s !== 'funding rounds',
) as ContentKeys[]

export type CompanyData = {
  name: string
} & {
  [s in ContentKeys]?: string[]
}
