import { memo } from 'react'

import { Button } from '@mantine/core'
import {
  Link,
  type LinkComponent,
  type LinkProps,
} from '@tanstack/react-router'
import * as _ from 'lodash-es'

const go_back = () => window.history.back()

type ButtonProps<C> = ReactProps<typeof Button<C>>

type LinkButtonProps =
  | (LinkProps &
      ButtonProps<LinkComponent<'a'>> & {
        back?: false
      })
  | (ButtonProps<'button'> & {
      back: true
    })

export const LinkButton = memo<LinkButtonProps>(function LinkButton(props) {
  if (props.back) {
    return <Button {...props} onClick={go_back} />
  }

  return <Button component={Link} {...props} />
}, _.isEqual)
// params are passed as an inline object so isEqual is needed
