import { memo, useEffect } from 'react'

import { useLocation } from '@tanstack/react-router'
import { usePostHog } from 'posthog-js/react'

// global variable as there is only one URL
let previous_href: string | undefined

export const PageTracking = memo(function PageTracking() {
  const href = useLocation({
    select: (location) => location.href,
  })
  const posthog = usePostHog()

  useEffect(() => {
    if (href !== previous_href) {
      // eslint-disable-next-line @cspell/spellchecker
      posthog.capture('$pageview')
    }
    previous_href = href
  }, [href, posthog])

  return null
})
