import { Fragment, memo } from 'react'

import { Anchor, Table } from '@mantine/core'
import * as _ from 'lodash-es'

import { useListCryptoContracts } from '@repo/common/queries/crypto_contracts'
import { useListProducts } from '@repo/common/queries/products'

export const ShowAddresses = memo(function ShowAddresses() {
  const { data: products } = useListProducts()
  const {
    data: { contracts, contract_base_url },
  } = useListCryptoContracts()
  return (
    <Table>
      <Table.Tbody>
        {_.sortBy(products, 'symbol').map((p) => (
          <Fragment key={p.symbol}>
            <Table.Tr>
              <Table.Td ta="right">{p.symbol}</Table.Td>
              <Table.Td>
                <Anchor
                  target="_blank"
                  href={`${contract_base_url}/${p.token_address}`}
                >
                  {p.token_address}
                </Anchor>
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td ta="right">{p.symbol} wallet</Table.Td>
              <Table.Td>
                <Anchor
                  target="_blank"
                  href={`${contract_base_url}/${p.escrow_wallet_address}`}
                >
                  {p.escrow_wallet_address}
                </Anchor>
              </Table.Td>
            </Table.Tr>
          </Fragment>
        ))}
        {_.sortBy(_.filter(contracts, { category: 'token' }), 'name').map(
          (c) => (
            <Table.Tr key={c.name}>
              <Table.Td ta="right">{c.symbol || c.name}</Table.Td>
              <Table.Td>
                <Anchor
                  target="_blank"
                  href={`${contract_base_url}/${c.address}`}
                >
                  {c.address}
                </Anchor>
              </Table.Td>
            </Table.Tr>
          ),
        )}
        {_.sortBy(_.filter(contracts, { category: 'dex' }), 'name').map((c) => (
          <Table.Tr key={c.name}>
            <Table.Td ta="right">{c.symbol || c.name}</Table.Td>
            <Table.Td>
              <Anchor
                target="_blank"
                href={`${contract_base_url}/${c.address}`}
              >
                {c.address}
              </Anchor>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
})
