import { memo } from 'react'

import { Stack, type StackProps, Text, Title } from '@mantine/core'

import classes from './HeadlineAndDetails.module.css'

export const HeadlineAndDetails = memo<
  { title: string; description: string } & StackProps
>(function HeadlineAndDetails({ title, description, ...props }) {
  return (
    <Stack gap="xxs" {...props}>
      <Title order={1} c="white" fw="bold" lh="1" opacity={0.8}>
        {title}
      </Title>
      <Text
        size="md"
        opacity={0.6}
        lh="1.25"
        pl="0.1em"
        className={classes.description}
      >
        {description}
      </Text>
    </Stack>
  )
})
