import { memo } from 'react'

import { Loader, Stack, Text } from '@mantine/core'
import { useTimeout } from '@mantine/hooks'

export const PanelPaymentPending = memo<{ onCompleted: () => void }>(
  function PanelPaymentPending({ onCompleted }) {
    useTimeout(onCompleted, 5000, {
      autoInvoke: true,
    })

    return (
      <Stack w="400" align="center">
        <Loader />
        <Text>Transfer Being Verified</Text>
      </Stack>
    )
  },
)
