import { memo } from 'react'

import { TokenImage } from '../TokenImage'

import classes from './TokenVault.module.css'

export const TokenVault = memo<ReactProps<typeof TokenImage>>(
  function TokenVault({ image_slug, size = 80 }) {
    return (
      <div
        className={classes.pair}
        style={{ '--token-size': size } as React.CSSProperties}
      >
        <TokenImage image_slug="vault" />
        <TokenImage image_slug={image_slug} />
      </div>
    )
  },
)
