import { memo } from 'react'

import { Button, type ButtonProps, Image, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import * as _ from 'lodash-es'
import { useAccount, useConnect } from 'wagmi'

import { FissionModal } from '../FissionModal'

import coinbase_url from './logos/coinbase.png'
import keplr_url from './logos/keplr.png'
import meta_mask_url from './logos/meta_mask.png'

const name_to_image: Record<string, string> = {
  MetaMask: meta_mask_url,
  'Coinbase Wallet': coinbase_url,
  Keplr: keplr_url,
}

export const ConnectWalletButton = memo<
  Omit<ButtonProps, 'onClick'> & { message?: string }
>(function ConnectWalletButton({ message = 'Connect Wallet', ...props }) {
  const { connectors, connect, error, isPending } = useConnect()
  const account = useAccount()
  const [opened, disclosure_handlers] = useDisclosure(false)

  /* istanbul ignore next */
  if (error) {
    console.error({
      status: account.status,
      error,
    })
  }

  if (account.isConnected) return null

  const clean_connectors = _.uniqBy(connectors, (c) => c.name)

  return (
    <>
      <FissionModal
        closeOnClickOutside={true}
        closeOnEscape={true}
        withCloseButton={true}
        opened={opened}
        onClose={disclosure_handlers.close}
        title="Connect Wallet"
      >
        <Stack>
          {clean_connectors.map((c) => (
            <Button
              key={c.uid}
              onClick={() => connect({ connector: c })}
              disabled={isPending}
              leftSection={
                name_to_image[c.name] ? (
                  <Image h="lg" src={name_to_image[c.name]} />
                ) : undefined
              }
            >
              <Text>{c.name}</Text>
            </Button>
          ))}
        </Stack>
      </FissionModal>
      <Button
        size="md"
        {...props}
        onClick={disclosure_handlers.open}
        loading={account.isConnecting}
      >
        {message}
      </Button>
    </>
  )
})
