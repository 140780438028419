import { Stack } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'

import { useGetFlags } from '@repo/common/hooks/flags'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/flags')({
  component: FlagsPage,
})

function FlagsPage() {
  const values = useGetFlags()
  return (
    <Stack>
      <div>
        Active Flags:
        <br />
        {JSON.stringify(values, null, 2)}
      </div>
    </Stack>
  )
}
