import { Button, createTheme } from '@mantine/core'

import { app_colors, fission_theme } from '@repo/common/core/theme'

export { app_colors }

// building in dark so flip light
export const theme = createTheme({
  ...fission_theme,
  colors: { ...app_colors, primary: app_colors.blue },
  black: app_colors.dark[9],
  components: {
    Button: Button.extend({
      defaultProps: {
        size: 'md',
      },
    }),
  },
})
