import { useMemo } from 'react'

import { skipToken, useQuery } from '@tanstack/react-query'
import { erc20Abi } from 'abitype/abis'
import { usePublicClient } from 'wagmi'

import { active_chain } from '@repo/common/blockchain/config'

import type { TokenImageSlug } from '../../components/TokenImage'
import { positionManagerAbi } from '../blockchain/abis/positionManagerAbi'
import { useGetDecimals } from '../blockchain/useGetDecimals'
import {
  type InSideType,
  useGetPositions,
  useGetTokenPrice,
} from '../fission_dex'
import { calc_shares } from '../helpers/block_helpers'
import { useListTokens } from '../products'

import { keys, useVaultInfo } from './vaults_queries'

export type VaultCompositionType = {
  name: 'TECH' | 'USDC' | 'M^0' | 'USDC ⇄ TECH'
  long_name: string
  usdc_value: number
  percentage: number
  shares: number
} & (
  | {
      type: 'token'
      image_slug: TokenImageSlug
    }
  | {
      type: 'lp'
      image_slug: [TokenImageSlug, TokenImageSlug]
    }
)

// eslint-disable-next-line max-lines-per-function, complexity
export function useGetVaultComposition({ id }: { id: RubyID | undefined }) {
  const { data: vault_data } = useVaultInfo({ vault_id: id })
  const { token_find_by } = useListTokens()
  // TODO
  const { data: tech_price } = useGetTokenPrice({ symbol: 'TECH' })

  const tech_address = token_find_by({ symbol: 'TECH' })?.token_address
  const usdc_address = token_find_by({ symbol: 'USDC' })?.token_address
  const { data: tech_decimals } = useGetDecimals(tech_address)
  const { data: usdc_decimals } = useGetDecimals(usdc_address)
  const { data: lp_data } = useGetVaultLPTokens({ id })

  const vault_address = vault_data?.escrow_wallet_address

  const client = usePublicClient({ chainId: active_chain.id })

  const enabled =
    id != null &&
    client != null &&
    vault_address != null &&
    tech_address != null &&
    tech_decimals != null &&
    usdc_address != null &&
    usdc_decimals != null &&
    lp_data != null &&
    tech_price != null

  return useQuery<{ tvl: number; composition: VaultCompositionType[] }>({
    queryKey:
      id == null ? [] : keys.vaults.details({ id })._ctx.composition.queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const balances = await client.multicall({
            contracts: [
              {
                abi: erc20Abi,
                functionName: 'balanceOf',
                address: tech_address,
                args: [vault_address],
              },
              {
                abi: erc20Abi,
                functionName: 'balanceOf',
                address: usdc_address,
                args: [vault_address],
              },
            ],
          })

          const tech_shares = calc_shares(balances[0].result, tech_decimals)
          const usdc_shares = calc_shares(balances[1].result, usdc_decimals)

          const composition: Omit<VaultCompositionType, 'percentage'>[] = [
            {
              type: 'token',
              image_slug: 'technology',
              name: 'TECH',
              long_name: 'Fission Technology Holdings',
              shares: tech_shares,
              usdc_value: tech_price * tech_shares,
            },
            {
              type: 'token',
              image_slug: 'usdc',
              name: 'USDC',
              long_name: 'USD Coin',
              shares: usdc_shares,
              usdc_value: usdc_shares,
            },
            {
              type: 'token',
              image_slug: 'm-0',
              name: 'M^0',
              long_name: 'M (Yield earning Stable coin)',
              shares: 0,
              usdc_value: 0,
            },
            {
              type: 'lp',
              image_slug: ['technology', 'usdc'],
              name: 'USDC ⇄ TECH',
              long_name: 'USDC ⇄ TECH LP Token',
              shares: lp_data.positions.length,
              usdc_value:
                lp_data.total_fund_tokens * tech_price +
                lp_data.total_usdc_tokens,
            },
          ]

          const tvl = composition.reduce(
            (acc, item) => acc + item.usdc_value,
            0,
          )

          return {
            tvl,
            composition: composition.map(
              (item) =>
                ({
                  ...item,
                  percentage:
                    (tvl > 0 ? item.usdc_value / tvl : 1 / composition.length) *
                    100,
                }) as VaultCompositionType,
            ),
          }
        },
  })
}

export function useGetVaultSellReturnAmounts({
  id,
  sell_usdc,
}: {
  id: RubyID | undefined
  sell_usdc: number
}) {
  const { data: vault } = useGetVaultComposition({ id })

  return useMemo(() => {
    if (vault == null) {
      return {
        usdc_amount: 0,
        tech_amount: 0,
      }
    }
    const usdc_data = vault.composition.find((i) => i.name == 'USDC')!
    const tech_data = vault.composition.find((i) => i.name == 'TECH')!

    const ratio_usdc_to_tech =
      usdc_data.usdc_value / (tech_data.usdc_value + usdc_data.usdc_value)
    const return_usdc_value = ratio_usdc_to_tech * sell_usdc
    const return_tech_value = (1 - ratio_usdc_to_tech) * sell_usdc
    const usdc_amount =
      usdc_data.usdc_value == 0
        ? 0
        : (return_usdc_value / usdc_data.usdc_value) * usdc_data.shares
    const tech_amount =
      tech_data.usdc_value == 0
        ? 0
        : (return_tech_value / tech_data.usdc_value) * tech_data.shares

    return {
      usdc_amount,
      tech_amount,
    }
  }, [sell_usdc, vault])
}

// eslint-disable-next-line max-lines-per-function, complexity, max-lines-per-function
export function useGetVaultLPTokens({ id }: { id: RubyID | undefined }) {
  const { data: vault_data } = useVaultInfo({ vault_id: id })

  return useGetPositions({ wallet_address: vault_data?.escrow_wallet_address })
}

export function useGetVaultShares({ id }: { id: RubyID | undefined }) {
  const tvlt_address = useVaultInfo({ vault_id: id })?.data?.token_address
  const { data: tvlt_decimals } = useGetDecimals(tvlt_address)

  const client = usePublicClient({ chainId: active_chain.id })

  const enabled =
    id != null &&
    client != null &&
    tvlt_address != null &&
    tvlt_decimals != null

  return useQuery<number>({
    queryKey:
      id == null ? [] : keys.vaults.details({ id })._ctx.shares.queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const shares = await client.readContract({
            abi: positionManagerAbi,
            functionName: 'totalSupply',
            address: tvlt_address,
          })
          return calc_shares(shares, tvlt_decimals)
        },
  })
}

// Maybe use instead of subgraph?
// export function useGetVaultPrice({ id }: { id: RubyID | undefined }) {
//   const share_data = useGetVaultShares({ id })
//   const compose_data = useGetVaultComposition({ id })
//   const product_data = useProductInfo({ id })

//   if (share_data.isSuccess && share_data.data == 0) {
//     // starting price condition
//     return {
//       ...product_data,
//       data: product_data.data?.price_per_share ?? 0,
//     }
//   }

//   const price = (compose_data.data?.tvl ?? 0) / (share_data.data ?? 1)

//   return {
//     ...merge_queries([share_data, compose_data]),
//     data: price,
//   }
// }

// eslint-disable-next-line complexity
export function useGetDepositAmounts({
  id,
  deposit_usdc,
  deposit_token,
}: {
  id: RubyID | undefined
  deposit_usdc: undefined | number
  deposit_token: undefined | number
  in_side: InSideType
}) {
  const { data: vault } = useGetVaultComposition({ id })

  const enabled = vault != null

  const use_tokens =
    (vault?.composition.find((e) => e.name == 'TECH')?.usdc_value ?? 0) <
    (vault?.composition.find((e) => e.name == 'USDC')?.usdc_value ?? 0)

  return {
    enabled,
    deposit_token: use_tokens ? deposit_token : 0,
    deposit_usdc: use_tokens ? 0 : deposit_usdc,
  }
}
