import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { useQueries, useQuery } from '@tanstack/react-query'

import { type CompanyData, getCompanyText } from '@/data/companies'

import type {
  CompanyMetricsData,
  CompanyRoundsData,
  InvestorRankingsData,
  InvestorsData,
} from '../data/companies/research_types'

export const queryKeys = createQueryKeyStore({
  research: {
    company: (cms_name: string) => [{ cms_name }],
    investors: null,
    investor_rankings: null,
    metrics: null,
    rounds: null,
  },
})

const make_query = (cms_name: string) => ({
  queryKey: queryKeys.research.company(cms_name).queryKey,
  queryFn: async () => getCompanyText({ cms_name }),
  gcTime: Infinity,
  staleTime: 1000 * 60 * 60 * 24, // one day
})

export function useGetCompany({ cms_name }: { cms_name: string }) {
  return useQuery<CompanyData>(make_query(cms_name))
}

export function useGetCompanies(names: string[]) {
  return useQueries({
    queries: names.map((cms_name) => make_query(cms_name)),
    combine: (results) => ({
      data: results.map((result) => result.data),
      isLoading: results.some((result) => result.isLoading),
    }),
  })
}
export function useInvestors() {
  return useQuery<InvestorsData>({
    queryKey: queryKeys.research.investors.queryKey,
    queryFn: async () => {
      return (await import('@/data/companies/investors.json')).default
    },
    gcTime: Infinity,
    staleTime: 1000 * 60 * 60 * 24, // one day
  })
}

export function useInvestorRankings() {
  return useQuery<InvestorRankingsData>({
    queryKey: queryKeys.research.investor_rankings.queryKey,
    queryFn: async () => {
      return (await import('@/data/companies/investor_rankings.json')).default
    },
    gcTime: Infinity,
    staleTime: 1000 * 60 * 60 * 24, // one day
  })
}

export function useCompanyMetrics() {
  return useQuery<CompanyMetricsData>({
    queryKey: queryKeys.research.metrics.queryKey,
    queryFn: async () => {
      return (await import('@/data/companies/metrics.json'))
        .default as CompanyMetricsData
    },
    gcTime: Infinity,
    staleTime: 1000 * 60 * 60 * 24, // one day
  })
}

export function useCompanyRounds() {
  return useQuery<CompanyRoundsData>({
    queryKey: queryKeys.research.rounds.queryKey,
    queryFn: async () => {
      return (await import('@/data/companies/rounds.json')).default
    },
    gcTime: Infinity,
    staleTime: 1000 * 60 * 60 * 24, // one day
  })
}
