import { memo } from 'react'

import { LineChart, PieChart } from '@mantine/charts'
import { Title } from '@mantine/core'
import { Legend } from 'recharts'

import { get_pie_color } from '@repo/common/core/pie_colors'
import {
  percentage_formatter,
  value_formatter,
} from '@repo/common/helpers/formatters'

import type { CompanyMetricsData } from '@/data/companies/research_types'
import type { CompanyData, SectionsKeys } from '@/data/companies/text_sections'

import { RevenueChart } from './RevenueChart'

// eslint-disable-next-line complexity
export function getBlockComponent({
  section,
  metrics,
  company,
}: {
  section: SectionsKeys
  metrics: CompanyMetricsData[string]
  company: CompanyData
}) {
  switch (section) {
    case 'key competitors':
      if (metrics?.market_share == null) return
      return (
        <>
          <Title order={2}>Market Share</Title>
          <ChartCompetitorsBlock
            market_share={metrics.market_share}
            company_name={company?.name ?? ''}
          />
        </>
      )
    case 'revenue':
      if (metrics.revenue == null) return
      return <RevenueChart metrics={metrics} />
    case 'product':
      if (metrics.kpi?.values == null) return
      return (
        <>
          <Title order={2} mb="sm">
            KPI: {metrics.kpi.type}
          </Title>
          <LineChart
            h={300}
            data={metrics.kpi.values}
            rightYAxisProps={{ width: 0 }}
            dataKey="year"
            valueFormatter={value_formatter}
            series={[{ name: 'value', color: 'primary.6' }]}
          />
        </>
      )
    default:
      return undefined
  }
}

const ChartCompetitorsBlock = memo<{
  market_share: CompanyMetricsData[string]['market_share']
  company_name: string
}>(function ChartCompetitorsBlock({ market_share, company_name }) {
  const pie_data = (market_share?.theirs ?? []).map((item, index) => ({
    name: item.company,
    value: item.percentage,
    color: get_pie_color(index + 2),
  }))
  pie_data.unshift({
    name: company_name,
    value: market_share?.my_percentage ?? 0,
    color: 'primary.6',
  })
  const total = pie_data.reduce((t, item) => t + item.value, 0)
  if (total < 99) {
    pie_data.push({
      name: 'other',
      value: 100 - total,
      color: 'dark.4',
    })
  }

  return (
    <PieChart
      h={300}
      w="100%"
      data={pie_data}
      withTooltip
      tooltipDataSource="segment"
      valueFormatter={percentage_formatter}
      startAngle={-270}
      endAngle={-360 - 270}
    >
      <Legend verticalAlign="bottom" height={50} />
    </PieChart>
  )
})
