import { memo, type ReactNode } from 'react'

import { ContentBox } from '@/components/ContentBox'

import { Link, type LinkProps } from '../Link'

import classes from './NavSideLinks.module.css'

export type NavLinks = (LinkProps & {
  Icon?: (props: { size: string }) => ReactNode
})[]

export const NavSideLinks = memo<{ links: NavLinks; children?: ReactNode }>(
  function NavSideLinks({ links, children }) {
    return (
      <ContentBox className={classes.link_box}>
        {children}
        {links.map(({ label, Icon, ...props }) => (
          <Link {...props} key={label} className={classes.link}>
            <>
              {Icon && <Icon size="1rem" />}
              {label}
            </>
          </Link>
        ))}
      </ContentBox>
    )
  },
)
