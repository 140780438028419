// https://react-svgr.com/playground/?jsxRuntime=automatic&memo=true&typescript=true
import { memo, type SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 1440 973" preserveAspectRatio="none" {...props}>
    <path
      fill="url(#a)"
      d="M648.5 57.5C221.502 159.689 110.181 509.63 50.052 612.189c-102.137 216.965-239.939 562.481 25.946 208.82C408.355 378.933 739.476 433.359 1484.5 243.422 2080.52 91.472 1914.87-90.172 1757.55-162h-341.01C1377.01-91.653 1075.5-44.689 648.5 57.5Z"
      style={{
        mixBlendMode: 'screen',
      }}
    />
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(-675.7492 358.03243 -297.78747 -562.04306 1230.77 151.812)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56F372" stopOpacity={0.15} />
        <stop offset={1} stopColor="#275DF0" />
      </radialGradient>
    </defs>
  </svg>
)
const Memo = memo(SvgComponent)
export default Memo
