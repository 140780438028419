import { memo, type ReactNode } from 'react'

import clsx from 'clsx'

import BackgroundGraphic1 from './BackgroundGraphic1'
import BackgroundGraphic2 from './BackgroundGraphic2'
import BackgroundGraphic3 from './BackgroundGraphic3'

import classes from './WebBackground.module.css'

export const WebBackground = memo<{ children: ReactNode; className?: string }>(
  function WebBackground({ children, className }) {
    return (
      <div className={clsx(classes.root, className)}>
        <BackgroundGraphic1 className={classes.graphic_1} />
        <BackgroundGraphic2 className={classes.graphic_2} />
        <BackgroundGraphic3 className={classes.graphic_3} />
        {children}
      </div>
    )
  },
)
