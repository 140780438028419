// https://react-svgr.com/playground/?jsxRuntime=automatic&memo=true&typescript=true
import { memo, type SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 1000 1000" preserveAspectRatio="none" {...props}>
    <path d="M0 0v438.406c310.171 0 561.594 251.423 561.594 561.594H1000C999.871 447.723 552.148 0 0 0Z" />
  </svg>
)
const Memo = memo(SvgComponent)
export default Memo
