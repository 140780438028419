/* eslint-disable complexity */

/* eslint-disable max-lines-per-function */
import { memo } from 'react'

import { Group, Stack, Text } from '@mantine/core'
import { useAccount } from 'wagmi'

import { ConnectWalletButton } from '@repo/common/components/ConnectWalletButton'
import { usdLargeNumberFormatter } from '@repo/common/helpers/formatters'
import { useListPortfolio } from '@repo/common/queries/products'

import { ContentBox } from '@/components/ContentBox'
import { useOnClickNavigate } from '@/helpers/useOnClickNavigate'

import classes from './PortfolioMiniBox.module.css'

export const PortfolioMiniBox = memo(function PortfolioMiniBox() {
  const { address } = useAccount()
  const { onClickNavigate } = useOnClickNavigate()
  const { totals } = useListPortfolio()

  return (
    <ContentBox
      onClick={onClickNavigate({
        to: '/portfolio',
      })}
      className={classes.root}
    >
      <Stack gap="0">
        <Text size="sm" opacity={0.8} mb="sm">
          My Balance
        </Text>
        {address == null ? (
          <ConnectWalletButton
            size="xs"
            variant="light"
            message="Connect Wallet"
          />
        ) : (
          <>
            <Text size="xl" data-testid="balance">
              {usdLargeNumberFormatter(totals.usdc_value, {
                maximumFractionDigits: 2,
              })}
            </Text>
            <Group mt="4" gap="4">
              <Text size="sm" c="green">
                soon
              </Text>
              <Text size="sm" opacity={0.8}>
                APY All Time
              </Text>
            </Group>
          </>
        )}
      </Stack>
    </ContentBox>
  )
})
