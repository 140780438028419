import { memo, type ReactNode } from 'react'

import { Group, Modal, ScrollArea, Stack } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import classes from './FissionModal.module.css'

export const FissionModal = memo<
  ReactProps<typeof Modal> & {
    belowTitle?: ReactNode
  }
>(function FissionModal({
  withCloseButton,
  belowTitle,
  title,
  children,
  ...props
}) {
  const isMobile = useMediaQuery('(max-width: 50em)')

  return (
    <Modal.Root
      fullScreen={isMobile}
      size="auto"
      classNames={{
        content: classes.content,
        header: classes.header,
        title: classes.title,
      }}
      {...props}
    >
      <Modal.Overlay
        color="var(--mantine-color-dark-3)"
        backgroundOpacity={0.35}
        blur={3}
      />
      <Modal.Content style={{ overflow: 'hidden' }}>
        <Stack
          // 100% just doesn't work
          mah="calc(100vh - var(--mantine-spacing-xl) - (2 * var(--modal-y-offset))"
          pos="relative"
          style={{ overflow: 'hidden' }}
          pl="calc(2*var(--mantine-spacing-xs) + (0.75rem* var(--mantine-scale)))"
          pr="xs"
          pb="xl"
        >
          <Modal.Header w="100%" flex="0 0">
            <Stack align="center" w="100%">
              <Group
                justify={withCloseButton ? 'space-between' : 'center'}
                align="center"
                w="100%"
              >
                <Modal.Title>{title}</Modal.Title>
                {withCloseButton && <Modal.CloseButton />}
              </Group>
              {belowTitle}
            </Stack>
          </Modal.Header>
          <ScrollArea.Autosize
            scrollbars="y"
            type="auto"
            offsetScrollbars
            classNames={{ viewport: classes.scrollbar_viewport }}
          >
            {children}
          </ScrollArea.Autosize>
        </Stack>
      </Modal.Content>
    </Modal.Root>
  )
})
