import { createFileRoute } from '@tanstack/react-router'

import { ResearchCompanyPage } from '@/components/ResearchCompanyPage'

// prettier-ignore
export const Route = createFileRoute('/_app/research/$symbol/company/$cms_name/')({
  component: ComponentIdPage,
})

function ComponentIdPage() {
  const { symbol, cms_name } = Route.useParams()

  return <ResearchCompanyPage symbol={symbol} cms_name={cms_name} />
}
