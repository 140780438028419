import { memo } from 'react'

import { Paper, Text } from '@mantine/core'
import isChromatic from 'chromatic'
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  x_tick_formatting,
  y_tick_formatting,
} from '@repo/common/components/LiquidityChart'
import {
  date_formatter,
  full_date_formatter,
  hour_formatter,
  tick_wrapper,
  usdFormatter,
} from '@repo/common/helpers/formatters'
import {
  type HistoryPeriod,
  useGetPriceHistory,
} from '@repo/common/queries/fission_dex'

// eslint-disable-next-line max-lines-per-function
export const DexPriceHistoryChart = memo<{
  height?: number
  period_type: HistoryPeriod
}>(
  // eslint-disable-next-line max-lines-per-function
  function DexPriceHistoryChart({ height = 300, period_type }) {
    const { data } = useGetPriceHistory({ period_type, symbol: 'TECH' })

    return (
      <ResponsiveContainer
        width="100%"
        height={height}
        style={
          {
            '--graph-font-size': 'var(--mantine-font-size-sm)',
          } as React.CSSProperties
        }
      >
        <LineChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Tooltip
            content={({ label, payload }) => (
              <ChartTooltip
                label={label as string}
                payload={payload as ToolTipType['payload']}
              />
            )}
          />
          <XAxis
            tick={x_tick_formatting}
            type="number"
            scale="time"
            dataKey="date"
            domain={['auto', 'dataMax']}
            interval="preserveStartEnd"
            tickFormatter={tick_wrapper(
              period_type == 'daily' ? date_formatter : hour_formatter,
            )}
          />
          <YAxis
            tick={y_tick_formatting}
            type="number"
            domain={['auto', 'auto']}
            orientation="right"
            tickFormatter={tick_wrapper((value) => usdFormatter(value, false))}
          />
          <Line
            isAnimationActive={!isChromatic()}
            dataKey="token_usdc_price"
            type="monotone"
            stroke="var(--mantine-color-green-5)"
            dot={false}
          ></Line>
        </LineChart>
      </ResponsiveContainer>
    )
  },
)

export type ToolTipType = {
  label: string | number
  payload?: { name: string; value: number }[]
}

export function ChartTooltip({ label, payload }: ToolTipType) {
  if (payload == null || payload.length == 0) return null

  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
      <Text size="sm" mb={5}>
        {full_date_formatter(label)}
      </Text>
      <Text size="md" c="green.5">
        Price: {usdFormatter(payload[0].value)}
      </Text>
    </Paper>
  )
}
