// https://react-svgr.com/playground/?jsxRuntime=automatic&memo=true&typescript=true
import { memo, type SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 723 1135" preserveAspectRatio="none" {...props}>
    <path
      fill="url(#a)"
      d="M726.5 219.5C299.502 321.689 188.181 671.63 128.052 774.189c-102.137 216.965-239.939 562.481 25.946 208.82C486.355 540.933 817.476 595.359 1562.5 405.422 2158.52 253.472 1992.87 71.828 1835.55 0h-341.01c-39.53 70.347-341.04 117.311-768.04 219.5Z"
      style={{
        mixBlendMode: 'screen',
      }}
    />
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(-675.7492 358.03243 -297.78747 -562.04306 1308.77 313.812)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56F372" stopOpacity={0.15} />
        <stop offset={1} stopColor="#275DF0" />
      </radialGradient>
    </defs>
  </svg>
)
const Memo = memo(SvgComponent)
export default Memo
