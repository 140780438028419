import { Stack } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'

import { useGetVersion } from '@repo/common/queries/versions'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/versions')({
  component: VersionsPage,
})

function VersionsPage() {
  const { data } = useGetVersion()
  return (
    <Stack>
      <div>
        Webapp Version:{' '}
        <a
          target="_blank"
          href={`https://github.com/fission-xyz/webapps/releases/tag/${__VERSION__.split('-')[0]}`}
          rel="noreferrer"
        >
          {__VERSION__}
        </a>{' '}
        @ {new Date(__VERSION_TIME__).toLocaleString()} - deployed @{' '}
        {new Date(__BUILT_TIME__).toLocaleString()}
      </div>
      <div>
        Server Version:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://github.com/fission-xyz/backend/commit/${data}`}
        >
          {data}
        </a>
      </div>
    </Stack>
  )
}
