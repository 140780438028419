import { memo, useMemo } from 'react'

import { BarChart } from '@mantine/charts'
import dayjs from 'dayjs'
import * as _ from 'lodash-es'

import { usdLargeNumberFormatter } from '@repo/common/helpers/formatters'

import type { CompanyRoundsData } from '@/data/companies/research_types'
import { useInvestors } from '@/queries/company'

import { Table, table_classes } from '../Table'

import { SectionBlock } from './SectionBlock'

import classNames from './RoundsBlock.module.css'

export const RoundsBlock = memo<{
  cms_name: string
  rounds: CompanyRoundsData[string]
  title: string
  onInViewport: ({
    title,
    in_viewport,
  }: {
    title: string
    in_viewport: boolean
  }) => void
  scroll_to: boolean
  onScrollFinish: () => void
}>(function RoundsBlock({ cms_name, rounds, ...props }) {
  const { data: investors_data } = useInvestors()
  const sorted = useMemo(() => {
    return _.sortBy(rounds, (r) => dayjs(r.date).valueOf())
  }, [rounds])

  const investors = investors_data?.[cms_name] ?? []

  return (
    <SectionBlock {...props}>
      <BarChart
        h={300}
        data={sorted}
        dataKey="round"
        valueFormatter={usdLargeNumberFormatter}
        withLegend
        legendProps={{ verticalAlign: 'bottom', height: 50 }}
        rightYAxisProps={{ width: 0 }}
        series={[
          {
            name: 'amount',
            label: 'Raised',
            color: 'teal.6',
          },
          {
            name: 'post_money_valuation',
            label: 'Post Valuation',
            color: 'blue.6',
          },
        ]}
      />
      <Table mt="sm" classNames={table_classes}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Date</Table.Th>
            <Table.Th>Round</Table.Th>
            <Table.Th>Raised</Table.Th>
            <Table.Th className={classNames.nowrap_cell} title="Post Valuation">
              Post Val.
            </Table.Th>
            <Table.Th>Key Investors</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {sorted.map(({ round, date, post_money_valuation, amount }) => (
            <Table.Tr key={date}>
              <Table.Td className={classNames.nowrap_cell}>
                {dayjs(date).format('MMM YYYY')}
              </Table.Td>
              <Table.Td className={classNames.nowrap_cell}>{round}</Table.Td>
              <Table.Td className={classNames.nowrap_cell}>
                {usdLargeNumberFormatter(amount)}
              </Table.Td>
              <Table.Td className={classNames.nowrap_cell}>
                {usdLargeNumberFormatter(post_money_valuation)}
              </Table.Td>
              <Table.Td>
                {investors
                  .filter((i) => i.round == round)
                  .map((i) => i.investor)
                  .join(', ')}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </SectionBlock>
  )
})
