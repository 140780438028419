import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { skipToken, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import * as _ from 'lodash-es'

import { graphql_call as graphql_price_call } from '@repo/common/queries/helpers/pricing_query'
import { graphql_call } from '@repo/common/queries/helpers/server_query'

import type { TokenImageSlug } from '../../components/TokenImage'
import type { TokenSymbol } from '../products'

import {
  query_fund_company_value,
  query_fund_daily_nav,
  query_list_funds,
} from './funds_graphql'
import { query_get_latest_fund_data } from './funds_price_graphql'

export const keys = createQueryKeyStore({
  funds: {
    all: null,
    details: ({ id }: { id: RubyID }) => ({
      queryKey: [{ id }],
      contextQueries: {
        price: null,
        company_value: null,
        daily_nav: null,
      },
    }),
  },
})

export type FundFindBy = {
  fund_id?: RubyID
  symbol?: string
}

const FundOrder = ['technology', 'fintech', 'consumer', 'blockchain']

export function useListFunds() {
  const results = useQuery({
    queryKey: keys.funds.all.queryKey,
    queryFn: async () => {
      const data = await graphql_call({ query: query_list_funds })
      if (data == null) return data

      const funds = data
        .map((f) => {
          if (f.type == 'fission_vault') return null

          return {
            ...f,
            symbol: f.symbol as TokenSymbol,
            image_slug: f.image_slug as TokenImageSlug,
          }
        })
        .filter((f) => f != null)

      return _.sortBy(funds, ({ name }) => {
        const index = FundOrder.indexOf(_.kebabCase(name))
        return index < 0 ? name : index.toString()
      })
    },
  })

  const data = results.data ?? []

  const fund_find_by = ({ fund_id, symbol }: FundFindBy) =>
    data.find((f) => f.id == fund_id || f.symbol === symbol?.toUpperCase())

  return {
    ...results,
    data,
    fund_find_by,
  }
}

export function useFundInfo(args: FundFindBy) {
  const { fund_find_by, ...others } = useListFunds()

  return { ...others, data: fund_find_by(args) }
}

export function useFundDailyNav({ id }: { id: RubyID | undefined }) {
  const results = useQuery({
    enabled: id != null,
    queryKey: keys.funds.details({ id: id! })._ctx.daily_nav.queryKey,
    queryFn: async () => {
      const result = await graphql_call({
        query: query_fund_daily_nav,
        variables: { id: id! },
      })
      if (result == null) return []
      const dailies = result.daily_nav.map((n) => ({
        ...n,
        date: dayjs(n.date).valueOf(),
      }))
      return _.sortBy(dailies, 'date')
    },
  })
  return {
    ...results,
    data: results.data ?? [],
  }
}

export function useFundLatestPriceData({ id }: { id: RubyID | undefined }) {
  return useQuery({
    queryKey: keys.funds.details({ id: id! })._ctx.price.queryKey,
    queryFn:
      id == null
        ? skipToken
        : async () => {
            const result = await graphql_price_call({
              query: query_get_latest_fund_data,
              variables: { id },
            })
            return result?.[0] ?? null
          },
  })
}

const empty_result = { companies: [], cash_balance: 0, funds_held_value: 0 }
export function useFundCompaniesValue({ id }: { id: RubyID | undefined }) {
  const results = useQuery({
    enabled: id != null,
    queryKey: keys.funds.details({ id: id! })._ctx.company_value.queryKey,
    queryFn: async () => {
      const result = await graphql_call({
        query: query_fund_company_value,
        variables: { id: id! },
      })
      if (result == null) return empty_result
      const companies = result.companies ?? []
      const { cash_balance } = result

      const funds_held_value = companies.reduce(
        (sum, c) => sum + c.value_held,
        0,
      )

      return { companies, cash_balance, funds_held_value }
    },
  })
  return {
    ...results,
    data: results.data ?? empty_result,
  }
}
