// https://react-svgr.com/playground/?jsxRuntime=automatic&memo=true&typescript=true
import { memo, type SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 1217 1555"
    preserveAspectRatio="none"
    fill="#275DF0"
    opacity={0.5}
    {...props}
  >
    <path
      d="M297.327 1531.45C724.325 1429.26 1147.37 527.317 1207.5 424.758 1309.64 207.793 531.886-218.214 266 135.447-66.356 577.523-95.416 162.209-322.5 765.5c-139.126 369.61 0 360.5 0 533 69.5 69 192.829 335.14 619.827 232.95Z"
      style={{
        mixBlendMode: 'screen',
      }}
    />
  </svg>
)
const Memo = memo(SvgComponent)
export default Memo
