import { graphql } from '@repo/common/queries/helpers/pricing_query'

export const query_get_latest_price = graphql(`
  query query_get_latest_price {
    vaultPriceDatas(first: 1, orderDirection: desc, orderBy: block_number) {
      block_timestamp_s
      tvl_usdc
      price_per_token
      total_supply
      position_count
      usdc_amount
      token_amount
    }
  }
`)

export const query_get_oldest_price = graphql(`
  query query_get_oldest_price {
    vaultPriceDatas(first: 1, orderDirection: asc, orderBy: block_number) {
      block_timestamp_s
      price_per_token
    }
  }
`)

export const query_get_daily_price = graphql(`
  query query_get_daily_price($first_day_s: BigInt!) {
    vaultPriceDayDatas(
      first: 365
      orderBy: date_s
      orderDirection: desc
      where: { date_s_gte: $first_day_s }
    ) {
      date_s
      tvl_usdc
      total_supply
      price_per_token
      position_usdc_value
      usdc_value
      token_usdc_value
    }
  }
`)
