import { type LegacyRef, memo, type ReactNode, useEffect } from 'react'

import {
  ActionIcon,
  Box,
  Button,
  type FlexProps,
  Group,
  NumberInput,
  Select,
  type SelectProps,
  Stack,
  Text,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import {
  useDebouncedValue,
  useDisclosure,
  useFocusWithin,
} from '@mantine/hooks'
import { z } from 'zod'

import { IconArrowDown, IconCaretDown } from '@repo/common/components/Icons'
import {
  type TokenImageSlug,
  TokenRender,
} from '@repo/common/components/TokenRender'
import { countFormatter } from '@repo/common/helpers/formatters'
import { useGetWalletBalance } from '@repo/common/queries/blockchain/useGetWalletBalance'
import {
  type ExchangeSideType,
  type InSideType,
  useGetTokenSwapPrice,
  useSwap,
} from '@repo/common/queries/fission_dex'
import type { TokenSymbol } from '@repo/common/queries/products'

import { usdFormatter } from '../../helpers/formatters'

import classes from './Swap.module.css'

export type SwapableTokens = 'TECH'
const valid_tokens: SwapableTokens[] = ['TECH'] as const
const swap_tokens = ['USDC', 'TECH'] as const

export function is_valid_symbol(symbol: string): SwapableTokens | null {
  if (valid_tokens.includes(symbol.toUpperCase() as SwapableTokens)) {
    return symbol.toUpperCase() as SwapableTokens
  }
  return null
}

type SwapableSymbolsType = 'USDC' | SwapableTokens
const symbol_to_image_slug: Record<SwapableSymbolsType, TokenImageSlug> = {
  USDC: 'usdc',
  TECH: 'technology',
}

const default_reason = 'Enter an amount'

export type ContentBoxType = (
  props: FlexProps & {
    ref?: LegacyRef<HTMLDivElement>
  },
) => ReactNode

export const Swap = memo<{
  token_symbol: SwapableTokens
  in_side: InSideType
  ContentBox: ContentBoxType
  onCompleted: () => void
  // eslint-disable-next-line max-lines-per-function, complexity
}>(function Swap({ token_symbol, in_side, onCompleted, ContentBox }) {
  const form = useForm({
    mode: 'controlled',
    initialValues: {
      in_side,
      exchange_side: 'in',
      amount_in: undefined,
      amount_out: undefined,
      symbol_in: in_side === 'USDC' ? 'USDC' : token_symbol,
      symbol_out: in_side !== 'USDC' ? 'USDC' : token_symbol,
    } as {
      in_side: InSideType
      exchange_side: ExchangeSideType
      amount_in: number | undefined
      amount_out: number | undefined
      symbol_in: TokenSymbol
      symbol_out: TokenSymbol
    },
    validateInputOnChange: true,
    validate: (values) => {
      return zodResolver(get_schema(values))(values)
    },
  })
  const get_schema = (values: {
    amount_in: number | undefined
    amount_out: number | undefined
    symbol_in: TokenSymbol
    symbol_out: TokenSymbol
  }) => {
    return z.object({
      amount_in: z
        .number({ message: default_reason })
        .positive()
        .max(balance_in, {
          message: `Insufficient ${values.symbol_in} balance`,
        }),
      amount_out: z.number({ message: default_reason }).positive(),
    })
  }

  const [show_details, show_details_actions] = useDisclosure(false)
  const [debounced_form_values] = useDebouncedValue(form.getValues(), 500)
  const { data: swap_data } = useGetTokenSwapPrice(debounced_form_values)

  const has_liquidity = swap_data?.has_liquidity ?? false

  const { balance: balance_in } = useGetWalletBalance(
    form.getValues().symbol_in,
  )
  const { balance: balance_out } = useGetWalletBalance(
    form.getValues().symbol_out,
  )

  const swap_query = useSwap({
    symbol_in: form.getValues().symbol_in,
    symbol_out: form.getValues().symbol_out,
  })

  const onSubmit = (
    values: Parameters<Parameters<typeof form.onSubmit>[0]>[0],
  ) => {
    swap_query
      .mutateAsync({ amount: values.amount_in! })
      .then(onCompleted)
      .catch(console.error)
  }

  const amount_in_focus = useFocusWithin()
  const amount_out_focus = useFocusWithin()

  form.watch('symbol_in', ({ previousValue, value }) => {
    const { amount_in, amount_out } = form.getValues()

    form.setValues({
      in_side: value === 'USDC' ? 'USDC' : 'TOKEN',
      amount_in: amount_out,
      amount_out: amount_in,
      symbol_out: previousValue,
    })
  })

  form.watch('symbol_out', ({ previousValue }) => {
    form.setFieldValue('symbol_in', previousValue)
  })

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (swap_data?.usdc_price == null || swap_data?.usdc_price <= 0) return

    if (form.getValues().exchange_side === 'in') {
      const value = form.getValues().amount_in
      if (value == null) return

      form.setValues({
        amount_out:
          form.getValues().symbol_in == 'USDC'
            ? value / swap_data.usdc_price
            : value * swap_data.usdc_price,
      })
    } else {
      const value = form.getValues().amount_out
      if (value == null) return

      form.setValues({
        amount_in:
          form.getValues().symbol_in == 'USDC'
            ? value * swap_data.usdc_price
            : value / swap_data.usdc_price,
      })
    }
    // ignore form as that doesn't matter for this trigger
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swap_data?.usdc_price])

  form.watch('amount_in', ({ value }) => {
    if (!amount_in_focus.focused) return
    if (swap_data?.usdc_price == null || swap_data?.usdc_price <= 0) {
      form.setValues({ exchange_side: 'in' })
      return
    }
    if (value == null) return

    if (form.getValues().symbol_in == 'USDC') {
      form.setValues({
        amount_out: value / swap_data.usdc_price,
        exchange_side: 'in',
      })
    } else {
      form.setValues({
        amount_out: value * swap_data.usdc_price,
        exchange_side: 'in',
      })
    }
  })

  form.watch('amount_out', ({ value }) => {
    if (!amount_out_focus.focused) return
    if (swap_data?.usdc_price == null || swap_data?.usdc_price <= 0) {
      form.setValues({ exchange_side: 'out' })
      return
    }
    if (value == null) return

    if (form.getValues().symbol_in == 'USDC') {
      form.setValues({
        amount_in: value * swap_data.usdc_price,
        exchange_side: 'out',
      })
    } else {
      form.setValues({
        amount_in: value / swap_data.usdc_price,
        exchange_side: 'out',
      })
    }
  })

  const enabled = form.isValid() && has_liquidity

  const reason = has_liquidity
    ? get_schema(form.getValues()).safeParse(form.getValues()).error?.errors[0]
        ?.message || default_reason
    : 'Insufficient liquidity'

  return (
    <Stack maw="100%">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack maw="100%" w="400px" gap="xxs">
          <Box pos="relative">
            <ActionIcon
              className={classes.switch_button}
              variant="filled"
              onClick={() =>
                form.setFieldValue('symbol_in', form.getValues().symbol_out)
              }
            >
              <IconArrowDown
                style={{ width: '70%', height: '70%' }}
                stroke={1.5}
              />
            </ActionIcon>
            <ContentBox
              p="xs"
              gap="xs"
              ref={amount_in_focus.ref}
              mod={{ focused: amount_in_focus.focused }}
            >
              <Text size="md" opacity={0.7}>
                Sell
              </Text>
              <Group justify="space-between">
                <NumberInput
                  classNames={{ input: classes.input }}
                  {...form.getInputProps('amount_in', { withError: false })}
                  disabled={!has_liquidity}
                  arial-label="sell amount"
                  variant="unstyled"
                  allowNegative={false}
                  decimalScale={form.getValues().symbol_in == 'USDC' ? 2 : 4}
                  hideControls
                  placeholder="0"
                  flex="1 1"
                />
                <Select
                  {...form.getInputProps('symbol_in')}
                  arial-label="sell token"
                  w="110px"
                  data={swap_tokens}
                  classNames={{
                    input: classes.select,
                    dropdown: classes.select_dropdown,
                  }}
                  allowDeselect={false}
                  renderOption={renderSelectOption}
                  rightSection={<IconArrowDown size="16px" />}
                  leftSection={
                    <TokenRender
                      type="token"
                      size="var(--mantine-spacing-lg)"
                      image_slug={
                        symbol_to_image_slug[
                          form.getValues().symbol_in as SwapableSymbolsType
                        ]
                      }
                    />
                  }
                />
              </Group>
              <Group justify="flex-end" align="baseline" gap="0">
                <Text size="sm" opacity={0.7}>
                  Balance: {balance_in}
                </Text>
                <Button
                  size="compact-sm"
                  variant="subtle"
                  onClick={() => form.setFieldValue('amount_in', balance_in)}
                >
                  MAX
                </Button>
              </Group>
            </ContentBox>
          </Box>
          <ContentBox
            p="xs"
            gap="xs"
            ref={amount_out_focus.ref}
            mod={{ focused: amount_out_focus.focused }}
          >
            <Text size="md" opacity={0.7}>
              Buy
            </Text>
            <Group justify="space-between">
              <NumberInput
                classNames={{ input: classes.input }}
                {...form.getInputProps('amount_out', { withError: false })}
                disabled={!has_liquidity}
                arial-label="buy amount"
                variant="unstyled"
                allowNegative={false}
                decimalScale={form.getValues().symbol_out == 'USDC' ? 2 : 4}
                hideControls
                placeholder="0"
                flex="1 1"
              />
              <Select
                {...form.getInputProps('symbol_out')}
                arial-label="buy token"
                w="110px"
                data={swap_tokens}
                classNames={{
                  input: classes.select,
                  dropdown: classes.select_dropdown,
                }}
                allowDeselect={false}
                renderOption={renderSelectOption}
                rightSection={<IconArrowDown size="16px" />}
                leftSection={
                  <TokenRender
                    type="token"
                    size="var(--mantine-spacing-lg)"
                    image_slug={
                      symbol_to_image_slug[
                        form.getValues().symbol_out as SwapableSymbolsType
                      ]
                    }
                  />
                }
              />
            </Group>
            <Group justify="flex-end">
              <Text size="sm" opacity={0.7}>
                Balance: {balance_out}
              </Text>
            </Group>
          </ContentBox>
          <Button
            disabled={!enabled}
            loading={swap_query.isPending}
            variant={enabled ? 'gradient' : 'outline'}
            size="lg"
            type="submit"
          >
            {enabled ? 'Swap' : reason}
          </Button>
          <Group pl="sm" justify="space-between">
            {has_liquidity && (
              <Text size="md" opacity={0.7}>
                1 {token_symbol} ={' '}
                {countFormatter(swap_data?.usdc_price ?? 0, {
                  compact: false,
                  maximumFractionDigits: 6,
                })}{' '}
                USDC
              </Text>
            )}
            <ActionIcon
              variant="subtle"
              onClick={show_details_actions.toggle}
              className={classes.details_button}
              mod={{ opened: show_details }}
              aria-label="toggle swap disclosures"
            >
              <IconCaretDown stroke={1.5} />
            </ActionIcon>
          </Group>
          {show_details && (
            <Stack gap="xs">
              <Group justify="space-between">
                <Text size="sm" opacity={0.7}>
                  Max. slippage
                </Text>
                <Text size="sm">5%</Text>
              </Group>
              <Group justify="space-between">
                <Text size="sm" opacity={0.7}>
                  System Fee (0.25%)
                </Text>
                <Text size="sm">
                  {usdFormatter(swap_data?.system_fee_amount)}
                </Text>
              </Group>
            </Stack>
          )}
        </Stack>
      </form>
    </Stack>
  )
})

const renderSelectOption: SelectProps['renderOption'] = ({ option }) => (
  <Group flex="1" gap="xxs">
    <TokenRender
      type="token"
      size="var(--mantine-spacing-lg)"
      image_slug={symbol_to_image_slug[option.value as SwapableSymbolsType]}
    />
    {option.label}
  </Group>
)
