import { memo } from 'react'

import { Group, Paper, Table, Text } from '@mantine/core'
import isChromatic from 'chromatic'
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  tick_wrapper,
  usdFormatter,
  usdLargeNumberFormatter,
  value_formatter,
} from '@repo/common/helpers/formatters'
import { useGetLiquidity } from '@repo/common/queries/fission_dex'

import type { TokenSymbol } from '../../queries/products'
import { TokenRender } from '../TokenRender'

export const y_tick_formatting = {
  fontSize: 'var(--graph-font-size)',
  fill: 'var(--mantine-color-dark-0)',
  opacity: 0.6,
}

export const x_tick_formatting = {
  fontSize: 'var(--graph-font-size)',
  fill: 'var(--mantine-color-dark-0)',
  opacity: 0.8,
}

export const Liquidity_Colors = {
  usdc: 'var(--mantine-color-green-5)',
  token: 'var(--mantine-color-blue-5)',
}

export const LiquidityChart = memo(function LiquidityChart({
  height = 400,
  graph_font_size = 'var(--mantine-font-size-sm)',
}: {
  height?: ReactProps<typeof ResponsiveContainer>['height']
  graph_font_size?: `var(--mantine-font-size-${string})`
}) {
  const symbol = 'TECH'
  const { data } = useGetLiquidity({ symbol })

  return (
    <ResponsiveContainer
      width="100%"
      height={height}
      style={
        {
          '--graph-font-size': graph_font_size,
        } as React.CSSProperties
      }
    >
      <BarChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        barGap={0}
      >
        <Tooltip
          wrapperStyle={{ zIndex: 10 }}
          content={({ label, payload }) => (
            <ChartTooltip
              label={label as string}
              symbol={symbol}
              payload={payload as ToolTipType['payload']}
            />
          )}
        />
        <XAxis
          tick={x_tick_formatting}
          type="number"
          dataKey="token_price"
          domain={['auto', 'auto']}
          tickFormatter={tick_wrapper((value) => usdFormatter(value, false))}
        />
        <YAxis
          tick={y_tick_formatting}
          type="number"
          domain={[0, 'dataMax']}
          tickFormatter={tick_wrapper(usdLargeNumberFormatter)}
        />
        <Bar
          stackId="a"
          dataKey="usdc_tokens"
          isAnimationActive={!isChromatic()}
          fill={Liquidity_Colors.usdc}
        />
        <Bar
          stackId="a"
          dataKey="token_in_usdc"
          isAnimationActive={!isChromatic()}
          fill={Liquidity_Colors.token}
        />
      </BarChart>
    </ResponsiveContainer>
  )
})

export type ToolTipType = {
  label: string | number
  payload?: {
    name: string
    color: string
    payload: {
      usdc_tokens: number
      token_tokens: number
      token_in_usdc: number
    }
  }[]
  symbol: TokenSymbol
}

// eslint-disable-next-line max-lines-per-function
export function ChartTooltip({ label, payload, symbol }: ToolTipType) {
  if (payload == null || payload.length == 0) return null

  const items = [
    {
      name: 'USDC',
      image_slug: 'usdc',
      color: Liquidity_Colors.usdc,
      tokens: payload[0].payload.usdc_tokens,
      value: payload[0].payload.usdc_tokens,
    },
    {
      name: symbol,
      image_slug: 'technology',
      color: Liquidity_Colors.token,
      tokens: payload[0].payload.token_tokens,
      value: payload[0].payload.token_in_usdc,
    },
  ] as const

  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
      <Text size="lg" mb="sm" ml="xs">
        {symbol} price: {usdFormatter(label)}
      </Text>
      <Table w="fit-content">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>
              <Text size="sm" opacity={0.8}>
                Token
              </Text>
            </Table.Th>
            <Table.Th>
              <Text size="sm" opacity={0.8}>
                Liquidity
              </Text>
            </Table.Th>
            <Table.Th>
              <Text size="sm" opacity={0.8}>
                Amount
              </Text>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {items.map((item) => (
            <Table.Tr key={item.name}>
              <Table.Td>
                <Group gap="1ex">
                  <TokenRender
                    type="token"
                    size="var(--mantine-spacing-lg)"
                    image_slug={item.image_slug}
                  />
                  <Text c={item.color}>{item.name}</Text>
                </Group>
              </Table.Td>
              <Table.Td>
                <Text ta="right" c={item.color}>
                  {usdFormatter(item.value)}
                </Text>
              </Table.Td>
              <Table.Td>
                <Text ta="right" c={item.color}>
                  {value_formatter(item.tokens)}
                </Text>
              </Table.Td>
            </Table.Tr>
          ))}
          <Table.Tr>
            <Table.Td>
              <Text>Total</Text>
            </Table.Td>
            <Table.Td>
              <Text ta="right">
                {usdFormatter(
                  payload[0].payload.usdc_tokens +
                    payload[0].payload.token_in_usdc,
                )}
              </Text>
            </Table.Td>
            <Table.Td />
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Paper>
  )
}
