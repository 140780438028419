import type { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { formatUnits } from 'viem'

export function calc_shares(
  shares: bigint | undefined,
  decimals: number,
): number {
  if (shares == null) return 0

  return Number(formatUnits(shares, decimals))
}

export function findCurrencyAmount({
  symbol,
  pair,
  not = false,
}: {
  symbol: string
  pair: [CurrencyAmount<Token>, CurrencyAmount<Token>]
  not?: boolean
}): CurrencyAmount<Token> {
  if (not) {
    return pair[0].currency.symbol === symbol ? pair[1] : pair[0]
  }
  return pair[0].currency.symbol === symbol ? pair[0] : pair[1]
}
