import { memo } from 'react'

import { usdFormatter } from '@repo/common/helpers/formatters'

import { useGetWalletBalance } from '../../queries/blockchain/useGetWalletBalance'
import { useProductInfo } from '../../queries/products'
import { useGetVaultLatestData } from '../../queries/vaults'

// eslint-disable-next-line complexity
export const VaultAmount = memo<{ id?: RubyID }>(function VaultAmount({ id }) {
  const price_result = useGetVaultLatestData({ id })
  const product_result = useProductInfo({ id })

  const wallet_result = useGetWalletBalance(product_result.data?.symbol)

  if (
    price_result.isLoading ||
    product_result.isLoading ||
    wallet_result.isLoading
  )
    return null

  if (price_result.data == null) return null
  const price = price_result.data.price_per_token

  const amount = price * wallet_result.balance

  return <>{usdFormatter(amount)}</>
})
