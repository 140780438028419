import { memo, useEffect } from 'react'

import { Group } from '@mantine/core'
import { useNavigate } from '@tanstack/react-router'

import { LoginSignup } from '@repo/common/components/LoginSignup'
import { useAuth } from '@repo/common/queries/auth'

export const LoginSignupPage = memo<{
  starting_mode: ReactProps<typeof LoginSignup>['starting_mode']
}>(function LoginSignupPage({ starting_mode }) {
  const { data } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (data?.is_authenticated) {
      void navigate({ to: '/portfolio', replace: true })
    }
  }, [data?.is_authenticated, navigate])

  return (
    <Group
      gap="xl"
      wrap="nowrap"
      pl={{ base: 'sm', sm: '0' }}
      pr={{ base: 'sm', sm: 'xl' }}
      align="top"
      maw="400"
      mx="auto"
    >
      <LoginSignup starting_mode={starting_mode} />
    </Group>
  )
})
