import { memo, useState } from 'react'

import { Box, Group, SegmentedControl, Select, Text } from '@mantine/core'

import { ContentBox } from '../../../../apps/labs/src/components/ContentBox'
import type { HistoryPeriod } from '../../queries/fission_dex'
import type { TokenSymbol } from '../../queries/products'
import { DexPriceHistoryChart } from '../DexPriceHistoryChart'
import { LiquidityChart } from '../LiquidityChart'
import { TokenRender } from '../TokenRender'

const chart_types = ['liquidity', 'price'] as const
type ChartType = (typeof chart_types)[number]

export const DexCharts = memo<{
  size: 'sm' | 'lg'
  symbol: TokenSymbol
  starting_type: ChartType
}>(function DexCharts({ size, starting_type }) {
  const [current_chart_type, set_chart_type] =
    useState<ChartType>(starting_type)
  const [period_type, set_period_type] = useState<HistoryPeriod>('daily')

  const height = size === 'sm' ? 100 : 400
  return (
    <ContentBox
      variant="diagram"
      p="md"
      w="100%"
      justify="space-between"
      gap="0"
    >
      {current_chart_type === 'liquidity' ? (
        <LiquidityChart height={height} />
      ) : (
        <DexPriceHistoryChart height={height} period_type={period_type} />
      )}
      <Group justify="space-between" mih="45px">
        {size == 'lg' ? (
          <Group gap="0.25em">
            <TokenRender
              type="lp"
              size="var(--mantine-spacing-lg)"
              image_slug={['usdc', 'technology']}
            />
            <Text>TECH / USDC</Text>
            <Text size="sm" opacity={0.7}>
              1.0%
            </Text>
          </Group>
        ) : (
          <Box />
        )}
        <Group gap="md">
          {current_chart_type === 'price' && (
            <SegmentedControl
              size="sm"
              data={[
                { label: '1D', value: 'hourly' },
                { label: '1Y', value: 'daily' },
              ]}
              value={period_type}
              onChange={(value) => set_period_type(value as HistoryPeriod)}
            />
          )}
          <Select
            size="sm"
            w="100px"
            variant="default"
            aria-label="Chart Type"
            value={current_chart_type}
            data={chart_types}
            allowDeselect={false}
            onChange={(value) => set_chart_type(value as ChartType)}
          />
        </Group>
      </Group>
    </ContentBox>
  )
})
