import { memo } from 'react'

import { usdFormatter } from '@repo/common/helpers/formatters'

import { useGetWalletBalance } from '../../queries/blockchain/useGetWalletBalance'
import { useGetTokenPrice } from '../../queries/fission_dex'
import { useFundInfo } from '../../queries/funds'

// eslint-disable-next-line complexity
export const FundAmount = memo<{ id?: RubyID }>(function FundAmount({ id }) {
  const fund_result = useFundInfo({ fund_id: id })
  const use_nav_price = fund_result.data?.type == null

  const price_result = useGetTokenPrice({
    symbol: use_nav_price ? undefined : fund_result.data?.symbol,
  })

  const wallet = useGetWalletBalance(fund_result.data?.symbol)

  if (fund_result.isLoading || price_result.isLoading || wallet.isLoading)
    return null

  let price = 0
  if (use_nav_price) {
    if (fund_result.data?.price_per_share == null) return null
    price = fund_result.data.price_per_share
  } else {
    if (price_result.data == null) return null
    price = price_result.data
  }

  const amount = price * wallet.balance

  return <>{usdFormatter(amount)}</>
})
