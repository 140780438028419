import ReactDOM from 'react-dom/client'

import { App } from '@/app/App'
import { AppContexts } from '@/app/AppContexts'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AppContexts>
    <App />
  </AppContexts>,
)
