import { forwardRef, type LegacyRef, memo, type ReactNode } from 'react'

import { Flex, type FlexProps, Text } from '@mantine/core'
import clsx from 'clsx'

import classes from './ContentBox.module.css'

type PropsType = FlexProps & {
  variant?: 'bright' | 'subtle' | 'fading' | 'diagram'
}

export const ContentBox = memo<
  PropsType & {
    ref?: LegacyRef<HTMLDivElement>
  }
>(
  forwardRef<HTMLDivElement, PropsType>(function ContentBox(
    { className = '', variant = 'subtle', ...props },
    ref,
  ) {
    return (
      <Flex
        ref={ref}
        data-variant={variant}
        className={clsx(classes.root, className)}
        direction="column"
        p="md"
        {...props}
      />
    )
  }),
)

export const ContentWithTitle = memo<
  ReactProps<typeof ContentBox> & { title: ReactNode; children: ReactNode }
>(function ContentWithTitle({ title, children, ...props }) {
  return (
    <ContentBox w="100%" p="xl" mih="100%" gap="md" {...props}>
      <Text size="lg">{title}</Text>
      {children}
    </ContentBox>
  )
})
