import { memo } from 'react'

import { Group, Text } from '@mantine/core'

import { countFormatter } from '@repo/common/helpers/formatters'
import { useGetWalletBalance } from '@repo/common/queries/blockchain/useGetWalletBalance'

import type { TokenSymbol } from '../../queries/products'
import { ConnectWalletButton } from '../ConnectWalletButton'
import { type TokenImageSlug, TokenRender } from '../TokenRender'

export const WalletBalance = memo<
  { token?: TokenSymbol; image_slug?: TokenImageSlug } & (
    | ({
        unconnected: 'show'
      } & ReactProps<typeof ConnectWalletButton>)
    | {
        unconnected?: 'hide'
      }
  )
>(function WalletBalance({
  unconnected = 'hide',
  token = 'USDC',
  image_slug = 'usdc',
  ...props
}) {
  const { balance, isConnected } = useGetWalletBalance(token)

  if (unconnected === 'show' && !isConnected) {
    return <ConnectWalletButton {...props} />
  }

  return (
    <Group gap="0.5ex" fz="md">
      <TokenRender
        size="var(--mantine-spacing-lg)"
        type="token"
        image_slug={image_slug}
      />
      <Text>
        {countFormatter(balance, {
          maximumFractionDigits: token == token ? 2 : 4,
          compact: false,
        })}
      </Text>
    </Group>
  )
})

export const OnlyWalletBalance = memo<
  { token?: TokenSymbol } & (
    | ({
        unconnected: 'show'
      } & ReactProps<typeof ConnectWalletButton>)
    | {
        unconnected?: 'hide'
      }
  )
>(function OnlyWalletBalance({
  unconnected = 'hide',
  token = 'USDC',
  ...props
}) {
  const { balance, isConnected } = useGetWalletBalance(token)

  if (unconnected === 'show' && !isConnected) {
    return <ConnectWalletButton {...props} />
  }

  return (
    <>
      {countFormatter(balance, {
        maximumFractionDigits: token == 'USDC' ? 2 : 4,
        compact: false,
      })}
    </>
  )
})
