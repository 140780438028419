import { memo } from 'react'

import { Anchor } from '@mantine/core'
import {
  Link as RouteLink,
  type LinkProps as RouteLinkProps,
} from '@tanstack/react-router'

export type LinkProps = RouteLinkProps & {
  to?: Omit<NonNullable<RouteLinkProps['to']>, './' | '../'>
  href?: string
  label?: string
  children?: React.ReactNode
  className?: string
}

export type OnlyToLinkProps = Omit<LinkProps, 'href' | 'to'> & {
  to: LinkProps['to']
}

export const Link = memo<LinkProps>(function Link({
  to,
  href,
  label,
  children,
  className,
  ...props
}) {
  const body = children || <>{label}</>
  if (href) {
    return (
      <Anchor {...props} classNames={{ root: className }} href={href}>
        {body}
      </Anchor>
    )
  }

  return (
    <RouteLink to={to} className={className} {...props}>
      {body}
    </RouteLink>
  )
})
