import { memo } from 'react'

import { Image, type ImageProps } from '@mantine/core'
import * as _ from 'lodash-es'

const fission_types = ['fisn', 'vault'] as const
const other_types = ['usdc', 'm-0'] as const

export const fund_types = [
  'ai-data',
  'blockchain',
  'consumer',
  'fintech',
  'industrial',
  'software',
  'technology',
] as const

export const all_types = [
  ...fund_types,
  ...other_types,
  ...fission_types,
] as const
export type TokenImageSlug = (typeof all_types)[number]

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type TokenImageSlugOrString = TokenImageSlug | string

export function get_image(image_slug: TokenImageSlugOrString) {
  const name = _.kebabCase(image_slug)

  if (all_types.includes(name as TokenImageSlug)) return `/token/${name}.png`

  return ''
}

export const TokenImage = memo<
  { image_slug: TokenImageSlugOrString; size?: string } & ImageProps
>(function TokenImage({ image_slug, size, ...props }) {
  return (
    <Image
      alt={`${image_slug} token image`}
      {...props}
      src={get_image(image_slug)}
      h={size}
      w={size}
    />
  )
})
