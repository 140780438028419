import { memo, useMemo } from 'react'

import dayjs from 'dayjs'
import * as _ from 'lodash-es'

import {
  find_nav_of_date,
  to_apy,
} from '@repo/common/helpers/daily_nav_helpers'

import { useFundDailyNav } from '../../queries/funds'

// eslint-disable-next-line complexity
export const FundNav1yAPY = memo<{ id?: RubyID }>(function FundNav1yAPY({
  id,
}) {
  const { data, isLoading } = useFundDailyNav({ id })

  const one_year_apy = useMemo(() => {
    const dailies = data.map((nav) => ({
      date: nav.date,
      price_per_share: nav.nav_price_per_share,
    }))

    const today_nav = _.last(dailies)
    const today_day = dayjs(today_nav?.date)

    const one_year_nav = find_nav_of_date(
      dailies,
      today_day.subtract(1, 'year'),
    )

    return to_apy(one_year_nav, today_nav)
  }, [data])

  if (isLoading) return null

  return one_year_apy
})
