import { memo, type ReactNode, useEffect, useRef } from 'react'

import { Box, ScrollArea, Stack, Title } from '@mantine/core'
import {
  type LinkProps as RouteLinkProps,
  useMatches,
} from '@tanstack/react-router'
import * as _ from 'lodash-es'

import { ContentBox } from '@/components/ContentBox'

import { Link, type LinkProps } from '../Link'

import classes from './NavSubLinks.module.css'

export type NavSubLinksType = {
  label: LinkProps['label']
  Icon?: (props: { size: string }) => ReactNode
  section: string
}[]

export const NavSubLinks = memo<{
  title: string
  links: NavSubLinksType
  base_route_id: string
  children_path: RouteLinkProps['to']
}>(function NavSubLinks({ title, links, base_route_id, children_path }) {
  const viewportRef = useRef<HTMLDivElement>(null)
  const { is_matching, params } = useMatches({
    select: (matches) => {
      const match = _.last(matches)!
      return {
        is_matching: match.routeId.startsWith(base_route_id),
        params: {
          section: null,
          ...match.params,
        },
      }
    },
  })

  useEffect(() => {
    if (!is_matching || params.section == null) return
    viewportRef.current
      ?.querySelector('[data-status="active"]')
      ?.scrollIntoView({ block: 'nearest' })
  }, [is_matching, params.section])

  if (!is_matching) return null

  return (
    <ContentBox className={classes.link_box} flex="1 1 auto">
      <Stack h="100%" gap="0">
        <Title order={2} mb="xs" pl="4">
          {title}
        </Title>
        <Box flex="1 1 auto" pos="relative">
          <Box h="100%" w="100%" pos="absolute">
            <ScrollArea.Autosize
              mah="100%"
              type="auto"
              scrollbars="y"
              offsetScrollbars
              viewportRef={viewportRef}
            >
              <Stack gap="4">
                {links.map(({ label, Icon, section }) => (
                  <Link
                    to={children_path}
                    params={{
                      ...params,
                      section,
                    }}
                    key={label}
                    className={classes.link}
                  >
                    <>
                      {Icon && <Icon size="1rem" />}
                      {label}
                    </>
                  </Link>
                ))}
              </Stack>
            </ScrollArea.Autosize>
          </Box>
        </Box>
      </Stack>
    </ContentBox>
  )
})
