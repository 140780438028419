import { Group } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'

import { LoginLogout } from '@repo/common/components/LoginLogout'

export const Route = createFileRoute('/_appish/logout')({
  component: LogoutPage,
})

function LogoutPage() {
  return (
    <Group
      gap="xl"
      wrap="nowrap"
      pl={{ base: 'sm', sm: '0' }}
      pr={{ base: 'sm', sm: 'xl' }}
      align="top"
      maw="400"
      mx="auto"
    >
      <LoginLogout />
    </Group>
  )
}
