import { memo, useEffect } from 'react'

import { LoginSignup } from '@repo/common/components/LoginSignup'
import { useAuth } from '@repo/common/queries/auth'

export const PanelLogin = memo<{
  onCompleted: () => void
}>(function PanelLogin({ onCompleted }) {
  const { data } = useAuth()

  useEffect(() => {
    if (data?.is_authenticated) {
      onCompleted()
    }
  }, [data?.is_authenticated, onCompleted])

  return <LoginSignup starting_mode="login" />
})

export const useSkipLogin = () => {
  const { data } = useAuth()
  return Boolean(data?.is_authenticated)
}
