import { createFileRoute } from '@tanstack/react-router'

import { ResearchCompanyPage } from '@/components/ResearchCompanyPage'

export const Route = createFileRoute(
  '/_app/research/$symbol/company/$cms_name/$section',
)({
  component: ComponentIdPage,
})

function ComponentIdPage() {
  const { symbol, cms_name, section } = Route.useParams()

  return (
    <ResearchCompanyPage
      symbol={symbol}
      cms_name={cms_name}
      section={section}
    />
  )
}
