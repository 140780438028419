import { erc20Abi } from 'abitype/abis'
import { useReadContract } from 'wagmi'

import { active_chain } from '@repo/common/blockchain/config'

export function useGetDecimals(address?: `0x${string}`) {
  return useReadContract({
    abi: erc20Abi,
    functionName: 'decimals',
    address,
    chainId: active_chain.id,
    query: {
      gcTime: Infinity,
      staleTime: Infinity,
      enabled: Boolean(address),
    },
  })
}
