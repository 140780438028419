import { graphql } from '@repo/common/queries/helpers/pricing_query'

export const query_get_latest_fund_data = graphql(`
  query query_get_latest_fund_data {
    tokenPriceDatas(first: 1, orderDirection: desc, orderBy: block_number) {
      block_timestamp_s
      tvl_usdc
      price_per_token
      total_supply
    }
  }
`)
