import { memo, useMemo } from 'react'

import { Sparkline } from '@mantine/charts'
import { Group, type MantineStyleProps, Text } from '@mantine/core'
import * as _ from 'lodash-es'

import { usdLargeNumberFormatter } from '@repo/common/helpers/formatters'

import { valuationsByQuarter } from '@/helpers/valuationsByQuarter'
import { useCompanyRounds } from '@/queries/company'

export const CompanyValuationSparkline = memo<{
  cms_name: string
  w?: MantineStyleProps['w']
}>(function CompanyValuationSparkline({ cms_name, w }) {
  const rounds_data = useCompanyRounds()

  const rounds = rounds_data.data?.[cms_name]

  const valuations = useMemo(() => valuationsByQuarter(rounds), [rounds])

  if (rounds_data.isLoading) {
    return (
      <Text fz="xs" data-testid="loading">
        Loading...
      </Text>
    )
  }
  if (valuations.length === 0) {
    return null
  }

  return (
    <Group gap="xs" wrap="nowrap">
      <Sparkline
        h={40}
        w={w}
        data={valuations}
        curveType="linear"
        color="teal"
        fillOpacity={0.6}
        strokeWidth={2}
      />
      <Text>{usdLargeNumberFormatter(_.last(valuations))}</Text>
    </Group>
  )
})
