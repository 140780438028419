import { memo } from 'react'

import { Group, Image, type MantineStyleProps, Text } from '@mantine/core'
import { Link } from '@tanstack/react-router'

import wallet_url from './labs.png'
import labs_url from './labs.svg'

import classes from './Logo.module.css'

export { wallet_url }

export const Logo = memo<{
  size: MantineStyleProps['h']
  as_link?: boolean
  with_name?: boolean
}>(function Logo({ size, as_link = false, with_name = false }) {
  const body = (
    <Group align="center" gap="xs">
      <Image src={labs_url} h={size} w={size} alt="Fission™ Logo" />
      {with_name && (
        <Text size="xl" lh="1">
          Fission{' '}
          <Text component="span" lh="1" ml="-4px">
            ™
          </Text>
        </Text>
      )}
    </Group>
  )

  if (as_link) {
    return (
      <Link className={classes.link} to="/" alt="Fission™ Homepage">
        {body}
      </Link>
    )
  }
  return body
})
