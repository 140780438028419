import { memo } from 'react'

import { Group, Stack, Text, Title, Tooltip } from '@mantine/core'
import type dayjs from 'dayjs'

import { full_date_formatter } from '../../helpers/formatters'

export type MetricData = {
  label: string
  rate: string
  since_date: Parameters<typeof dayjs>[0]
  apy?: string
}[]

export const Metrics = memo<{ data: MetricData }>(function Metrics({ data }) {
  return (
    <Group justify="space-around" align="stretch" gap="xxs">
      {data.map(({ label, rate, apy, since_date }) => (
        <Stack gap="2" align="center" key={label}>
          <Tooltip label={`From ${full_date_formatter(since_date)}`}>
            <Title order={2}>{label}</Title>
          </Tooltip>
          <Text c="green">{rate}</Text>
          {apy && (
            <Text c="green" style={{ textWrap: 'nowrap' }}>
              {apy}{' '}
              <Text span size="sm" opacity={0.8} c="white">
                APY
              </Text>
            </Text>
          )}
        </Stack>
      ))}
    </Group>
  )
})
