import * as _ from 'lodash-es'

import { useFundInfo } from '@repo/common/queries/funds'

import { useInvestorRankings, useInvestors } from '../queries/company'

export function useTopFundInvestors({
  count,
  symbol,
}: {
  count: number
  symbol: string
}) {
  const { data: rankings } = useInvestorRankings()
  const { data: investors } = useInvestors()
  const { data: fund } = useFundInfo({ symbol })

  const companies = fund?.companies ?? []
  const all_company_cms_names = companies.map((c) => c.cms_name)

  const all_investors = _.uniq(
    all_company_cms_names.flatMap((cms_name: string) => {
      const list = investors?.[cms_name] ?? []
      return list.map(({ investor }) => investor.toLowerCase())
    }),
  )

  const top_investors = (rankings ?? []).filter((investor) => {
    return all_investors.includes(investor.toLowerCase())
  })

  if (top_investors.length > 0) {
    return top_investors.slice(0, count)
  }

  return all_investors.slice(0, count)
}

export function useTopCompanyInvestors({
  count,
  cms_name,
}: {
  count: number
  cms_name: string
}) {
  const { data: rankings } = useInvestorRankings()
  const { data: investors } = useInvestors()

  const all_investors = (investors?.[cms_name] ?? []).map(
    ({ investor }) => investor,
  )

  const top_investors = (rankings ?? []).filter((investor) => {
    return (
      all_investors.find((i) => i.toLowerCase() === investor.toLowerCase()) !=
      null
    )
  })

  if (top_investors.length > 0) {
    return top_investors.slice(0, count)
  }

  return all_investors.slice(0, count)
}
