import { memo } from 'react'

import { Group, Image, type MantineStyleProps } from '@mantine/core'

import coinbase_url from './coinbase.png'
import fireblocks_url from './fireblocks.png'
import kraynos_url from './kraynos.png'
import m0_url from './m0.png'
import mvp_url from './mvp.png'
import salt_url from './salt.png'
// eslint-disable-next-line @cspell/spellchecker
import securitize_url from './securitize.png'
import sumsub_url from './sumsub.png'
import tally_url from './tally.png'
import trm_url from './trm.png'

const partner_list = [
  'TRM',
  'Fireblocks',
  'Coinbase',
  'M^0',
  'Manhattan Venture Partners',
  'Securitize',
  'Sumsub',
  'Tally',
  'SALT',
  'Kraynos Capital',
] as const
export type PartnerListName = (typeof partner_list)[number]

const full_list: {
  src: string
  alt: PartnerListName
}[] = [
  { src: trm_url, alt: 'TRM' },
  { src: fireblocks_url, alt: 'Fireblocks' },
  { src: m0_url, alt: 'M^0' },
  { src: mvp_url, alt: 'Manhattan Venture Partners' },
  { src: coinbase_url, alt: 'Coinbase' },
  { src: securitize_url, alt: 'Securitize' },
  { src: sumsub_url, alt: 'Sumsub' },
  { src: tally_url, alt: 'Tally' },
  { src: salt_url, alt: 'SALT' },
  { src: kraynos_url, alt: 'Kraynos Capital' },
] as const

export const Partners = memo<{
  omit: PartnerListName[]
  maw: MantineStyleProps['maw']
}>(function Partners({ omit = [], maw }) {
  const partners = full_list.filter((p) => !omit.includes(p.alt))
  return (
    <Group flex="1 1" miw="300px" maw={maw} gap="xl">
      {partners.map((p) => (
        <Image
          alt={p.alt}
          src={p.src}
          title={p.alt}
          key={p.alt}
          h="3rem"
          px="lg"
        />
      ))}
    </Group>
  )
})
