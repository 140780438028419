import { memo, type ReactNode } from 'react'

import { clsx } from 'clsx/lite'

import BackgroundGraphic from './BackgroundGraphic'

import classes from './AppBackground.module.css'

export const AppBackground = memo<{ children: ReactNode; className?: string }>(
  function AppBackground({ children, className }) {
    return (
      <div className={clsx(classes.root, className)}>
        <BackgroundGraphic className={classes.root_layer_1} />
        <BackgroundGraphic className={classes.root_layer_2} />
        {children}
      </div>
    )
  },
)
