/* eslint-disable complexity */
import { memo, useCallback } from 'react'

import { Box, Button, Grid, Group, Stack, Text } from '@mantine/core'
import { useAccount } from 'wagmi'

import { get_blockchain_error_message } from '@repo/common/blockchain/get_blockchain_error_message'
import { ConnectWalletButton } from '@repo/common/components/ConnectWalletButton'
import { IconPlus } from '@repo/common/components/Icons'
import { TokenImage } from '@repo/common/components/TokenImage'
import { TokenRender } from '@repo/common/components/TokenRender'
import {
  countFormatter,
  percentage_formatter,
  usdFormatter,
} from '@repo/common/helpers/formatters'
import { useBuyCartFund } from '@repo/common/queries/cart'
import { useGetTokenPrice } from '@repo/common/queries/fission_dex'
import { useFundInfo } from '@repo/common/queries/funds'
import { useGetGovMintRewards } from '@repo/common/queries/gov'
import { useBuyProduct } from '@repo/common/queries/products'

import classes from '../PanelBuy/PanelBuy.module.css'

export const PanelPayment = memo<{
  fund_id: RubyID
  onCompleted: () => void
  onCancel: () => void
}>(
  // eslint-disable-next-line max-lines-per-function
  function PanelPayment({ onCompleted, onCancel, fund_id }) {
    const account = useAccount()
    const product = useBuyProduct({ id: fund_id })
    const cart = useBuyCartFund()
    const { data: fund } = useFundInfo({ fund_id })
    const { data: fund_price } = useGetTokenPrice({ symbol: fund?.symbol })

    const quantity = cart.values?.quantity ?? 0
    const total_usdc = quantity * (fund_price ?? 0)
    const { data: fisn_rewards } = useGetGovMintRewards({
      fund_id,
      mint_value: total_usdc,
    })

    const is_ready: boolean = product.isReady && fund_price != null

    const onSubmit = useCallback(() => {
      if (!product.isReady) return
      product
        .mutateAsync({
          token: 'USDC',
          amount: total_usdc,
        })
        .then(onCompleted)
        .catch(console.error)
      // product is not stable for memo
    }, [onCompleted, product, total_usdc])

    if (fund == null) return null

    let error_message: string = ''
    if (product.isReady && product.isError) {
      error_message = get_blockchain_error_message(product.error)
    }

    return (
      <Grid columns={2} gutter="0" className={classes.root} mt="50">
        {error_message && (
          <Grid.Col span={2} className={classes.row}>
            <Text c="red" pb="lg" role="status">
              Blockchain or Wallet error: {error_message}
            </Text>
          </Grid.Col>
        )}
        <Grid.Col span={1} className={classes.row}>
          <Group>
            <TokenImage image_slug={fund.name} size="50" />
            <Stack gap="0">
              <Text size="lg" fw="bold" lh="1">
                {fund.name}
              </Text>
              <Text size="sm">{fund.symbol}</Text>
            </Stack>
          </Group>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row_value}>
          <Text fw="bold">
            {fund_price == null ? null : usdFormatter(fund_price)}/share
          </Text>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row}>
          <Text size="sm">Order Type</Text>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row_value}>
          <Text>Market</Text>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row}>
          <Text size="md" component="label" htmlFor="quantity">
            Tokens to Mint
          </Text>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row_value}>
          <Group gap="0.5ex" fz="md">
            <TokenRender
              size="var(--mantine-spacing-lg)"
              type="token"
              image_slug={fund.image_slug}
            />
            <Text size="lg" fw="bold">
              {countFormatter(quantity, {
                maximumFractionDigits: 2,
                compact: false,
              })}
            </Text>
          </Group>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row}>
          <Group gap="0.5ex">
            <Text size="md" lh="1">
              Mint Rewards
            </Text>
            <Group c="green.2" gap="0">
              <IconPlus size="1em" />
              <Text size="md" lh="1">
                {percentage_formatter(fisn_rewards.percentage)}
              </Text>
            </Group>
          </Group>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row_value}>
          <Group gap="0.5ex" fz="md">
            <TokenRender
              size="var(--mantine-spacing-lg)"
              type="token"
              image_slug="fisn"
            />
            <Text fw="bold">
              {countFormatter(fisn_rewards.tokens, {
                maximumFractionDigits: 2,
                compact: false,
              })}
            </Text>
          </Group>
        </Grid.Col>
        <Grid.Col span={2}>
          <Box className={classes.line} />
        </Grid.Col>
        <Grid.Col span={1} className={classes.row}>
          <Text size="sm">Total Cost</Text>
        </Grid.Col>
        <Grid.Col span={1} data-testid="total" className={classes.row_value}>
          <Group gap="0.5ex" fz="md">
            <TokenRender
              size="var(--mantine-spacing-lg)"
              type="token"
              image_slug="usdc"
            />
            <Text fw="bold">
              {countFormatter(total_usdc, {
                maximumFractionDigits: 2,
                compact: false,
              })}
            </Text>
          </Group>
        </Grid.Col>
        <Grid.Col span={2} mt="lg">
          <Group justify="flex-end">
            {account.isConnected ? (
              <>
                <Button variant="subtle" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  size="lg"
                  onClick={onSubmit}
                  disabled={!is_ready || product.isPending}
                >
                  Execute Mint
                </Button>
              </>
            ) : (
              <ConnectWalletButton />
            )}
          </Group>
        </Grid.Col>
      </Grid>
    )
  },
)
