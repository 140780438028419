import { useCallback, useEffect, useMemo, useState } from 'react'

import { usePrevious } from '@mantine/hooks'
import { useNavigate } from '@tanstack/react-router'
import * as _ from 'lodash-es'

import { section_ordering } from '@/data/companies'

export function useScrollSync({
  section,
  cms_name,
  symbol,
}: {
  section?: string
  cms_name: string
  symbol: string
}) {
  cms_name = _.kebabCase(cms_name)
  const [sections_in_view, set_sections_in_view] = useState<string[]>([])
  const navigate = useNavigate()
  const previous_section = usePrevious(section)
  const [scroll_to, set_scroll_to] = useState<string>('')

  const active_section = useMemo(() => {
    let active: string | undefined
    section_ordering.forEach((s) => {
      const a_section = _.kebabCase(s)
      if (active != null) return
      if (sections_in_view.includes(a_section)) {
        active = a_section
      }
    })
    return active ?? section ?? _.kebabCase(section_ordering[0])
  }, [section, sections_in_view])

  // eslint-disable-next-line complexity
  useEffect(() => {
    // console.log({
    //   section,
    //   scroll_to,
    //   previous_section,
    //   active_section,
    // })
    if (section == null) return
    const load_with_section = previous_section == null
    const click_to_scroll =
      scroll_to == '' &&
      previous_section != section &&
      active_section != section

    if (click_to_scroll || load_with_section) {
      set_scroll_to(section)
    }
  }, [active_section, previous_section, scroll_to, section])

  const onScrollFinish = useCallback(() => {
    set_scroll_to('')
  }, [])

  const onInViewport = useCallback(
    ({ title, in_viewport }: { title: string; in_viewport: boolean }) => {
      set_sections_in_view((sections) => {
        const in_view_section = _.kebabCase(title)
        if (in_viewport && !sections.includes(in_view_section)) {
          return [...sections, in_view_section]
        } else if (!in_viewport && sections.includes(in_view_section)) {
          return _.without(sections, in_view_section)
        }
        return sections
      })
    },
    [],
  )

  useEffect(() => {
    if (scroll_to != '') return
    void navigate({
      to: '/research/$symbol/company/$cms_name/$section',
      params: {
        symbol,
        cms_name,
        section: active_section,
      },
      resetScroll: false,
      replace: true,
    })
  }, [active_section, cms_name, navigate, scroll_to, symbol])

  return {
    onScrollFinish,
    onInViewport,
    scroll_to,
  }
}
