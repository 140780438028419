import { memo } from 'react'

import { get_vc_logo } from '@/data/vcs'
import white_url from '@/data/vcs/images/white.png'

import { BaseLogo, type LogoProps } from '../CompanyLogo'

export const VCLogo = memo<
  LogoProps & {
    name: string
  }
>(function CompanyLogo({ name, ...props }) {
  const { image_url, dark_background_logo, no_white_mode_logo } = get_vc_logo({
    name,
  })

  return (
    <BaseLogo
      image_url={image_url}
      white_url={white_url}
      dark_background_logo={dark_background_logo}
      no_white_mode_logo={no_white_mode_logo}
      {...props}
    />
  )
})
