import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { skipToken, useQuery } from '@tanstack/react-query'
import { Alchemy, AssetTransfersCategory, Network } from 'alchemy-sdk'
import dayjs from 'dayjs'
import * as _ from 'lodash-es'
import { formatUnits, type Hex, hexToBigInt } from 'viem'
import { useAccount } from 'wagmi'

import { useGetDecimals } from '../blockchain/useGetDecimals'
import { useGetPriceHistory } from '../fission_dex'
import { useProductInfo, useTokenInfo } from '../products'
import type { TokenSymbol } from '../products/products_queries'
import { useGetVaultHistory } from '../vaults'

const config = {
  apiKey: import.meta.env.VITE_ALCHEMY_KEY,
  network: Network.ETH_SEPOLIA,
}
const alchemy = new Alchemy(config)

export const keys = createQueryKeyStore({
  portfolio: {
    history: null,
  },
})

type HistoryRecord = {
  symbol: Extract<TokenSymbol, 'FISN' | 'TECH' | 'TVLT'>
  timestamp: number
  change: number
  bonus_change: number
}

export type BalanceRecord = {
  day: number
  balance_usdc: number
  change_usdc: number
  components: Record<
    HistoryRecord['symbol'],
    {
      balance_tokens: number
      change_tokens: number
      cost_change_usdc: number
    }
  >
}

function hex_to_float(hex: string, decimals: number): number {
  return Number(formatUnits(hexToBigInt(hex as Hex), decimals))
}

// eslint-disable-next-line complexity, max-lines-per-function
export function useGetPortfolioHistory() {
  const { address } = useAccount()
  const { data: fisn_token } = useTokenInfo({ symbol: 'FISN' })
  const { data: tech_token } = useProductInfo({ symbol: 'TECH' })
  const { data: tvlt_token } = useProductInfo({ symbol: 'TVLT' })
  const { data: fisn_decimals } = useGetDecimals(fisn_token?.token_address)
  const { data: tech_decimals } = useGetDecimals(tech_token?.token_address)
  const { data: tvlt_decimals } = useGetDecimals(tvlt_token?.token_address)
  const { data: tvlt_prices } = useGetVaultHistory({ id: tvlt_token?.id })
  const { data: tech_prices } = useGetPriceHistory({
    symbol: 'TECH',
    period_type: 'daily',
  })

  const enabled =
    address != null &&
    fisn_token != null &&
    tech_token != null &&
    tvlt_token != null &&
    fisn_decimals != null &&
    tech_decimals != null &&
    tvlt_decimals != null &&
    tvlt_prices.length > 0 &&
    tech_prices.length > 0

  return useQuery({
    queryKey: keys.portfolio.history.queryKey,
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    gcTime: Infinity,
    queryFn: !enabled
      ? skipToken
      : // eslint-disable-next-line max-lines-per-function
        async () => {
          const lookup: Record<
            Hex,
            { decimals: number; symbol: HistoryRecord['symbol'] }
          > = {
            [fisn_token.token_address.toLowerCase()]: {
              decimals: fisn_decimals,
              symbol: 'FISN',
            },
            [tech_token.token_address.toLowerCase()]: {
              decimals: tech_decimals,
              symbol: 'TECH',
            },
            [tvlt_token.token_address.toLowerCase()]: {
              decimals: tvlt_decimals,
              symbol: 'TVLT',
            },
          } as const

          const to_data_p = alchemy.core.getAssetTransfers({
            toAddress: address,
            category: [AssetTransfersCategory.ERC20],
            contractAddresses: Object.keys(lookup),
            withMetadata: true,
          })
          const from_data_p = alchemy.core.getAssetTransfers({
            fromAddress: address,
            category: [AssetTransfersCategory.ERC20],
            contractAddresses: [
              fisn_token.token_address,
              tech_token.token_address,
              tvlt_token.token_address,
            ],
            withMetadata: true,
          })

          const results = await Promise.all([to_data_p, from_data_p])

          let history: HistoryRecord[] = []

          _.map(results, 'transfers')
            .flat()
            .forEach((transfer) => {
              const token =
                lookup[transfer.rawContract.address!.toLowerCase() as Hex]
              if (token == null) console.log(transfer)

              const sign =
                transfer.to!.toLowerCase() == address.toLowerCase() ? 1 : -1

              const change = hex_to_float(
                transfer.rawContract.value!,
                token.decimals,
              )

              const entry: HistoryRecord = {
                symbol: token.symbol,
                timestamp: dayjs(transfer.metadata.blockTimestamp).valueOf(),
                change: sign * change,
                bonus_change: token.symbol == 'FISN' ? change : 0,
              }
              history.push(entry)
            })

          history = _.sortBy(history, 'timestamp')
          const sorted_tvlt_prices = _.sortBy(tvlt_prices, 'date_s')
          const sorted_tech_prices = _.sortBy(tech_prices, 'date')

          const get_price = (symbol: HistoryRecord['symbol'], date: number) => {
            let token_price = 0
            date = dayjs(date).endOf('day').valueOf()
            if (symbol === 'FISN') {
              token_price = 0.05
            } else if (symbol === 'TECH') {
              token_price =
                _.findLast(sorted_tech_prices, (p) => p.date <= date)
                  ?.token_usdc_price ?? 0
            } else if (symbol === 'TVLT') {
              token_price =
                _.findLast(sorted_tvlt_prices, (p) => p.date_s * 1000 <= date)
                  ?.price_per_token ?? 0
            }
            return token_price
          }

          const combined_history: BalanceRecord[] = []
          let last_record: BalanceRecord = {
            day: 0,
            balance_usdc: 0,
            change_usdc: 0,
            components: _.map(lookup, 'symbol').reduce(
              (obj, symbol) => {
                obj[symbol] = {
                  balance_tokens: 0,
                  change_tokens: 0,
                  cost_change_usdc: 0,
                }
                return obj
              },
              {} as BalanceRecord['components'],
            ),
          }
          const today_end = dayjs().endOf('day')
          let next_day = dayjs(history[0].timestamp).startOf('day')

          while (next_day.isBefore(today_end)) {
            const record: BalanceRecord = {
              day: next_day.valueOf(),
              balance_usdc: 0,
              change_usdc: 0,
              components: _.cloneDeep(last_record.components),
            }

            Object.values(lookup).forEach((token) => {
              const price = get_price(token.symbol, record.day)

              const entries = _.filter(
                history,
                (e) =>
                  e.symbol == token.symbol &&
                  dayjs(e.timestamp).isSame(dayjs(record.day), 'day'),
              )
              const changes = _.sumBy(entries, 'change')
              const bonus_changes = _.sumBy(entries, 'bonus_change')
              record.components[token.symbol].balance_tokens += changes
              record.components[token.symbol].change_tokens = changes
              record.components[token.symbol].cost_change_usdc =
                (changes - bonus_changes) * price

              record.change_usdc += changes * price
              record.balance_usdc +=
                price * record.components[token.symbol].balance_tokens
            })

            combined_history.push(record)

            next_day = next_day.add(1, 'day')
            last_record = record
          }

          return combined_history
        },
  })
}
