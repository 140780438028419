const fund_descriptions: Record<string, { description: string }> = {
  'ai-data': {
    description:
      'The AI & Data fund contains VC-backed private companies revolutionizing the AI & Data markets. Learn more about the fund and portfolio companies below.',
  },
  software: {
    description:
      'The software fund contains VC-backed private companies revolutionizing the software markets. Learn more about the fund and portfolio companies below.',
  },
  industrial: {
    description:
      'The industrial fund contains VC-backed private companies revolutionizing the industrial markets. Learn more about the fund and portfolio companies below.',
  },
  consumer: {
    description:
      'The customer fund contains VC-backed private companies revolutionizing the customer markets. Learn more about the fund and portfolio companies below.',
  },
  fintech: {
    description:
      'The FinTech fund contains VC-backed private companies revolutionizing the financial markets. Learn more about the fund and portfolio companies below.',
  },
  technology: {
    description:
      'The technology fund contains VC-backed private companies revolutionizing their respective markets. Learn more about the fund and portfolio companies below.',
  },
  blockchain: {
    description:
      'The Blockchain fund contains VC-backed private companies revolutionizing the blockchain markets. Learn more about the fund and portfolio companies below.',
  },
}
export default fund_descriptions
