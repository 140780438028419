import { Group, Stack, Text, Title } from '@mantine/core'
import { createFileRoute, Navigate } from '@tanstack/react-router'

import { LinkButton } from '@repo/common/components/LinkButton'

import { AddLiquidity } from '@/components/AddLiquidity'
import { ContentBox } from '@/components/ContentBox'

export const Route = createFileRoute('/_app/trade/$symbol/create-position')({
  component: TradeSymbolCreatePositionPage,
})

function TradeSymbolCreatePositionPage() {
  let { symbol } = Route.useParams()
  symbol = symbol.toUpperCase()

  if (symbol != 'TECH') {
    return <Navigate to="/overview" replace />
  }

  return (
    <Stack align="center">
      <Stack align="center">
        <Title fz="title_sm">Create Manual Position</Title>
        <ContentBox p="md" w="100%" gap="lg" maw="400px">
          <Text size="lg">What to have an automated position?</Text>
          <LinkButton size="lg" to="/vault/$symbol" params={{ symbol: 'TVLT' }}>
            Invest in TVLT instead!
          </LinkButton>
          <Text size="sm" opacity={0.7}>
            We recommend using the {symbol} Vault to maximize returns.
          </Text>
        </ContentBox>
        <AddLiquidity fund_symbol="TECH" />
        <Group justify="flex-end" w="100%" mt="40px" opacity={0.6}>
          <LinkButton variant="outline" to="/trade">
            Swap
          </LinkButton>
        </Group>
      </Stack>
    </Stack>
  )
}
