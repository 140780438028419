import { memo } from 'react'

import { usdLargeNumberFormatter } from '@repo/common/helpers/formatters'
import { useGetVaultComposition } from '@repo/common/queries/vaults'

export const VaultTVL = memo<{ id: RubyID }>(function VaultTVL({ id }) {
  const { data, isLoading } = useGetVaultComposition({ id })
  if (isLoading) return null
  return <>{usdLargeNumberFormatter(data?.tvl, { maximumFractionDigits: 2 })}</>
})
