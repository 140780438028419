import { memo } from 'react'

import {
  Group,
  Progress,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core'

import { ContentBox } from '../../../../apps/labs/src/components/ContentBox'
import {
  usdLargeNumberFormatter,
  value_formatter,
} from '../../helpers/formatters'
import { useGetPoolStats, useGetTokenPrice } from '../../queries/fission_dex'
import type { TokenSymbol } from '../../queries/products'
import { Liquidity_Colors } from '../LiquidityChart'
import { TokenRender } from '../TokenRender'

// eslint-disable-next-line max-lines-per-function
export const PoolStats = memo<{ symbol: TokenSymbol }>(function PoolStats({
  symbol,
}) {
  const { data: stats } = useGetPoolStats({ symbol })
  const { data: tech_price } = useGetTokenPrice({ symbol })

  if (stats == null || tech_price == null) return null
  const token_in_usdc = stats.token_tvl * tech_price
  return (
    <ContentBox gap="lg" p="md">
      <Stack gap="xs">
        <Title order={2} lh="1">
          Pool Balances
        </Title>
        <Group justify="space-between">
          <Group gap="0.25em">
            <Text size="sm">{value_formatter(stats.usdc_tvl)}</Text>
            <TokenRender
              type="token"
              size="var(--mantine-spacing-lg)"
              image_slug="usdc"
            />
            <Text size="sm">USDC</Text>
          </Group>
          <Group gap="0.25em">
            <Text size="sm">{value_formatter(stats.token_tvl)}</Text>
            <TokenRender
              type="token"
              size="var(--mantine-spacing-lg)"
              image_slug="technology"
            />
            <Text size="sm">{symbol}</Text>
          </Group>
        </Group>
        <Progress.Root size="xl">
          <Tooltip
            label={`USDC ${usdLargeNumberFormatter(stats.usdc_tvl, { maximumFractionDigits: 2 })}`}
          >
            <Progress.Section
              value={(100 * stats.usdc_tvl) / (stats.usdc_tvl + token_in_usdc)}
              color={Liquidity_Colors.usdc}
            />
          </Tooltip>
          <Tooltip
            label={`${symbol} ${usdLargeNumberFormatter(token_in_usdc, { maximumFractionDigits: 2 })}`}
          >
            <Progress.Section
              value={(100 * token_in_usdc) / (stats.usdc_tvl + token_in_usdc)}
              color={Liquidity_Colors.token}
            />
          </Tooltip>
        </Progress.Root>
      </Stack>
      <SimpleGrid cols={3}>
        <Stack gap="xxs" align="center">
          <Text size="sm" opacity={0.5}>
            TVL
          </Text>
          <Text size="lg" lh="1">
            {usdLargeNumberFormatter(stats.usdc_tvl + token_in_usdc, {
              maximumFractionDigits: 2,
            })}
          </Text>
        </Stack>
        <Stack gap="xxs" align="center">
          <Text size="sm" opacity={0.5}>
            Lifetime volume
          </Text>
          <Text size="lg" lh="1">
            {usdLargeNumberFormatter(
              stats.usdc_volume + stats.token_volume * tech_price,
              {
                maximumFractionDigits: 2,
              },
            )}
          </Text>
        </Stack>
        <Stack gap="xxs" align="center">
          <Text size="sm" opacity={0.5}>
            24H volume
          </Text>
          <Text size="lg" lh="1">
            {usdLargeNumberFormatter(
              stats.last_day_usdc_volume +
                stats.last_day_token_volume * tech_price,
              {
                maximumFractionDigits: 2,
              },
            )}
          </Text>
        </Stack>
      </SimpleGrid>
    </ContentBox>
  )
})
