import { memo } from 'react'

import { Group, type GroupProps, Stack, Text, Title } from '@mantine/core'

import { LinkButton } from '@repo/common/components/LinkButton'
import { TokenImage } from '@repo/common/components/TokenImage'
import { FundAmount } from '@repo/common/components/updating_numbers/FundAmount'
import { FundNav1yAPY } from '@repo/common/components/updating_numbers/FundNav1yAPY'
import { FundPrice } from '@repo/common/components/updating_numbers/FundPrice'
import { FundTVL } from '@repo/common/components/updating_numbers/FundTVL'
import { OnlyWalletBalance } from '@repo/common/components/WalletBalance'
import { usdFormatter } from '@repo/common/helpers/formatters'
import { type FundFindBy, useFundInfo } from '@repo/common/queries/funds'

import { useCompanyMetrics } from '@/queries/company'

import { CompanyLogo } from '../CompanyLogo'

import classes from './InvestmentRow.module.css'

export const InvestmentRow = memo<
  FundFindBy & {
    variant?: 'invest' | 'research' | 'details'
    w?: GroupProps['w']
  }
  // eslint-disable-next-line complexity, max-lines-per-function
>(function InvestmentRow({ fund_id, symbol, variant = 'invest', w }) {
  const { data: fund, isLoading } = useFundInfo({ fund_id, symbol })
  const metrics_data = useCompanyMetrics()

  const top_companies =
    fund?.companies?.filter((c) => {
      if (metrics_data.data == null) return false
      if (metrics_data.data[c.cms_name] == null) {
        console.error(`No metrics data for ${c.cms_name}`)
      }
      if (metrics_data.data[c.cms_name].highlight == 'any') return true
      if (
        fund.image_slug.toLowerCase() !== 'technology' &&
        metrics_data.data[c.cms_name].highlight == 'direct'
      )
        return true
      return false
    }) ?? []

  if (isLoading || fund == null) return null

  return (
    <Group
      className={classes.root}
      w={w}
      align={variant != 'details' ? 'center' : 'flex-start'}
    >
      <Group w="190px" wrap="nowrap">
        <TokenImage image_slug={fund.name} size="50" />
        <Stack gap="0">
          <Text size="lg" fw="bold" lh="1">
            {fund.name}
          </Text>
          <Text size="sm">{fund.symbol}</Text>
        </Stack>
      </Group>
      <Group gap="sm">
        <Group gap="lg">
          <Stack gap="0" className={classes.inter_column}>
            <Title order={2} fz="xs" lh="2">
              Price per Share
            </Title>
            <Text size="lg" lh="md" c="green">
              <FundPrice id={fund.id} />
            </Text>
          </Stack>
          <Stack gap="0" className={classes.inter_column}>
            <Title order={2} fz="xs" lh="2">
              NAV per Share
            </Title>
            <Text size="lg" lh="md" c="chart_a.5">
              {usdFormatter(fund.price_per_share)}
            </Text>
          </Stack>
          <Stack gap="0" className={classes.inter_column}>
            <Title order={2} fz="xs" lh="2">
              NAV 1 Year APY
            </Title>
            <Text size="lg" lh="md" c="green">
              <FundNav1yAPY id={fund.id} />
            </Text>
          </Stack>
          <Stack gap="0" className={classes.inter_column} miw="90px">
            <Title order={2} fz="xs" lh="2">
              TVL
            </Title>
            <Text size="lg" lh="md" c="green">
              <FundTVL id={fund.id} />
            </Text>
          </Stack>
        </Group>
        {['trade', 'details'].includes(variant) && (
          <Group wrap="nowrap">
            <Stack gap="0" mih="57" miw="100" className={classes.inter_column}>
              <Title order={2} fz="xs" lh="2">
                My Balance
              </Title>
              <Text size="lg" lh="md">
                <FundAmount id={fund.id} />
              </Text>
            </Stack>
            <Stack gap="0" mih="57" className={classes.inter_column}>
              <Title order={2} fz="xs" lh="2">
                My Shares
              </Title>
              <Text size="lg" lh="md">
                <OnlyWalletBalance token={fund.symbol} />
              </Text>
            </Stack>
          </Group>
        )}
      </Group>
      {variant == 'invest' && (
        <Stack gap="0" className={classes.inter_column}>
          <Title order={2} fz="xs" lh="2">
            Companies including
          </Title>
          <Group gap="xs" wrap="nowrap">
            {top_companies.slice(0, 3).map(({ cms_name, name }) => (
              <CompanyLogo
                key={cms_name}
                cms_name={cms_name}
                title={name}
                h="30"
              />
            ))}
          </Group>
        </Stack>
      )}
      <Group flex="1" justify="flex-end" mr="lg">
        {variant != 'details' && (
          <LinkButton
            to="/investments/$symbol"
            params={{ symbol: fund.symbol }}
            size="sm"
            variant="outline"
          >
            View Details
          </LinkButton>
        )}
      </Group>
    </Group>
  )
})
