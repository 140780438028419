import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import * as _ from 'lodash-es'

dayjs.extend(utc)

export const tick_wrapper = (formatter: (value: number) => string) => {
  return (value: unknown) => formatter(value as number)
}

export const usdFormatter = (value?: number | string, showCents = true) => {
  value = Number(value ?? 0)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  if (!showCents) {
    value = Math.ceil(value)
    return formatter.format(value).slice(0, -'.00'.length)
  }
  const text = formatter.format(value)
  return text == '-$0.00' ? '$0.00' : text
}

export const countFormatter = (
  value: number,
  {
    compact = true,
    maximumFractionDigits = 1,
  }: {
    compact?: boolean
    maximumFractionDigits?: number
  } = {},
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    notation: compact ? 'compact' : 'standard',
    maximumFractionDigits,
  })
  return formatter.format(value)
}

export const usdLargeNumberFormatter = (
  value?: number | string | null,
  {
    missing_make_blank = false,
    maximumFractionDigits = 0,
    missing_string = '',
  }: {
    missing_make_blank?: boolean
    maximumFractionDigits?: number
    missing_string?: string
  } = {},
) => {
  if (value === null || (missing_make_blank && value == null)) {
    return missing_string
  }
  value = Number(value ?? 0)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
    notation: 'compact',
  })

  return formatter.format(value)
}

export const value_formatter = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    notation: 'compact',
  })
  return formatter.format(value)
}

export const crypto_value_formatter = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    notation: 'compact',
  })
  return formatter.format(value)
}

export const percentage_formatter = (
  percent: number,
  {
    compact = false,
    maximumFractionDigits = 2,
  }: {
    compact?: boolean
    maximumFractionDigits?: number
  } = {},
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    notation: compact ? 'compact' : 'standard',
    maximumFractionDigits,
  })
  return formatter.format(percent / 100)
}

const DATE_FORMAT = 'MMM D'
export const date_formatter = (value: number) =>
  dayjs.utc(value).format(DATE_FORMAT)

export const date_s_formatter = (value: number) =>
  dayjs.unix(value).format(DATE_FORMAT)

export const full_date_formatter = (value?: dayjs.ConfigType) =>
  dayjs(value).format('MMM DD, YYYY')

export const legend_name_formatter = (name: string) => _.startCase(name)

export const hour_formatter = (value: number) => {
  const time = dayjs(value)
  if (time.hour() === 0) {
    return time.format(DATE_FORMAT)
  }
  return time.format('h:mm a')
}
