import { memo } from 'react'

import { Group } from '@mantine/core'

import { useTopCompanyInvestors } from '@/hooks/investors'

import { VCLogo } from '../VCLogo'

export const CompanyInvestors = memo<{
  cms_name: string
  // eslint-disable-next-line max-lines-per-function, complexity
}>(function CompanyInvestors({ cms_name }) {
  const top_investors = useTopCompanyInvestors({ cms_name, count: 3 })

  return (
    <Group>
      {top_investors.map((investor) => (
        <VCLogo key={investor} name={investor} title={investor} h="40" />
      ))}
    </Group>
  )
})
