/* eslint-disable complexity */
import { memo, useEffect } from 'react'

import { Button, Grid, Group, NumberInput, Stack, Text } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useFocusWithin } from '@mantine/hooks'
import { z } from 'zod'

import { TokenRender } from '@repo/common/components/TokenRender'
import { countFormatter, usdFormatter } from '@repo/common/helpers/formatters'
import { useGetWalletBalance } from '@repo/common/queries/blockchain/useGetWalletBalance'
import { useBuyCartVault } from '@repo/common/queries/cart'
import {
  type InSideType,
  useGetTokenPrice,
} from '@repo/common/queries/fission_dex'
import type { TokenSymbol } from '@repo/common/queries/products'
import {
  useGetDepositAmounts,
  useGetVaultLatestData,
} from '@repo/common/queries/vaults'

// eslint-disable-next-line max-lines-per-function
import classes from './PanelBuyVault.module.css'

const default_reason = 'Enter deposits'

export const PanelBuyVault = memo<{
  vault_id: RubyID
  onCancel: () => void
  onCompleted: () => void
  amount?: number
}>(
  // eslint-disable-next-line max-lines-per-function
  function PanelBuyVault({ vault_id, onCancel, onCompleted }) {
    const vault_price_result = useGetVaultLatestData({ id: vault_id })
    const fund_symbol: TokenSymbol = 'TECH'
    const { data: tech_price } = useGetTokenPrice({ symbol: fund_symbol })
    const cart = useBuyCartVault()

    const form = useForm({
      initialValues: {
        deposit_token: 10,
        deposit_usdc: 1000,
        in_side: 'TOKEN',
      } as {
        deposit_token: number | undefined
        deposit_usdc: number | undefined
        in_side: InSideType
      },
      validateInputOnChange: true,
      validate: (values) => {
        return zodResolver(get_schema())(values)
      },
    })

    const deposit_token_focus = useFocusWithin()
    const deposit_usdc_focus = useFocusWithin()

    const { balance: balance_token } = useGetWalletBalance('TECH')
    const { balance: balance_usdc } = useGetWalletBalance('USDC')

    const updates = useGetDepositAmounts({ id: vault_id, ...form.getValues() })

    const onSubmit = (
      values: Parameters<Parameters<typeof form.onSubmit>[0]>[0],
    ) => {
      cart.setValues({
        deposit_token: values.deposit_token ?? 0,
        deposit_usdc: values.deposit_usdc ?? 0,
        id: vault_id,
      })
      onCompleted()
    }

    useEffect(() => {
      if (!updates.enabled) return
      if (
        updates.deposit_token == 0 &&
        form.getValues().in_side == 'TOKEN' &&
        form.getValues().deposit_token != 0
      ) {
        console.log('set deposit_token to 0')
        form.setValues({ deposit_token: 0, in_side: 'USDC' })
      }
      if (
        updates.deposit_usdc == 0 &&
        form.getValues().in_side == 'USDC' &&
        form.getValues().deposit_usdc != 0
      ) {
        console.log('set deposit_usdc to 0')
        form.setValues({ deposit_usdc: 0, in_side: 'TOKEN' })
      }
    }, [form, updates.deposit_token, updates.deposit_usdc, updates.enabled])

    const get_schema = () => {
      return z.object({
        deposit_token:
          updates.deposit_token == 0 && (updates.deposit_usdc ?? 0) > 0
            ? z.number({ message: default_reason })
            : z
                .number({ message: default_reason })
                .positive()
                .max(balance_token, {
                  message: `Insufficient ${fund_symbol} balance`,
                }),
        deposit_usdc:
          updates.deposit_usdc == 0 && (updates.deposit_token ?? 0) > 0
            ? z.number({ message: default_reason })
            : z
                .number({ message: default_reason })
                .positive()
                .max(balance_usdc, {
                  message: `Insufficient USDC balance`,
                }),
      })
    }

    const enabled = form.isValid()
    const reason =
      get_schema().safeParse(form.getValues()).error?.errors[0]?.message ||
      default_reason

    const vault_price = vault_price_result.data?.price_per_token

    if (vault_price == null || tech_price == null || updates.enabled == false)
      return null

    const total_vault =
      ((form.getValues().deposit_token ?? 0) * tech_price +
        (form.getValues().deposit_usdc ?? 0)) /
      vault_price

    return (
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Grid columns={2} gutter="0" className={classes.root}>
          <Grid.Col span={1} className={classes.row}>
            <Group>
              <TokenRender image_slug="technology" type="vault" size="50px" />
              <Stack gap="0">
                <Text size="lg" lh="1">
                  TECH Vault
                </Text>
                <Text size="sm">TVLT</Text>
              </Stack>
            </Group>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row_value}>
            <Text>{usdFormatter(vault_price)}/share</Text>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row}>
            <Text size="sm">Order Type</Text>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row_value}>
            <Text>Market</Text>
          </Grid.Col>
          {form.getValues().in_side == 'USDC' ? (
            <>
              <Grid.Col span={1} className={classes.row}>
                <Text size="sm">Deposit </Text>
                <Group justify="flex-end" gap="1ex" mx="xxs">
                  <TokenRender
                    type="token"
                    size="var(--mantine-spacing-lg)"
                    image_slug="usdc"
                  />
                  <Text size="sm">USDC</Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={1} className={classes.row_value}>
                <NumberInput
                  ref={deposit_usdc_focus.ref}
                  min={1}
                  size="lg"
                  {...form.getInputProps('deposit_usdc')}
                  name="deposit_usdc"
                  id="deposit_usdc"
                  aria-label="deposit USDC amount"
                />
              </Grid.Col>
              <Grid.Col span={1} offset={1} className={classes.row_value}>
                <Group justify="flex-end" align="baseline" gap="0">
                  <Text
                    size="sm"
                    opacity={0.7}
                    data-testid="wallet usdc balance"
                  >
                    Balance:{' '}
                    {countFormatter(balance_usdc, {
                      maximumFractionDigits: 2,
                      compact: false,
                    })}
                  </Text>
                  <Button
                    size="compact-sm"
                    variant="subtle"
                    onClick={() =>
                      form.setValues({
                        deposit_usdc: balance_usdc,
                        in_side: 'USDC',
                      })
                    }
                  >
                    MAX
                  </Button>
                </Group>
              </Grid.Col>
            </>
          ) : (
            <>
              <Grid.Col span={1} className={classes.row}>
                <Text size="sm">Deposit </Text>
                <Group justify="flex-end" gap="1ex" mx="xxs">
                  <TokenRender
                    type="token"
                    size="var(--mantine-spacing-lg)"
                    image_slug="technology"
                  />
                  <Text size="sm">{fund_symbol}</Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={1} className={classes.row_value}>
                <NumberInput
                  ref={deposit_token_focus.ref}
                  min={1}
                  size="lg"
                  {...form.getInputProps('deposit_token')}
                  name="deposit_token"
                  id="deposit_token"
                  aria-label={`deposit ${fund_symbol} amount`}
                />
              </Grid.Col>
              <Grid.Col span={1} offset={1} className={classes.row_value}>
                <Group justify="flex-end" align="baseline" gap="0">
                  <Text
                    size="sm"
                    opacity={0.7}
                    data-testid="wallet token balance"
                  >
                    Balance:{' '}
                    {countFormatter(balance_token, {
                      maximumFractionDigits: 4,
                      compact: false,
                    })}
                  </Text>
                  <Button
                    size="compact-sm"
                    variant="subtle"
                    onClick={() =>
                      form.setValues({
                        deposit_token: balance_token,
                        in_side: 'TOKEN',
                      })
                    }
                  >
                    MAX
                  </Button>
                </Group>
              </Grid.Col>
            </>
          )}
          <Grid.Col span={1} fw="bold" className={classes.row}>
            <Text size="md">Total Shares</Text>
          </Grid.Col>
          <Grid.Col
            span={1}
            fw="bold"
            data-testid="total"
            className={classes.row_value}
          >
            <Group gap="0.5ex">
              <TokenRender
                image_slug="technology"
                type="vault"
                size="var(--mantine-spacing-lg)"
              />
              <Text>
                {countFormatter(total_vault, {
                  maximumFractionDigits: 4,
                  compact: false,
                })}
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={2} mt="lg">
            <Group justify="flex-end">
              <Button variant="subtle" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                disabled={!enabled}
                type="submit"
                miw="12em"
                variant={enabled ? 'filled' : 'outline'}
              >
                {enabled ? 'Buy' : reason}
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    )
  },
)
