import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { skipToken, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import type { ResultOf } from 'gql.tada'

import { always_default_data } from '@repo/common/helpers/query_helpers'
import { useListProducts } from '@repo/common/queries/products'

import { graphql_call } from '../helpers/pricing_query'

import {
  query_get_daily_price,
  query_get_latest_price,
  query_get_oldest_price,
} from './vaults_graphql'

export const keys = createQueryKeyStore({
  vaults: {
    details: ({ id }: { id: RubyID }) => ({
      queryKey: [{ id }],
      contextQueries: {
        // blockchain
        lp_positions: null,
        composition: null,
        shares: null,

        // graphql
        latest_data: null,
        original_price: null,
        history: null,
      },
    }),
  },
})

export type VaultFindBy = {
  vault_id?: RubyID
  symbol?: string
}

export function useListVaults() {
  const results = useListProducts()

  const data = results.data ?? []

  const vaults = data.filter((f) => f.type === 'vault')

  const vault_find_by = ({ vault_id, symbol }: VaultFindBy) =>
    vaults.find((f) => f.id == vault_id || f.symbol === symbol?.toUpperCase())

  return {
    ...results,
    data: vaults,
    vault_find_by,
  }
}

export function useVaultInfo(args: VaultFindBy) {
  const { vault_find_by, ...others } = useListVaults()

  return { ...others, data: vault_find_by(args) }
}

export function useGetVaultOriginalPrice({ id }: { id: RubyID | undefined }) {
  return useQuery<{ price: number; date: ISO8601DateTime } | null>({
    queryKey: keys.vaults.details({ id: id! })._ctx.original_price.queryKey,
    queryFn: async () => {
      const result = await graphql_call({
        query: query_get_oldest_price,
      })
      if (result == null) return null
      return {
        price: Number(result[0].price_per_token),
        date: dayjs.unix(Number(result[0].block_timestamp_s)).toISOString(),
      }
    },
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: Boolean(id),
  })
}

export function useGetVaultLatestData({ id }: { id: RubyID | undefined }) {
  const { data: vault } = useVaultInfo({ vault_id: id })

  const enabled = id != null && vault != null

  return useQuery({
    queryKey: keys.vaults.details({ id: id! })._ctx.latest_data.queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const result = await graphql_call({
            query: query_get_latest_price,
          })
          const data = result?.[0] ?? null

          if (data == null) {
            return {
              block_timestamp_s: dayjs().unix(),
              tvl_usdc: 0,
              price_per_token: vault.price_per_share,
              total_supply: 0,
              position_count: 0,
              usdc_amount: 0,
              token_amount: 0,
            }
          }

          return data
        },
    gcTime: Infinity,
  })
}
export type VaultHistoryType = ResultOf<
  typeof query_get_daily_price
>['vaultPriceDayDatas'][number]

export function useGetVaultHistory({ id }: { id: RubyID | undefined }) {
  return always_default_data(
    [],
    useQuery<VaultHistoryType[]>({
      queryKey: keys.vaults.details({ id: id! })._ctx.history.queryKey,
      queryFn: async () => {
        const results = await graphql_call({
          query: query_get_daily_price,
          variables: {
            first_day_s: dayjs().subtract(1, 'year').unix(),
          },
        })
        if (results == null) return []
        if (dayjs.unix(results[0].date_s).isSame(dayjs(), 'day') == false) {
          results.unshift({ ...results[0], date_s: dayjs().unix() })
        }
        results.reverse() // sort by date in ascending order
        return results
      },
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      gcTime: Infinity,
      enabled: Boolean(id),
    }),
  )
}
