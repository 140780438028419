/* eslint-disable max-lines-per-function */
import { memo } from 'react'

import {
  Box,
  Button,
  Grid,
  Group,
  NumberInput,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { z } from 'zod'

import { TokenRender } from '@repo/common/components/TokenRender'
import { countFormatter, usdFormatter } from '@repo/common/helpers/formatters'
import { useGetWalletBalance } from '@repo/common/queries/blockchain/useGetWalletBalance'
import { useSellCartVault } from '@repo/common/queries/cart'
import {
  useGetVaultLatestData,
  useGetVaultSellReturnAmounts,
} from '@repo/common/queries/vaults'

import classes from './PanelSell.module.css'

const schema = z.object({
  quantity: z.number().min(1),
})

export const PanelSell = memo<{
  vault_id: RubyID
  onCancel: () => void
  onCompleted: () => void
  amount?: number
}>(function PanelSell({
  vault_id,
  amount: starting_amount = 1,
  onCancel,
  onCompleted,
}) {
  const { data } = useGetVaultLatestData({ id: vault_id })
  const vault_price = data?.price_per_token ?? 0
  const cart = useSellCartVault()
  const form = useForm({
    initialValues: {
      quantity: starting_amount,
    },
    validate: zodResolver(schema),
  })

  const { balance: tvlt_balance } = useGetWalletBalance('TVLT')

  const sell_usdc = form.getValues().quantity * vault_price

  const return_amounts = useGetVaultSellReturnAmounts({
    id: vault_id,
    sell_usdc,
  })

  const onSubmit = (
    values: Parameters<Parameters<typeof form.onSubmit>[0]>[0],
  ) => {
    cart.setValues({
      quantity: values.quantity,
      deposit_type: 'usdc',
      id: vault_id,
    })
    onCompleted()
  }

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Grid columns={2} gutter="0" className={classes.root}>
        <Grid.Col span={1} className={classes.row}>
          <Group>
            <TokenRender type="vault" image_slug="technology" size="50px" />
            <Stack gap="0">
              <Title fz="xl" lh="1">
                TECH Vault
              </Title>
            </Stack>
          </Group>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row_value}>
          <Text>{usdFormatter(vault_price)}/share</Text>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row}>
          <Text size="sm">Redeem Type</Text>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row_value}>
          <Text>Market</Text>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row}>
          <Text size="sm" component="label" htmlFor="quantity">
            Redeem Amount
          </Text>
        </Grid.Col>
        <Grid.Col span={1} className={classes.row_value}>
          <NumberInput
            min={1}
            size="lg"
            {...form.getInputProps('quantity')}
            name="quantity"
            id="quantity"
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Group justify="flex-end" align="baseline" gap="0">
            <Text size="sm" opacity={0.7} data-testid="wallet balance">
              Balance:{' '}
              {countFormatter(tvlt_balance, {
                maximumFractionDigits: 4,
                compact: false,
              })}
            </Text>
            <Button
              size="compact-sm"
              variant="subtle"
              onClick={() => form.setFieldValue('quantity', tvlt_balance)}
            >
              MAX
            </Button>
          </Group>
        </Grid.Col>
        <Grid.Col span={2}>
          <Box className={classes.line} />
        </Grid.Col>
        <Grid.Col span={1} className={classes.row}>
          Total Redeem Value
        </Grid.Col>
        <Grid.Col
          span={1}
          fw="bold"
          data-testid="total_value"
          className={classes.row_value}
        >
          {usdFormatter(sell_usdc)}
        </Grid.Col>
        <Grid.Col span={1} className={classes.row}>
          Total Return Shares
        </Grid.Col>
        <Grid.Col span={1} fw="bold" className={classes.row_value}>
          <Group>
            <Group gap="0.5ex">
              <TokenRender
                image_slug="technology"
                type="token"
                size="var(--mantine-spacing-lg)"
              />
              <Text data-testid="total_tech">
                {countFormatter(return_amounts.tech_amount, {
                  maximumFractionDigits: 4,
                  compact: false,
                })}
              </Text>
            </Group>
            <Group gap="0.5ex">
              <TokenRender
                image_slug="usdc"
                type="token"
                size="var(--mantine-spacing-lg)"
              />
              <Text data-testid="total_usdc">
                {countFormatter(return_amounts.usdc_amount, {
                  maximumFractionDigits: 4,
                  compact: false,
                })}
              </Text>
            </Group>
          </Group>
        </Grid.Col>
        <Grid.Col span={2} mt="lg">
          <Group justify="flex-end">
            <Button variant="subtle" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit">Redeem</Button>
          </Group>
        </Grid.Col>
      </Grid>
    </form>
  )
})
