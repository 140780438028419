import { type ComponentType, memo, useCallback } from 'react'

import { Button, type ButtonProps } from '@mantine/core'
import { useCounter, useDisclosure } from '@mantine/hooks'

import { PanelBuyComplete } from '@repo/common/components/PanelBuyComplete'
import { WorkflowModal } from '@repo/common/components/WorkflowModal'
import { useSellCartVault } from '@repo/common/queries/cart'

import { PanelLogin, useSkipLogin } from '../PanelLogin'
import { PanelSell } from '../PanelSell'
import { PanelSellPayment } from '../PanelSellPayment'

type PanelProps = {
  onCompleted: () => void
  onCancel: () => void
  vault_id: RubyID
}

const steps: {
  title: string
  Panel: ComponentType<PanelProps>
  progress: number
  can_close?: boolean
  useSkipCheck?: () => boolean
}[] = [
  {
    title: 'Redeem',
    Panel: PanelSell,
    progress: 50,
    can_close: true,
  },
  {
    title: 'Login',
    Panel: PanelLogin,
    progress: 25,
    useSkipCheck: useSkipLogin,
  },
  { title: 'Payment', Panel: PanelSellPayment, progress: 25 },
  { title: 'Completed', Panel: PanelBuyComplete, progress: 0 },
]

export const ProcessSell = memo<{ vault_id: RubyID } & ButtonProps>(
  function ProcessSell({ vault_id, ...props }) {
    const [active, setActive] = useCounter(0, { min: 0, max: steps.length - 1 })
    const [opened, { open, close }] = useDisclosure(false)
    const { clear: clear_cart } = useSellCartVault()

    const onCancel = useCallback(() => {
      close()
      setActive.reset()
      clear_cart()
    }, [clear_cart, close, setActive])

    const skip_checks = steps.map((s) => s.useSkipCheck?.())

    let next = active + 1
    while (skip_checks[next]) {
      next += 1
    }

    const onCompleted = useCallback(() => {
      setActive.set(next)
    }, [next, setActive])

    const progress = steps
      .slice(0, active)
      .reduce((acc, step) => acc + step.progress, 0)

    const { Panel, title } = steps[active]
    return (
      <>
        <Button {...props} onClick={open} />
        <WorkflowModal
          progress={progress}
          opened={opened}
          onClose={close}
          title={title}
          closeOnEscape={true}
          closeOnClickOutside={true}
        >
          <Panel
            vault_id={vault_id}
            onCancel={onCancel}
            onCompleted={onCompleted}
          />
        </WorkflowModal>
      </>
    )
  },
)
