import { memo } from 'react'

import { usdFormatter } from '@repo/common/helpers/formatters'
import { useGetVaultLatestData } from '@repo/common/queries/vaults'

export const VaultPrice = memo<{ id: RubyID }>(function VaultPrice({ id }) {
  const { data, isLoading } = useGetVaultLatestData({ id })
  if (isLoading) return null
  return <>{usdFormatter(data?.price_per_token)}</>
})
