import { memo, useEffect, useState } from 'react'

import * as _ from 'lodash-es'
import { useAccount } from 'wagmi'

import { useIsAuthenticated } from '@repo/common/queries/auth'
import {
  active_network,
  useAddWallet,
  useGetWallets,
} from '@repo/common/queries/wallet_addresses'

export const AutoAddWallet = memo(function AutoAddWallet() {
  const [called, set_called] = useState(false)
  const is_authenticated = useIsAuthenticated()
  const account = useAccount()
  const { mutate: add_wallet } = useAddWallet()
  const { data: wallets, isFetched } = useGetWallets()

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (called || !is_authenticated || !isFetched || !account.address) return

    if (
      _.find(wallets, { address: account.address, network: active_network() })
    ) {
      return
    }

    add_wallet({
      address: account.address,
      wallet_provider: account.connector?.name ?? 'unknown',
    })
    set_called(true)
  }, [
    called,
    is_authenticated,
    isFetched,
    account.address,
    wallets,
    add_wallet,
    account,
  ])

  return null
})
