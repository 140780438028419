import { memo, type ReactNode, useEffect } from 'react'

import { Text, Title } from '@mantine/core'
import {
  useInViewport,
  useMergedRef,
  usePrevious,
  useScrollIntoView,
} from '@mantine/hooks'

import { ContentBox } from '@/components/ContentBox'

import classes from './SectionBlock.module.css'

export const TextSectionBlock = memo<{
  title: string
  lines: string[]
  onInViewport: ({
    title,
    in_viewport,
  }: {
    title: string
    in_viewport: boolean
  }) => void
  scroll_to: boolean
  onScrollFinish: () => void
  children?: ReactNode
}>(function TextSectionBlock({ lines, children, ...props }) {
  return (
    <SectionBlock {...props}>
      {children != null && (
        <ContentBox className={classes.graph}>{children}</ContentBox>
      )}
      {lines.map((line, index) => {
        let is_bold = false
        if (line.startsWith('**')) {
          line = line.replace(/\*\*/g, '')
          is_bold = true
        }
        return (
          <Text
            fw={is_bold ? 'bold' : 'normal'}
            key={index}
            size="sm"
            lh="md"
            mb={is_bold ? '0' : 'md'}
          >
            {line}
          </Text>
        )
      })}
    </SectionBlock>
  )
})

export const SectionBlock = memo<{
  title: string
  children: ReactNode
  onInViewport: ({
    title,
    in_viewport,
  }: {
    title: string
    in_viewport: boolean
  }) => void
  scroll_to: boolean
  onScrollFinish: () => void
}>(function SectionBlock({ title, children, ...props }) {
  return (
    <ScrollToBlock title={title} {...props}>
      <ContentBox p="xl" display="block">
        <Title order={2} mb="lg" fz="lg">
          {title}
        </Title>
        {children}
      </ContentBox>
    </ScrollToBlock>
  )
})

export const ScrollToBlock = memo<{
  title: string
  children: ReactNode
  onInViewport: ({
    title,
    in_viewport,
  }: {
    title: string
    in_viewport: boolean
  }) => void
  scroll_to: boolean
  onScrollFinish: () => void
}>(function ScrollToBlock({
  title,
  children,
  onInViewport,
  scroll_to,
  onScrollFinish,
}) {
  const { ref: viewRef, inViewport: in_viewport } = useInViewport()
  const previous = usePrevious(in_viewport)
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 10,
    onScrollFinish,
  })
  const mergedRef = useMergedRef(viewRef, targetRef)

  useEffect(() => {
    if (previous != in_viewport) {
      onInViewport({ title, in_viewport })
    }
  }, [in_viewport, onInViewport, previous, title])

  useEffect(() => {
    if (!scroll_to) return
    scrollIntoView()
  }, [scrollIntoView, scroll_to])

  return <div ref={mergedRef}>{children}</div>
})
