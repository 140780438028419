import { memo } from 'react'

import { Paper, Text } from '@mantine/core'
import isChromatic from 'chromatic'
import dayjs from 'dayjs'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  date_s_formatter,
  full_date_formatter,
  tick_wrapper,
  usdFormatter,
} from '@repo/common/helpers/formatters'
import { useGetVaultHistory } from '@repo/common/queries/vaults'

export const y_tick_formatting = {
  fontSize: 'var(--mantine-font-size-sm)',
  fill: 'var(--mantine-color-dark-0)',
  opacity: 0.8,
}

export const x_tick_formatting = {
  fontSize: 'var(--mantine-font-size-sm)',
  fill: 'var(--mantine-color-dark-0)',
  opacity: 0.6,
}

export const Price_Colors = {
  stroke: 'var(--mantine-color-chestnut-2)',
  fill: 'var(--mantine-color-chestnut-2)',
}

export const VaultPerShareChart = memo<{ id: RubyID }>(
  function VaultPerShareChart({ id }) {
    const { data: daily_prices } = useGetVaultHistory({ id })

    return (
      <ResponsiveContainer>
        <LineChart data={daily_prices}>
          <CartesianGrid
            strokeDasharray="3 3"
            stroke="var(--mantine-color-dark-0)"
            opacity={0.4}
          />
          <XAxis
            dataKey="date_s"
            scale="time"
            type="number"
            domain={['dataMin', 'dataMax']}
            interval="preserveStartEnd"
            tick={x_tick_formatting}
            tickFormatter={tick_wrapper(date_s_formatter)}
          />
          <YAxis
            yAxisId="left"
            type="number"
            domain={['dataMin - 2', 'auto']}
            tick={y_tick_formatting}
            tickFormatter={tick_wrapper(usdFormatter)}
          />
          <Tooltip
            content={({ label, payload }) => (
              <ChartTooltip
                label={label as number}
                payload={payload as ToolTipType['payload']}
              />
            )}
          />

          <Line
            yAxisId="left"
            isAnimationActive={!isChromatic()}
            type="monotone"
            dataKey="price_per_token"
            {...Price_Colors}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    )
  },
)

export type ToolTipType = {
  label: number
  payload?: { name: string; color: string; value: number }[]
}

export function ChartTooltip({ label, payload }: ToolTipType) {
  if (payload == null || payload.length == 0) return null

  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
      <Text size="sm" mb={5}>
        {full_date_formatter(dayjs.unix(label))}
      </Text>
      <Text size="md" c={payload[0].color}>
        Price per token: {usdFormatter(payload[0].value)}
      </Text>
    </Paper>
  )
}
