import {
  DEFAULT_THEME,
  type MantineColorsTuple,
  type MantineThemeOverride,
} from '@mantine/core'

// https://tailwind.ink?p=1.f8f9f8ecf0f2d8dce1b2b6bc8d929c6d717e54576440414e2e2e381c1c23
const dark_metal: MantineColorsTuple = [
  '#f8f9f8',
  '#ecf0f2',
  '#d8dce1',
  '#B2B6BC', // metal
  '#8d929c',
  '#6d717e',
  '#545764',
  '#42403a',
  '#2d2b29',
  '#090706', // slate
]

// https://tailwind.ink?p=1.f8faf9ebf1f7d1dfeea6bdd87596ba5a739c48577e38415f1b233a161a29
const dark_blue: MantineColorsTuple = [
  '#f8faf9',
  '#ebf1f7',
  '#d1dfee',
  '#a6bdd8',
  '#7596ba',
  '#5a739c',
  '#48577e',
  '#38415f',
  '#1b233a',
  '#121316', // core color [9]
]

const gray: MantineColorsTuple = DEFAULT_THEME.colors.gray

// Foundation primary color
// https://tailwind.ink?p=1.feddc8ffc89ef9b47ce69d5bc483449e662f754b1f583814402914271c14
const chestnut: MantineColorsTuple = [
  '#fdebd9',
  '#f5d7b8',
  '#eabf95',
  '#D3A271', // 3 dust
  '#be864e',
  '#9d6733',
  '#754B1F', // 6 rust
  '#583814',
  '#402914',
  '#271c14',
]

// Labs primary color
// https://tailwind.ink?p=1.f8f9fce5eafccad6fba8befe8ba1ff6384fe2f66fa174ed4043b9f102562
const blue: MantineColorsTuple = [
  '#f8f9fc',
  '#e5eafc',
  '#cad6fb',
  '#a8befe',
  '#8ba1ff',
  '#6384fe',
  '#285FF4', // core color [6]
  '#174ed4',
  '#043b9f',
  '#102562',
]

// https://tailwind.ink?p=1.eafdeba3fbb15bf3742ee34e29cf1c00b500009900067903065903153521
const green: MantineColorsTuple = [
  '#eafdeb',
  '#a3fbb1',
  '#56F372', // core color [2]
  '#2ee34e',
  '#29cf1c',
  '#00b500',
  '#009900',
  '#067903',
  '#065903',
  '#153521',
]

const chart_a: MantineColorsTuple = [
  '#00876c',
  '#479a72',
  '#6fac7b',
  '#94be85',
  '#b8d093',
  '#dce2a4',
  '#fff4b8',
  '#f9d998',
  '#f4bd7c',
  '#eea067',
]
const chart_b: MantineColorsTuple = [
  '#e88259',
  '#e06152',
  '#d43d51',
  '#b51967',
  '#e74ec0',
  '#ca46ca',
  '#8a339c',
  '#9416d2',
  '#7f64ea',
  '#6c27f4',
]

export const app_colors = {
  primary: blue,
  dark: dark_blue,
  gray,
  chestnut,
  blue,
  dark_blue,
  dark_metal,
  green,
  chart_a,
  chart_b,
}

// https://typescale.com/ 1.25 Major Third
const sizes = {
  xxs: '0.512rem', // 8
  xs: '0.64rem', // 10
  sm: '0.8rem', // 12
  md: '1rem', // 16
  lg: '1.25rem', // 20
  xl: '1.56rem', // boundary 28 really 25
  xxl: '1.952rem', // 31
  xl1: '2.441rem',
  xl2: '3.052rem', // 48
  xl3: '3.815rem', // 61
}

// building in dark so flip light
export const fission_theme: MantineThemeOverride = {
  primaryColor: 'primary',
  primaryShade: {
    light: 5,
    dark: 6,
  },
  defaultGradient: {
    from: 'primary.6',
    to: 'primary.8',
    deg: 106,
  },
  fontFamily: `Lausanne, ${DEFAULT_THEME.fontFamily}`,
  headings: {
    fontFamily: `Lausanne, ${DEFAULT_THEME.fontFamily}`,
    fontWeight: '300',
    sizes: {
      h1: {
        fontSize: sizes.xl,
        lineHeight: '1',
      },
      h2: {
        fontSize: sizes.sm,
        lineHeight: '2.0',
      },
      h3: {
        fontSize: sizes.sm,
        lineHeight: '2.0',
      },
      h4: {
        fontSize: sizes.sm,
        lineHeight: '2.0',
      },
      h5: {
        fontSize: sizes.sm,
        lineHeight: '2.0',
      },
      h6: {
        fontSize: sizes.sm,
        lineHeight: '2.0',
      },
    },
  },
  fontSizes: {
    ...sizes,
    title_xs: sizes.xl,
    title_sm: sizes.xxl,
    title_md: sizes.xl1,
    title_lg: sizes.xl2,
    title_hero: sizes.xl3,
  },
  lineHeights: {
    xs: '1.42',
    sm: '1.71',
    md: '1.87',
    lg: '2.0',
    xl: '1.0',
    title_xs: '1.0',
    title_sm: '1.0',
    title_md: '1.0',
    title_lg: '1.0',
    title_hero: '1.0',
  },
  defaultRadius: 'xs',
  radius: sizes,
  spacing: sizes,
  other: {},
}

export type CustomColors = keyof typeof app_colors

export type Shades = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export type CustomColorsShades = `${CustomColors}.${Shades}`

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    colors: Record<CustomColors | string, MantineColorsTuple>
  }
}
