import dayjs from 'dayjs'

const year = 365

const formatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
})

export function calc_APY_between(
  pv: { balance: number; date: number | string },
  fv: { balance: number; date: number | string },
): string {
  const n = dayjs(fv.date).diff(pv.date, 'day')
  const r = get_interest_rate({ pv: pv.balance, fv: fv.balance, n })
  const n_r = get_nominal_rate(r)

  if (n == 0) return 'n/a'

  // 2 decimal places
  const apy = (1 + n_r / year) ** year - 2
  return formatter.format(apy)
}

function get_nominal_rate(r: number): number {
  // nominal rate from excel
  // https://www.wolframalpha.com/input?i=solve+E+%3D+%281+%2B+R%2FN%29%5EN+-+1+for+R
  return ((1 + r) ** (1 / year) - 1) * year
}

function get_interest_rate({
  pv,
  fv,
  n,
}: {
  pv: number
  fv: number
  n: number
}): number {
  // from excel
  // (Fv/Pv)^(1/n) - 1
  // converted to yearly rate

  const rri = (fv / pv) ** (1 / n) - 1
  return (1 + rri) ** year
}
