import { useCallback } from 'react'

import { Group } from '@mantine/core'
import { createFileRoute, useNavigate } from '@tanstack/react-router'

import {
  LoginPasswordReset,
  reset_search_schema,
} from '@repo/common/components/LoginPasswordReset'

export const Route = createFileRoute('/_appish/reset')({
  component: ResetPage,
  validateSearch: reset_search_schema,
})

///reset?uuid=3d644032-16e8-478c-9cd8-e768f584ba38&token=914a1f79-3d35-4329-b246-2e5925092711&type=reset
function ResetPage() {
  const { token, uuid } = Route.useSearch()
  const navigate = useNavigate()

  const onSuccess = useCallback(() => {
    void navigate({ to: '/portfolio', replace: true })
  }, [navigate])

  return (
    <Group
      gap="xl"
      wrap="nowrap"
      pl={{ base: 'sm', sm: '0' }}
      pr={{ base: 'sm', sm: 'xl' }}
      align="top"
      maw="400"
      mx="auto"
    >
      <LoginPasswordReset token={token} uuid={uuid} onSuccess={onSuccess} />
    </Group>
  )
}
