import { graphql } from '@repo/common/queries/helpers/server_query'

export const query_get_wallets = graphql(`
  query get_wallets {
    me {
      wallets {
        address
        network
      }
    }
  }
`)

export const mutation_add_wallet = graphql(`
  mutation add_wallet($input: WalletInput!) {
    add_wallet(input: $input) {
      added_at
    }
  }
`)
