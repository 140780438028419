import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { useQuery } from '@tanstack/react-query'

import { server_client } from '@repo/common/queries/helpers/server_query'

import { query_get_version } from './versions_graphql'

export const keys = createQueryKeyStore({
  versions: {
    server: null,
  },
})

export function useGetVersion() {
  return useQuery({
    queryKey: keys.versions.server.queryKey,
    queryFn: async () => {
      const result = await server_client.request({
        document: query_get_version,
      })
      return result.version
    },
  })
}
