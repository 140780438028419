import type { CustomColorsShades, Shades } from './theme'

const AvailableColors = 20

export function get_pie_color(
  index: number,
  { max_colors = 20 }: { max_colors?: number } = {},
): CustomColorsShades {
  const skip = 1 + Math.floor((AvailableColors - max_colors) / max_colors)
  index = index * skip

  const base = index < 10 ? 'chart_a' : 'chart_b'
  return `${base}.${(index % 10) as Shades}`
}
