import { memo } from 'react'

import {
  Box,
  Burger,
  Group,
  AppShell as MantineShell,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Outlet } from '@tanstack/react-router'
import dayjs from 'dayjs'
import * as _ from 'lodash-es'

import {
  IconDocs,
  IconInvestments,
  IconLP,
  IconPortfolio,
  IconQuestionMark,
  IconResearch,
  IconTrade,
} from '@repo/common/components/Icons'
import { RequireAccess } from '@repo/common/components/RequireAccess/RequireAccess'
import { RequireLogin } from '@repo/common/components/RequireLogin'
import { LabsDocsLink } from '@repo/common/helpers/cross_site_links'

import { section_ordering } from '@/data/companies'
import { Route as ResearchBaseRoute } from '@/routes/_app/research.$symbol.company.$cms_name.index'

import { AppBackground } from '../AppBackground'
import { Logo } from '../Logo'
import { type NavLinks, NavSideLinks } from '../NavSideLinks'
import { NavSubLinks, type NavSubLinksType } from '../NavSubLinks'
import { PortfolioMiniBox } from '../PortfolioMiniBox'

import classes from './AppShell.module.css'

export const main_links: NavLinks = [
  { to: '/investments', label: 'Investments', Icon: IconInvestments },
  { to: '/research', label: 'Research', Icon: IconResearch },
  { to: '/trade', label: 'Trade', Icon: IconTrade },
  {
    to: '/vault/$symbol',
    params: { symbol: 'TVLT' },
    label: 'Provide Liquidity',
    Icon: IconLP,
  },
  { to: '/portfolio', label: 'My Portfolio', Icon: IconPortfolio },
  { to: '/overview', label: 'How It Works', Icon: IconQuestionMark },
  { ...LabsDocsLink, Icon: IconDocs },
]

export const research_sub_links: NavSubLinksType = section_ordering.map(
  (section) => ({
    section: _.kebabCase(section),
    label: _.capitalize(section),
  }),
)

// TODO: page animations: https://github.com/TanStack/router/discussions/823
export const AppShell = memo(function AppShell() {
  return (
    <RequireLogin>
      <RequireAccess>
        <AppShellNoLoginRequired />
      </RequireAccess>
    </RequireLogin>
  )
})

export const AppShellNoLoginRequired = memo(function AppShellNoLoginRequired() {
  const [mobile_open, { toggle, close }] = useDisclosure()

  return (
    <AppBackground className={classes.root}>
      <MantineShell
        header={{ height: 60 }}
        withBorder={false}
        navbar={{
          width: 230,
          breakpoint: 'sm',
          collapsed: { mobile: !mobile_open, desktop: false },
        }}
      >
        <MantineShell.Header className={classes.header}>
          <Burger
            aria-label="Toggle navigation"
            opened={mobile_open}
            onClick={toggle}
            hiddenFrom="sm"
            size="sm"
            aria-expanded={mobile_open}
          />
          <Logo size={32} as_link with_name />
        </MantineShell.Header>
        <MantineShell.Navbar className={classes.navbar} onClick={close}>
          <PortfolioMiniBox />
          <NavSideLinks links={main_links} />
          <NavSubLinks
            title="Company Research"
            base_route_id={ResearchBaseRoute.id}
            children_path="/research/$symbol/company/$cms_name/$section"
            links={research_sub_links}
          />
        </MantineShell.Navbar>
        <MantineShell.Main>
          <Box
            maw="100rem"
            pr="var(--app-body-margin)"
            pl={{ base: 'var(--app-body-margin)', sm: '1rem' }}
          >
            <Box mih="calc(100vh - 200px)">
              <Outlet />
            </Box>
            <Group justify="flex-end" pt="100px" pb="1rem">
              <Text size="sm">
                Copyright ©{dayjs().format('YYYY')} Fission™
              </Text>
            </Group>
          </Box>
        </MantineShell.Main>
      </MantineShell>
    </AppBackground>
  )
})
