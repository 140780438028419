import { memo } from 'react'

import { Button, Group, Stack } from '@mantine/core'

import { useLogout } from '@repo/common/queries/auth'

import { Logo } from '../../../../apps/labs/src/components/Logo'

export const LoginLogout = memo(function LoginLogout() {
  const reset = useLogout()

  return (
    <Stack miw="300">
      <Group justify="center">
        <Logo size={120} />
      </Group>

      <Button
        loading={reset.isPending}
        size="lg"
        mt="lg"
        onClick={() => reset.mutate()}
      >
        Logout?
      </Button>
    </Stack>
  )
})
