import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { skipToken, useQuery, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import type { ResultOf, VariablesOf } from 'gql.tada'
import JSBI from 'jsbi'
import * as _ from 'lodash-es'

import {
  always_default_data,
  merge_queries,
} from '@repo/common/helpers/query_helpers'
import { graphql_call } from '@repo/common/queries/helpers/uniswap_query'

import { useFundInfo } from '../funds'
import type { TokenSymbol } from '../products'

import {
  useGetPoolAddress,
  useGetPoolConfig,
} from './fission_dex_block_queries'
import {
  type BarChartTick,
  createBarChartTicks,
} from './fission_dex_graph_helpers'
import {
  query_get_daily_price,
  query_get_hourly_price,
  query_get_liquidity,
  query_get_pool_stats,
} from './fission_dex_graphql'

export type HistoryPeriod = 'hourly' | 'daily'

export type InSideType = 'USDC' | 'TOKEN'
export type ExchangeSideType = 'in' | 'out'

export const keys = createQueryKeyStore({
  dex: {
    swap_prices: (args: {
      symbol: string
      amount: number
      in_side: InSideType
      exchange_side: ExchangeSideType
    }) => [args],
    pool_config: (token_symbol: string) => [{ token_symbol }],
    pool_address: (token_symbol: string) => [{ token_symbol }],
    lp_positions: (wallet_address: CryptoAddress) => [wallet_address],
  },
  dex_graph: {
    pool: (token_symbol: string) => ({
      queryKey: [token_symbol],
      contextQueries: {
        liquidity: null,
        stats: null,
        price_history: (period_type: HistoryPeriod) => [period_type],
      },
    }),
  },
})

// eslint-disable-next-line complexity
export function useGetTokenPrice({
  symbol,
}: {
  symbol: TokenSymbol | undefined
}) {
  let is_fisn = false
  if (symbol == 'FISN') {
    is_fisn = true
    symbol = undefined
  }
  const fund_query = useFundInfo({ symbol })
  const pool_query = useGetPoolConfig(symbol)

  if (is_fisn) {
    return {
      data: 0.05,
      ...merge_queries([]),
    }
  }
  if (symbol == null || pool_query.data == null) {
    return { ...pool_query, data: null }
  }
  if (pool_query.data.liquidity == JSBI.BigInt('0')) {
    return { ...fund_query, data: fund_query.data?.price_per_share }
  }
  const price =
    pool_query.data.token0Price.quoteCurrency.symbol === 'USDC'
      ? Number(pool_query.data.token0Price.toFixed(2))
      : Number(pool_query.data.token1Price.toFixed(2))

  return {
    ...pool_query,
    data: price,
  }
}

export function useGetPoolStats({
  symbol,
}: {
  symbol: TokenSymbol | undefined
}) {
  const { data: pool_address } = useGetPoolAddress(symbol)

  const enabled = pool_address != null
  return useQuery<{
    usdc_volume: number
    token_volume: number
    usdc_tvl: number
    token_tvl: number
    last_day_usdc_volume: number
    last_day_token_volume: number
  }>({
    staleTime: 5 * 60 * 1000, // 15 minutes
    queryKey: keys.dex_graph.pool(symbol!)._ctx.stats.queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const results = await graphql_call({
            query: query_get_pool_stats,
            variables: { id: pool_address.toLowerCase() },
          })
          if (results == null) {
            return {
              usdc_volume: 0,
              token_volume: 0,
              usdc_tvl: 0,
              token_tvl: 0,
              last_day_usdc_volume: 0,
              last_day_token_volume: 0,
            }
          }

          const token0_is_usdc = results.token0.symbol == 'USDC'
          if (token0_is_usdc) {
            return {
              usdc_volume: Number(results.volumeToken0),
              token_volume: Number(results.volumeToken1),
              usdc_tvl: Number(results.totalValueLockedToken0),
              token_tvl: Number(results.totalValueLockedToken1),
              last_day_usdc_volume: Number(results.history[0].volumeToken0),
              last_day_token_volume: Number(results.history[0].volumeToken1),
            }
          }

          return {
            usdc_volume: Number(results.volumeToken1),
            token_volume: Number(results.volumeToken0),
            usdc_tvl: Number(results.totalValueLockedToken1),
            token_tvl: Number(results.totalValueLockedToken0),
            last_day_usdc_volume: Number(results.history[0].volumeToken1),
            last_day_token_volume: Number(results.history[0].volumeToken0),
          }
        },
  })
}

export function useGetLiquidity({
  symbol,
}: {
  symbol: TokenSymbol | undefined
}) {
  const { data: pool_address } = useGetPoolAddress(symbol)

  const enabled = pool_address != null
  return always_default_data(
    [],
    useQuery<BarChartTick[]>({
      staleTime: 60 * 60 * 1000, // 1 hour
      queryKey: keys.dex_graph.pool(symbol!)._ctx.liquidity.queryKey,
      queryFn: !enabled
        ? skipToken
        : async () => {
            let skip = 0
            let loadingTicks = true
            let results:
              | NonNullable<ResultOf<typeof query_get_liquidity>>['pool']
              | undefined

            let graphTicks: NonNullable<
              ResultOf<typeof query_get_liquidity>['pool']
            >['ticks'] = []

            while (loadingTicks) {
              results = await graphql_call({
                query: query_get_liquidity,
                variables: { id: pool_address.toLowerCase(), skip },
              })
              if (results == null) {
                throw new Error('no results')
              }
              graphTicks = graphTicks.concat(results.ticks)
              if (results.ticks.length < 1000) {
                loadingTicks = false
              } else {
                skip += 1000
              }
            }
            if (results == null || results.tick == null) {
              throw new Error('no results')
            }

            return createBarChartTicks({
              feeTier: results.feeTier,
              poolLiquidity: results.liquidity,
              poolSqrtPrice: results.sqrtPrice,
              tickCurrent: results.tick,
              token0: results.token0,
              token1: results.token1,
              numSurroundingTicks: 20,
              graphTicks,
            })
          },
    }),
  )
}

type HistoryReturnType = {
  date: number
  token_usdc_price: number
  usdc_token_price: number
}[]
// eslint-disable-next-line complexity, max-lines-per-function
async function price_history({
  pool_id,
  period_type,
}: {
  pool_id: VariablesOf<typeof query_get_liquidity>['id']
  period_type: HistoryPeriod
}): Promise<HistoryReturnType> {
  pool_id = pool_id.toLowerCase()

  if (period_type === 'hourly') {
    const results = await graphql_call({
      query: query_get_hourly_price,
      variables: { id: pool_id },
    })
    if (results == null || results.history.length == 0) {
      return []
    }
    const is_token0_usdc = results.token0.name == 'USDC'
    const firstTime = dayjs().subtract(1, 'day')
    const filled_in_history: HistoryReturnType = []
    // eslint-disable-next-line complexity
    _.times(24, (n) => {
      const time = firstTime.add(n, 'hour')
      if (time.isAfter(dayjs())) return

      const entry = results.history.find((e) =>
        dayjs.unix(e.date).isSame(time, 'hour'),
      )

      if (entry) {
        filled_in_history[n] = {
          usdc_token_price: Number(
            is_token0_usdc ? entry.token1Price : entry.token0Price,
          ),
          token_usdc_price: Number(
            is_token0_usdc ? entry.token0Price : entry.token1Price,
          ),
          date: dayjs.unix(entry.date).valueOf(),
        }
      } else if (n === 0) {
        filled_in_history[n] = {
          // take the newest entry
          usdc_token_price: Number(
            is_token0_usdc
              ? results.history[0].token1Price
              : results.history[0].token0Price,
          ),
          token_usdc_price: Number(
            is_token0_usdc
              ? results.history[0].token0Price
              : results.history[0].token1Price,
          ),
          date: time.valueOf(),
        }
      } else {
        filled_in_history[n] = {
          ...filled_in_history[n - 1],
          date: time.valueOf(),
        }
      }
    })
    return filled_in_history
  }
  // daily
  const results = await graphql_call({
    query: query_get_daily_price,
    variables: {
      id: pool_id,
      firstTime: dayjs().subtract(1, 'year').unix(),
    },
  })
  if (results == null || results.history.length == 0) {
    return []
  }
  const is_token0_usdc = results.token0.name == 'USDC'
  if (dayjs.unix(results.history[0].date).isBefore(dayjs(), 'day')) {
    results.history = [
      { ...results.history[0], date: dayjs().unix() },
      ...results.history,
    ]
  }
  return results.history.map((entry) => ({
    usdc_token_price: Number(
      is_token0_usdc ? entry.token1Price : entry.token0Price,
    ),
    token_usdc_price: Number(
      is_token0_usdc ? entry.token0Price : entry.token1Price,
    ),
    date: dayjs.unix(entry.date).valueOf(),
  }))
}

export function useGetPriceHistory({
  symbol,
  period_type,
}: {
  symbol: TokenSymbol | undefined
  period_type: HistoryPeriod
}) {
  const { data: pool_address } = useGetPoolAddress(symbol)
  const queryClient = useQueryClient()

  const enabled = pool_address != null && symbol != null

  const opposite_period_type = period_type === 'hourly' ? 'daily' : 'hourly'

  if (enabled) {
    void queryClient.prefetchQuery({
      queryKey: keys.dex_graph
        .pool(symbol)
        ._ctx.price_history(opposite_period_type).queryKey,
      queryFn: () =>
        price_history({
          pool_id: pool_address.toLowerCase(),
          period_type: opposite_period_type,
        }),
      staleTime: 60 * 60 * 1000, // 1 hour
    })
  }

  return always_default_data(
    [],
    useQuery({
      queryKey: keys.dex_graph.pool(symbol!)._ctx.price_history(period_type)
        .queryKey,
      queryFn: !enabled
        ? skipToken
        : () =>
            price_history({ pool_id: pool_address.toLowerCase(), period_type }),
      staleTime: 60 * 60 * 1000, // 1 hour
    }),
  )
}
