import { memo } from 'react'

import { usdLargeNumberFormatter } from '@repo/common/helpers/formatters'

import { useFundInfo, useFundLatestPriceData } from '../../queries/funds'

export const FundTVL = memo<{ id: RubyID }>(function FundTVL({ id }) {
  const fund_query = useFundInfo({ fund_id: id })
  const price_query = useFundLatestPriceData({ id })
  if (fund_query.isLoading || price_query.isLoading) return null
  if (fund_query.data?.symbol != 'TECH') {
    return 'n/a'
  }
  return (
    <>
      {usdLargeNumberFormatter(price_query.data?.tvl_usdc, {
        maximumFractionDigits: 2,
      })}
    </>
  )
})
