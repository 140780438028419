import { Group, Stack, Title } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'
import dayjs from 'dayjs'

import { PortfolioChart } from '@repo/common/components/PortfolioChart/PortfolioChart'
import { PortfolioTable } from '@repo/common/components/PortfolioTable'
import { WalletBalance } from '@repo/common/components/WalletBalance'
import { useAuth } from '@repo/common/queries/auth'

import { ContentBox } from '@/components/ContentBox'
import { HeadlineAndDetails } from '@/components/HeadlineAndDetails'
import { ProfileActions } from '@/components/ProfileActions'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/_app/portfolio')({
  component: IndexPage,
})

function IndexPage() {
  const auth = useAuth()

  let name = auth.data?.name
  if (name) name += name.endsWith('s') ? "'" : "'s"
  if (!name) name = 'My'

  return (
    <Stack gap="xl">
      <HeadlineAndDetails
        title={`${name} Portfolio`}
        description={auth.switch(
          `As of ${dayjs().format('MMMM DD')}`,
          'Please connect your wallet to view your portfolio.',
        )}
      />
      <ContentBox w="100%" maw="1150px" variant="fading" p="xl" h="400px">
        <Title order={2} mb="lg">
          Balance over time
        </Title>
        <PortfolioChart />
      </ContentBox>
      <ContentBox w="100%" variant="fading" p="xl">
        <Stack align="flex-start">
          <Stack align="flex-start" maw="100%">
            <PortfolioTable />
          </Stack>

          <Title mt="60px" order={2}>
            Wallet Balance
          </Title>
          <Group>
            <WalletBalance
              unconnected="show"
              size="sm"
              message="Connect to see Wallet balances"
            />
          </Group>
        </Stack>
      </ContentBox>
      <ProfileActions />
    </Stack>
  )
}
