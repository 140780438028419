import type { ReactNode } from 'react'

import {
  Button,
  Group,
  type MantineStyleProps,
  Stack,
  Text,
} from '@mantine/core'
import { type UseFormReturnType } from '@mantine/form'

import { Logo } from '../../../../apps/labs/src/components/Logo'

import classes from './LoginSignupForm.module.css'

export { classes }

export const form_input_props = {
  size: 'lg',
  classNames: { input: classes.input, label: classes.label },
}

export type LogoType = (props: { size: MantineStyleProps['h'] }) => ReactNode

export function LoginSignupForm<V>({
  children,
  action,
  button_text,
  after_button,
  form,
  onSuccess,
}: {
  children: ReactNode
  after_button?: ReactNode
  button_text: string
  action: {
    mutate(values: V, callbacks?: { onSuccess?: () => void }): void
    isPending: boolean
    isError: boolean
    error: null | { message: string }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturnType<V>
  onSuccess?: () => void
}) {
  const onSubmit = (
    values: Parameters<Parameters<typeof form.onSubmit>[0]>[0],
  ) => {
    void action.mutate(values, { onSuccess })
  }

  return (
    <form onSubmit={form.onSubmit(onSubmit)} className={classes.form}>
      <Stack maw="100%">
        <Group justify="center">
          <Logo size={120} />
        </Group>

        {action.isError && (
          <Text c="red">{action.error?.message ?? 'unknown error'}</Text>
        )}
        {children}
        <Button type="submit" loading={action.isPending} size="lg" mt="lg">
          {button_text}
        </Button>
        {after_button}
      </Stack>
    </form>
  )
}
