import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Userfront from '@userfront/core'
import Cookies from 'js-cookie'
import { usePostHog } from 'posthog-js/react'

// eslint-disable-next-line @cspell/spellchecker
void Userfront.init('vbq7rrzb')

export type AuthDataType = {
  name?: string
  email?: string
  uuid?: string
  is_member: boolean
  is_authenticated: boolean
}

export const keys = createQueryKeyStore({
  user: {
    data: null,
  },
})

export function useRefresh() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      Userfront.tokens.refresh()

      await new Promise((resolve) => setTimeout(resolve, 5000))
      await queryClient.invalidateQueries({ queryKey: keys.user.data.queryKey })
      console.log('Token Refreshed')
    },
  })
}

function useIdentify() {
  const posthog = usePostHog()

  return function identify_check(data: AuthDataType) {
    if (user_has_been_identified == false && data.is_authenticated) {
      user_has_been_identified = true
      posthog.identify(data.uuid, { email: data.email, name: data.name })
      Sentry.setUser({
        id: data.uuid,
        email: data.email,
        name: data.name,
      })
    }
  }
}

let made_auth_token = false
function useMakeAdminToken() {
  return function make_admin_token_check() {
    if (made_auth_token == false && Userfront.user.hasRole?.('admin')) {
      made_auth_token = true
      console.log('Making admin token')
      Cookies.set(
        Userfront.tokens.accessTokenName,
        Userfront.tokens.accessToken,
        {
          expires: 7,
          secure: true,
          sameSite: 'Lax',
          domain: '.fission.xyz',
        },
      )
    }
  }
}

let user_has_been_identified = false
export function testingRest() {
  user_has_been_identified = false
  made_auth_token = false
}

export function useAuth() {
  const identify_check = useIdentify()
  const make_admin_token_check = useMakeAdminToken()

  const results = useQuery<AuthDataType>({
    queryKey: keys.user.data.queryKey,
    // eslint-disable-next-line complexity
    queryFn: () => {
      const data: AuthDataType = {
        email: Userfront.user.email ?? '',
        name: Userfront.user.name ?? '',
        uuid: Userfront.user.userUuid ?? '',
        is_authenticated: Boolean(Userfront.user.userUuid),
        is_member:
          ((Userfront.user.hasRole?.('member') as boolean) ?? false) ||
          ((Userfront.user.hasRole?.('admin') as boolean) ?? false),
      }
      identify_check(data)
      make_admin_token_check()
      return data
    },
  })
  return {
    ...results,
    switch<T>(is_auth: T, not_auth: T) {
      return results.data?.is_authenticated ? is_auth : not_auth
    },
  }
}

export function useIsAuthenticated() {
  const { data } = useAuth()
  return data?.is_authenticated ?? false
}

export function useSignup() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      name,
      email,
      password,
      company_name,
    }: {
      name: string
      email: string
      password: string
      company_name: string
    }) => {
      await Userfront.signup({
        method: 'password',
        email,
        password,
        name,
        redirect: false,
        data: { company_name },
      })
      await queryClient.invalidateQueries({ queryKey: keys.user.data.queryKey })
    },
  })
}
export function useLogin() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      email,
      password,
    }: {
      email: string
      password: string
    }) => {
      await Userfront.login({
        method: 'password',
        emailOrUsername: email,
        password,
        redirect: false,
      })
      await queryClient.invalidateQueries({ queryKey: keys.user.data.queryKey })
    },
  })
}

export function useResetPassword() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (args: {
      method: 'jwt' | 'link'
      existingPassword?: string
      password: string
      token?: string
      uuid?: string
    }) => {
      await Userfront.updatePassword({
        ...args,
        redirect: false,
      })
      if (args.method === 'link') {
        await queryClient.invalidateQueries({
          queryKey: keys.user.data.queryKey,
        })
      }
    },
  })
}

export function useLogout() {
  const queryClient = useQueryClient()
  const posthog = usePostHog()

  return useMutation({
    mutationFn: async () => {
      posthog.reset()
      await Userfront.logout({ redirect: '/' })
      await queryClient.invalidateQueries({ queryKey: keys.user.data.queryKey })
    },
  })
}
