/* eslint-disable @cspell/spellchecker */
import * as _ from 'lodash-es'

export const images = import.meta.glob<{ default: string }>('./images/*.png', {
  eager: true,
})

// eslint-disable-next-line @cspell/spellchecker
export const vc_image_names = ['soft-bank', 'sequoia', '9', 'creandum']

const dark_background_logos: string[] = [
  'apollo-global-management',
  'blackstone',
  'dragoneer-investment-group',
  'draper-fisher-jurvetson',
  'dst-global',
  'durable-capital-partners',
  'fidelity-management-research',
  'sequoia-capital',
  'silver-lake',
  'union-square-ventures',
  'valor-equity-partners',
  'y-combinator',
]

const no_white_mode_logos: string[] = ['blackstone', 'union-square-ventures']

export function get_vc_logo({ name }: { name: string }) {
  const filename = _.kebabCase(name)
  if (images[`./images/${filename}.png`] == null) {
    return {
      image_url: '',
      dark_background_logo: true,
      no_white_mode_logos: false,
    }
  }

  return {
    image_url: images[`./images/${filename}.png`].default,
    dark_background_logo: dark_background_logos.includes(filename),
    no_white_mode_logo: no_white_mode_logos.includes(filename),
  }
}
