import { Box, Stack, Text } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'

import { useListFunds } from '@repo/common/queries/funds'

import { ContentBox } from '@/components/ContentBox'
import { HeadlineAndDetails } from '@/components/HeadlineAndDetails'
import { InvestmentRow } from '@/components/InvestmentRow'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/_app/investments/')({
  component: InvestmentsPage,
})

function InvestmentsPage() {
  const { data: funds } = useListFunds()

  const soon = funds.filter((f) => f.symbol !== 'TECH')

  return (
    <Stack gap="sm" maw="80rem">
      <HeadlineAndDetails
        title="Investments"
        description="Choose a token backed by thematic bundles of leading private equities."
        mb="lg"
      />
      <ContentBox>
        <InvestmentRow symbol="TECH" />
      </ContentBox>
      <Box px="lg" mt="lg">
        <Text opacity={0.7}>Coming Soon</Text>
      </Box>
      {soon.map(({ id }) => (
        <ContentBox key={id} opacity={0.7}>
          <InvestmentRow fund_id={id} key={id} />
        </ContentBox>
      ))}
    </Stack>
  )
}
