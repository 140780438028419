import { memo } from 'react'

import { TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import isUUID from 'validator/es/lib/isUUID'
import { z } from 'zod'

import {
  form_input_props,
  LoginSignupForm,
} from '@repo/common/components/LoginSignupForm'

import { useResetPassword } from '../../queries/auth'
import { password_zod } from '../LoginSignup'

const schema = z.union([
  z.object({
    method: z.literal('link'),
    password: password_zod,
  }),
  z.object({
    method: z.literal('jwt'),
    existingPassword: password_zod,
    password: password_zod,
  }),
])

export const reset_search_schema = z.object({
  uuid: z.string().refine(isUUID).or(z.undefined()).catch(undefined),
  token: z.string().min(8).or(z.undefined()).catch(undefined),
  type: z.enum(['reset']).catch('reset'),
})

export const LoginPasswordReset = memo<{
  uuid?: string
  token?: string
  onSuccess?: () => void
}>(function LoginPasswordReset({ uuid, token, onSuccess }) {
  const is_link_mode = uuid ? true : false
  const form = useForm({
    initialValues: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      method: (is_link_mode ? 'link' : 'jwt') as 'link' | 'jwt',
      uuid,
      token,
      password: '',
      existingPassword: undefined,
    },
    validate: zodResolver(schema),
  })
  const reset = useResetPassword()

  return (
    <LoginSignupForm
      form={form}
      action={reset}
      onSuccess={onSuccess}
      button_text="Reset Password"
    >
      <>
        {is_link_mode || (
          <TextInput
            label="existing password"
            type="password"
            {...form.getInputProps('existingPassword')}
            disabled={reset.isPending}
            {...form_input_props}
          />
        )}
        <TextInput
          label="new password"
          type="password"
          {...form.getInputProps('password')}
          disabled={reset.isPending}
          {...form_input_props}
        />
      </>
    </LoginSignupForm>
  )
})
