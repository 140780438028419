import { memo, useMemo } from 'react'

import { Group, Paper, Text } from '@mantine/core'
import isChromatic from 'chromatic'
import dayjs from 'dayjs'
import {
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  date_formatter,
  full_date_formatter,
  tick_wrapper,
  usdFormatter,
  usdLargeNumberFormatter,
} from '@repo/common/helpers/formatters'

import {
  type BalanceRecord,
  useGetPortfolioHistory,
} from '../../queries/portfolio'
import { useListPortfolio } from '../../queries/products'
import { ConnectWalletButton } from '../ConnectWalletButton'

export const y_tick_formatting = {
  fontSize: 'var(--mantine-font-size-sm)',
  fill: 'var(--mantine-color-dark-0)',
  opacity: 0.8,
}

export const x_tick_formatting = {
  fontSize: 'var(--mantine-font-size-sm)',
  fill: 'var(--mantine-color-dark-0)',
  opacity: 0.6,
}

export const Balance_Colors = {
  stroke: 'var(--mantine-color-green-2)',
  fill: 'var(--mantine-color-green-2)',
}

export const PortfolioChart = memo(function PortfolioChart() {
  const { data: history } = useGetPortfolioHistory()
  const { totals } = useListPortfolio()

  const data = useMemo(() => {
    if (history == null) return []
    const today = dayjs()
    const records = history.filter((r) => !dayjs(r.day).isSame(today, 'day'))
    const latest: BalanceRecord = {
      day: today.startOf('day').valueOf(),
      balance_usdc: totals.usdc_value,
      change_usdc: 0,
      components: {} as BalanceRecord['components'],
    }
    records.push(latest)
    return records
  }, [history, totals.usdc_value])

  if (history == null) {
    return (
      <Group>
        <ConnectWalletButton message="Connect Wallet to see history" />
      </Group>
    )
  }
  return (
    <ResponsiveContainer>
      <ComposedChart data={data}>
        <XAxis
          dataKey="day"
          scale="time"
          type="number"
          domain={['dataMin', 'dataMax']}
          interval="preserveStartEnd"
          tick={x_tick_formatting}
          tickFormatter={tick_wrapper(date_formatter)}
        />
        <YAxis
          yAxisId="left"
          type="number"
          domain={['auto', 'auto']}
          tick={y_tick_formatting}
          tickFormatter={tick_wrapper(usdLargeNumberFormatter)}
        />
        <Tooltip
          content={({ label, payload }) => (
            <ChartTooltip
              label={label as ToolTipType['label']}
              payload={payload as ToolTipType['payload']}
            />
          )}
        />

        <Line
          yAxisId="left"
          isAnimationActive={!isChromatic()}
          type="stepAfter"
          dataKey="balance_usdc"
          {...Balance_Colors}
          strokeWidth={2}
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
})

export type ToolTipType = {
  label: number
  payload?: {
    name: string
    color: string
    value: number
    payload: BalanceRecord
  }[]
}

export function ChartTooltip({ label, payload }: ToolTipType) {
  if (payload == null || payload?.length < 1) return null

  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
      <Text size="sm" mb={5}>
        {full_date_formatter(label)}
      </Text>
      <Text size="md">Total: {usdFormatter(payload[0].value)}</Text>
    </Paper>
  )
}
