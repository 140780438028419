import { memo } from 'react'

import { countFormatter } from '@repo/common/helpers/formatters'
import { useGetVaultShares } from '@repo/common/queries/vaults'

export const VaultShares = memo<{ id: RubyID }>(function VaultShares({ id }) {
  const { data: shares } = useGetVaultShares({ id })
  if (shares == null) return null
  return (
    <>{countFormatter(shares, { compact: true, maximumFractionDigits: 2 })}</>
  )
})
