import { memo } from 'react'

import { type ModalProps, Progress } from '@mantine/core'

import { FissionModal } from '@repo/common/components/FissionModal'

import classes from './WorkflowModal.module.css'

export const WorkflowModal = memo<
  {
    progress?: number
  } & ModalProps
>(function WorkflowModal({ progress, ...props }) {
  return (
    <FissionModal
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      {...props}
      belowTitle={
        <>
          {progress != null && progress > 0 && (
            <Progress
              size="lg"
              value={progress}
              w="100%"
              className={classes.root}
              color={progress == 100 ? 'green' : 'primary'}
            />
          )}
        </>
      }
    />
  )
})
