import { memo } from 'react'

import { Group, Stack, Title } from '@mantine/core'
import { Link as RouteLink } from '@tanstack/react-router'
import * as _ from 'lodash-es'

import { ContentBox } from '@/components/ContentBox'
import type { SectionsKeys } from '@/data/companies/text_sections'

import classes from './StatBox.module.css'

export const StatBox = memo<{
  name: string
  value: string
  params: { symbol: string; cms_name: string }
  section?: SectionsKeys
}>(function StatBox({ name, value, params, section }) {
  const body = (
    <ContentBox px="lg" py="xl" miw="120">
      <Stack gap="xs">
        <Title order={2} c="gray" lh="1">
          {name}
        </Title>
        <Group fz="lg" lh="1">
          {value}
        </Group>
      </Stack>
    </ContentBox>
  )
  if (section) {
    const section_link = _.kebabCase(section)
    return (
      <RouteLink
        key={name}
        to="/research/$symbol/company/$cms_name/$section"
        params={{
          ...params,
          section: section_link,
        }}
        className={classes.link}
      >
        {body}
      </RouteLink>
    )
  }
  return body
})
