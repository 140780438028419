import { graphql } from '@repo/common/queries/helpers/server_query'

export const query_list_funds = graphql(`
  query query_list_funds {
    funds {
      id
      long_name
      name
      symbol
      image_slug
      type

      price_per_share

      companies {
        name
        cms_name
      }
    }
  }
`)

export const query_fund_daily_nav = graphql(`
  query query_fund_daily_nav($id: ID!) {
    fund_details(id: $id) {
      daily_nav {
        date
        nav_price_per_share: price_per_share
        round_price_per_share
      }
    }
  }
`)

export const query_fund_company_value = graphql(`
  query query_fund_company_value($id: ID!) {
    fund_details(id: $id) {
      cash_balance
      companies {
        name
        cms_name
        value_held
      }
    }
  }
`)
