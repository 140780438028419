import { memo, useMemo } from 'react'

import { Box, Group, Stack, Text } from '@mantine/core'
import dayjs from 'dayjs'

import { LabsDocsLink } from '@repo/common/helpers/cross_site_links'

import { Link, type LinkProps } from '../Link'
import { Logo } from '../Logo'

import classes from './Footer.module.css'

type FooterLinkGroupType = {
  title: string
  links: LinkProps[]
}

const BOTTOM_LINKS: LinkProps[] = [
  // {
  //   label: 'Privacy Policy',
  //   to: '/privacy-policy',
  // },
  // {
  //   label: 'Responsible Disclosure',
  //   to: '/disclosure',
  // },
  // {
  //   label: 'Terms of Use',
  //   to: '/terms-of-use',
  // },
]

export const Footer = memo(function Footer() {
  const FOOTER_LINKS: FooterLinkGroupType[] = useMemo(
    () => [
      {
        title: 'Fission',
        links: [LabsDocsLink],
      },
    ],
    [],
  )

  return (
    <Box component="footer">
      <Group
        align="stretch"
        justify="space-between"
        maw="var(--website-content-width)"
        pr="var(--app-body-margin)"
        pl={{ base: 'var(--app-body-margin)', sm: '1rem' }}
        m="200 auto 50 auto"
      >
        <Stack justify="space-between">
          <Logo size={32} as_link with_name />
        </Stack>
        <Group align="flex-start" gap="20px 80px">
          {FOOTER_LINKS.map(({ title, links }) => (
            <Stack key={title} gap="6px">
              <Text fw="bold">{title}</Text>
              {links.map(({ label, to, href }) => (
                <Link key={label} to={to} href={href} className={classes.link}>
                  {label}
                </Link>
              ))}
            </Stack>
          ))}
        </Group>
      </Group>
      <Box className={classes.bottom}>
        <Group
          justify="space-between"
          maw="var(--website-content-width)"
          pr="var(--app-body-margin)"
          pl={{ base: 'var(--app-body-margin)', sm: '1rem' }}
          m="0 auto"
          h="100%"
        >
          <Group>
            {BOTTOM_LINKS.map(({ label, to }) => (
              <Link
                key={label}
                label={label}
                to={to}
                className={classes.bottom_link}
              />
            ))}
          </Group>

          <Text size="sm" opacity={0.5} mb="xs">
            Copyright ©{dayjs().format('YYYY')} Fission™
          </Text>
        </Group>
      </Box>
    </Box>
  )
})
