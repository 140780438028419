import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'

import { useAuth } from '@repo/common/queries/auth'

import { router } from './routes'

// no localhost error tracking
if (import.meta.env.MODE != 'development') {
  Sentry.init({
    dsn: 'https://34011000490119eb38d649d6d65fddcb@o4507572175241216.ingest.us.sentry.io/4507589706055680',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
    ],
    release: __VERSION__,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/.*.fission.xyz/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

export function App() {
  const queryClient = useQueryClient()
  const { data: auth } = useAuth()

  return (
    <RouterProvider
      router={router}
      context={{
        queryClient,
        auth,
      }}
    />
  )
}
