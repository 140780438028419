import { graphql } from '@repo/common/queries/helpers/server_query'

export const query_list_crypto_contracts = graphql(`
  query query_list_crypto_contracts {
    crypto {
      contract_base_url
      contracts {
        address
        category
        name
        symbol
      }
    }
  }
`)
