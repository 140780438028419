import { memo, type ReactNode } from 'react'

import { Group, Stack, Text } from '@mantine/core'

import { useAuth } from '../../queries/auth'
import { LoginSignup } from '../LoginSignup'

export const RequireLogin = memo<{
  children: ReactNode
}>(function RequireLogin({ children }) {
  const { data } = useAuth()

  if (data?.is_authenticated) {
    return children
  }

  return (
    <Group
      gap="xl"
      wrap="nowrap"
      pl={{ base: 'sm', sm: '0' }}
      pr={{ base: 'sm', sm: 'xl' }}
      align="top"
      maw="600px"
      mx="auto"
    >
      <LoginSignup starting_mode="signup">
        <Stack gap="md">
          <Text size="xxl" lh="1.4" ta="center">
            The future of VC is built on DeFi. Join the revolution.
          </Text>
          <Text mt="lg" size="lg" ta="center" opacity={0.8}>
            Testnet is available now for a select group of users.{' '}
          </Text>
          <Text ta="center" size="lg" opacity={0.8}>
            Sign up to request early access and get notified first for upcoming
            rewards.
          </Text>
        </Stack>
      </LoginSignup>
    </Group>
  )
})
