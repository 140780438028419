import { memo } from 'react'

import { Box, Image, type ImageProps, Stack, Text } from '@mantine/core'

import { getCompanyLogo } from '@/data/companies'
import company_white_url from '@/data/companies/images/white.png'

export type LogoProps = {
  variant?: 'colors' | 'white'
  title: string
  h?: ImageProps['h']
  w?: ImageProps['w']
  miw?: ImageProps['miw']
}

export const BaseLogo = memo<
  LogoProps & {
    image_url: string
    white_url: string
    dark_background_logo?: boolean
    no_white_mode_logo?: boolean
  }
>(function BaseLogo({
  image_url,
  dark_background_logo,
  no_white_mode_logo,
  variant = 'white',
  white_url,
  ...props
}) {
  if (image_url == '') {
    return (
      <Stack {...props} align="center" justify="center">
        <Text size="sm">{props.title}</Text>
      </Stack>
    )
  }
  if (variant === 'colors' || no_white_mode_logo) {
    return (
      <Box
        bg={dark_background_logo ? undefined : 'dark.0'}
        style={{ width: 'fit-content', height: 'fit-content' }}
      >
        <Image w="unset" {...props} src={image_url} />
      </Box>
    )
  }

  return (
    <Image
      w="unset"
      {...props}
      src={white_url}
      style={{ maskImage: `url(${image_url})`, maskSize: '100% 100%' }}
    />
  )
})

export const CompanyLogo = memo<
  LogoProps & {
    cms_name: string
  }
>(function CompanyLogo({ cms_name, ...props }) {
  const { image_url, dark_background_logo, no_white_mode_logo } =
    getCompanyLogo({ cms_name })

  return (
    <BaseLogo
      image_url={image_url}
      dark_background_logo={dark_background_logo}
      no_white_mode_logo={no_white_mode_logo}
      white_url={company_white_url}
      {...props}
    />
  )
})
