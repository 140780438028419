import { memo } from 'react'

import { Box, Paper, Text, Title } from '@mantine/core'
import isChromatic from 'chromatic'
import * as _ from 'lodash-es'
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  percentage_formatter,
  usdLargeNumberFormatter,
} from '@repo/common/helpers/formatters'

import type { CompanyMetricsData } from '@/data/companies/research_types'

import { type ToolTipType } from '../PerShareChart'

const tick_formatting = {
  fontSize: 'var(--mantine-font-size-sm)',
  fill: 'var(--mantine-color-primary-0)',
}

export const Amount_Colors = {
  fill: 'var(--mantine-color-primary-5)',
}
export const Growth_Colors = {
  stroke: 'var(--mantine-color-green-5)',
  fill: 'var(--mantine-color-green-5)',
}

export const RevenueChart = memo<{
  metrics: CompanyMetricsData[string]
  // eslint-disable-next-line max-lines-per-function
}>(function RevenueChart({ metrics }) {
  const data = metrics.revenue.map(({ year, amount }, index) => {
    if (index === 0) {
      return {
        year,
        amount,
        growth: 0,
      }
    }
    return {
      year,
      amount,
      growth:
        ((amount - metrics.revenue[index - 1].amount) /
          metrics.revenue[index - 1].amount) *
        100,
    }
  })
  const negative_growth = _.minBy(data, 'growth')?.growth ?? 0
  return (
    <>
      <Title order={2} mb="sm">
        Revenue Amount and Growth
      </Title>
      <Box w="100%" h="300">
        <ResponsiveContainer>
          <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <XAxis dataKey="year" axisLine={false} tick={tick_formatting} />
            <ReferenceLine
              yAxisId="right"
              y={0}
              stroke="gray"
              strokeWidth={1.5}
              strokeOpacity={0.65}
            />

            <YAxis
              yAxisId="left"
              padding={{ bottom: negative_growth < 0 ? 50 : 0 }}
              tick={tick_formatting}
              tickFormatter={(value) =>
                usdLargeNumberFormatter(value as number)
              }
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              type="number"
              tick={tick_formatting}
              tickFormatter={(value) => percentage_formatter(value as number)}
            />
            <Tooltip
              content={({ label, payload }) => (
                <ChartTooltip
                  label={label as string}
                  payload={payload as ToolTipType['payload']}
                />
              )}
            />
            <Legend />
            <Bar
              isAnimationActive={!isChromatic()}
              yAxisId="left"
              dataKey="amount"
              barSize={20}
              {...Amount_Colors}
            />
            <Line
              isAnimationActive={!isChromatic()}
              yAxisId="right"
              type="monotone"
              dataKey="growth"
              {...Growth_Colors}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </>
  )
})

export function ChartTooltip({ label, payload }: ToolTipType) {
  if (!payload) return null

  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
      <Text size="sm" mb={5}>
        Year {label}
      </Text>
      {payload.map((item) => (
        <Text key={item.name} c={item.color} size="md" fw="bold">
          {_.startCase(item.name)}:{' '}
          {item.name == 'amount'
            ? usdLargeNumberFormatter(item.value)
            : percentage_formatter(item.value)}
        </Text>
      ))}
    </Paper>
  )
}
