import { type LinkProps, useNavigate } from '@tanstack/react-router'

export function useOnClickNavigate() {
  const navigate = useNavigate()
  const onClickNavigate =
    ({
      to,
      href,
      params,
    }: {
      to?: LinkProps['to']
      href?: string
      params?: LinkProps['params']
    }) =>
    () => {
      if (href) {
        window.open(href)
      } else {
        navigate({ to, params }).catch(console.error)
      }
    }

  return {
    navigate,
    onClickNavigate,
  }
}
