import { memo, useCallback } from 'react'

import type { MessageHandler } from '@sumsub/websdk'
import SumsubWebSdk from '@sumsub/websdk-react'
import type { EventPayload } from '@sumsub/websdk/types/types'

import { useGetKycAccessToken } from '@repo/common/queries/kyc'

const config = {}
const options = {
  adaptIframeHeight: true,
}

export const SumsubComponent = memo<{ onCompleted?: () => void }>(
  function SumsubComponent({ onCompleted }) {
    const {
      data: access_token,
      isLoading,
      refreshToken,
    } = useGetKycAccessToken()

    const onMessage = useCallback(
      (
        type: Parameters<MessageHandler>[0],
        payload: Parameters<MessageHandler>[1],
      ) => {
        console.log('onMessage', type, payload)

        if (type === 'idCheck.onApplicantStatusChanged') {
          const status_payload =
            payload as EventPayload<'idCheck.onApplicantStatusChanged'> & {
              reviewResult: { reviewAnswer: string }
            }
          if (
            status_payload.reviewStatus === 'completed' &&
            status_payload.reviewResult.reviewAnswer === 'GREEN'
          ) {
            onCompleted?.()
          }
        }
      },
      [onCompleted],
    )

    if (isLoading) return null
    if (access_token == null) {
      console.error('No access token')
      return null
    }

    return (
      <SumsubWebSdk
        accessToken={access_token}
        expirationHandler={refreshToken}
        config={config}
        options={options}
        onMessage={onMessage}
        onError={console.error}
      />
    )
  },
)
