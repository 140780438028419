import { memo, useEffect } from 'react'

import { Box, Group, type MantineStyleProps } from '@mantine/core'
import { useHover, usePrevious } from '@mantine/hooks'

import type { CustomColorsShades } from '@repo/common/core/theme'

import classes from './WeightedSegmentChart.module.css'

export type WeightPoint = {
  id: string
  weight: number
  color: CustomColorsShades
  name: string
}

export const WeightedSegmentChart = memo<
  {
    weights: WeightPoint[]
    onHover: (id: WeightPoint['id'] | null) => void
    onClick?: (id: WeightPoint['id']) => void
    highlighted: WeightPoint['id'] | null
  } & MantineStyleProps
>(function WeightedSegmentChart({
  weights,
  onHover,
  onClick,
  highlighted,
  h = 20,
  ...props
}) {
  const { hovered, ref } = useHover()
  const wasHovered = usePrevious(hovered)

  useEffect(() => {
    if (!hovered && wasHovered) {
      onHover(null)
    }
  }, [hovered, wasHovered, onHover])

  return (
    <Group h={h} {...props} justify="space-between" gap="0" ref={ref}>
      {weights.map(({ id, weight, color, name }) => (
        <Box
          w={`calc(${weight}% - 4px)`}
          bg={color}
          key={id}
          title={`${name} - ${weight}%`}
          onMouseOver={() => onHover(id)}
          onClick={() => onClick?.(id)}
          className={classes.point}
          data-hover={id === highlighted}
        />
      ))}
    </Group>
  )
})
